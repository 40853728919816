import React, { useContext, useState, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    IconButton,
    MenuItem,
    TablePagination,
} from '@material-ui/core'
import { apiCall } from '../../common/api'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import BiddingCard from '../../components/molecules/Bidding/BiddingCard'
import './ProductView.css'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router-dom'
import {
    handleRedirectInternal,
    currencyFormat,
    dateFormatFront,
    titleUrlFormat,
} from '../../common/components'
import { bidSocketHandler, messageHandler } from '../Common/socketHandler'
import FavoriteCheckbox from '../../components/atoms/FavoriteCheckbox'
import CurrencyConverter from './CurrencyConverter'
import Popup from '../../components/organisms/Popup'
import { PhotoSwipeGallery } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'
import ReactHtmlParser from 'react-html-parser'
// context
import CommonContext from '../../context/common/commonContext'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import ReadMore from '../../components/atoms/ReadMore'
import BidHistory from '../../components/organisms/BidHistory'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'
import Timer from '../../common/timer'
import Login from '../Login'
import { Pagination } from '@material-ui/lab'

function TabPanel(props) {
    const { children, value, index, html, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {html ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: html,
                            }}
                        ></span>
                    ) : (
                        <span>{children}</span>
                    )}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}))

// includes both live / blind auction
const SingleAuction = ({
    product,
    images,
    downloadFile,
    setProduct,
    getProductView,
    hideBidding,
    slider,
}) => {
    const authContext = useContext(AuthContext)
    const classes = useStyles()
    const alertContext = useContext(AlertContext)
    const { phrase } = useContext(CommonContext)
    const { setAlert } = alertContext
    const [logPopup, setLogPopup] = useState(false)
    const [value, setValue] = React.useState(0)
    const { user, responseStatus, clearResponse, saveSearch, isAuthenticated } = authContext
    const [productDescPopup, setProductDescPopup] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [pageLimit, setPageLimit] = useState(10)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    let history = useHistory()
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const { setBidHistoryValue } = useContext(CommonContext)
    const userRef = useRef(user)
    const productRef = useRef(product)
    useEffect(() => {
        userRef.current = user
        productRef.current = product
    })
    console.log(product, 'product')
    const toggleLogOpen = () => {
        setLogPopup(!logPopup)
        if (window.location.hash.includes('#login')) {
            handleRedirectInternal(history, '')
        }
    }
    const socketHandler = (data, type) => {
        bidSocketHandler(
            data,
            type,
            userRef.current,
            productRef.current,
            phrase,
            setProduct,
            setAlert,
        )
    }
    const changePageLimit = (e) => {
        // setLineItemsReady(false)
        setPageLimit(e.target.value)
        // setPageNum(1)
    }
    const cancelBidHandler = (data) => {
        if (data.project_id == productRef.current.id) {
            getProductView(productRef.current.id)
        }
    }

    const productDescClose = () => {
        setProductDescPopup(false)
    }

    const onHandlePage = (e, value) => {
        setPage(value)
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    let options = {}

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        socket.on('realclosedupdates', (data) => {
            console.log('[PRODUCT CLOSED SOCKET] ', data)
            socketHandler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            socketHandler(data, 'bidAddtime')
        })
        socket.on('drop_price', (data) => {
            socketHandler(data, 'drop_price')
        })
        // Bid Cancellation Event => Reload the product info
        socket.on('cancelbidemitted', function (data) {
            cancelBidHandler(data)
        })
        socket.on('reservepriceupdate', function (data) {
            socketHandler(data, 'reservepriceupdate')
        })
        socket.on('bid_extended', function (data) {
            socketHandler(data, 'bid_extended')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                socketHandler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                socketHandler(data, 'bidAddtime')
            })
            socket.off('drop_price', (data) => {
                socketHandler(data, 'drop_price')
            })
            socket.off('cancelbidemitted', function (data) {
                cancelBidHandler(data)
            })
            socket.off('reservepriceupdate', function (data) {
                socketHandler(data, 'reservepriceupdate')
            })
            socket.off('bid_extended', function (data) {
                socketHandler(data, 'bid_extended')
            })
            socket.disconnect()
        }
    }, [])

    let categories = ''
    if (product) {
        for (let i in product.multiplecategoryshow) {
            // last index
            if (i == product.multiplecategoryshow.length - 1) {
                categories += phrase[product.multiplecategoryshow[i]]
            } else {
                categories += phrase[product.multiplecategoryshow[i]] + ', '
            }
        }
    }
    const redirectFile = (event) => {
        if (event.target.alt !== 'img') {
            window.location.href = `${global.site_url}/uploads/product/${event.target.title}`
        } else {
            event.preventDefault()
            setOpen(true)
        }
    }

    return (
        <>
            {slider ? (
                <div className="mt-3 sliderDetails">
                    <div className="timerMainCnt d-flex align-items-start justify-content-between">
                        <div>
                            <p className="lotID">Lot id : {product.id}</p>
                            <h2 className="pvTitle"> {product.title} </h2>
                        </div>
                        <div key="bidTimer" className="bidTimeCntRt text-center primeTimer">
                            {/* <label> {phrase.time_left} </label> */}
                            <div className="primeColor">
                                <Timer
                                    date_added={product.date_added}
                                    date_closed={product.date_closed}
                                    withText={1}
                                ></Timer>
                                {/* <h6>{dateFormatFront(product.date_closed)}</h6> */}
                            </div>
                        </div>
                    </div>
                    <div>
                        <ImageGallery
                            items={images}
                            thumbnailPosition="bottom"
                            showNav={false}
                            showBullets={false}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            autoPlay={true}
                            onClick={redirectFile}
                            onErrorImageURL="logo512.png"
                        />
                        {/* <PhotoSwipeGallery
                            items={images}
                            isOpen={isOpen}
                            onClose={() => setOpen(false)}
                            options={options}
                        /> */}
                    </div>
                    {isAuthenticated ? (
                        <div className="mainCondCnt d-flex align-items-start justify-content-between my-2">
                            <div className="conditionCnt">
                                <div className="conditions d-flex align-items-center">
                                    <label>Condition :</label>
                                    <h6>
                                        {' '}
                                        {phrase[product.condition] ||
                                            product.condition.charAt(0).toUpperCase() +
                                                product.condition.slice(1)}
                                    </h6>
                                </div>
                                <div key="currentBid">
                                    <label>
                                        {' '}
                                        {product.bid_count
                                            ? phrase.current_bid
                                            : phrase.starting_price}{' '}
                                    </label>
                                    <h1 className="price">
                                        {product.currency_symbol}
                                        {product.wprice} &nbsp;
                                        {product.currency}
                                    </h1>
                                </div>
                            </div>
                            {isAuthenticated ? (
                                <Button
                                    className="viewBids"
                                    onClick={() => setBidHistoryValue(product.id)}
                                >
                                    {' '}
                                    {phrase.view_all_bids}({product.bid_count})
                                </Button>
                            ) : (
                                <p>Bids ({product.bid_count})</p>
                            )}
                        </div>
                    ) : null}

                    {isAuthenticated ? (
                        <SecondaryButton
                            label="Bid Now"
                            btnSize="small"
                            onClick={() =>
                                history.push(
                                    `/product/view/${product?.id}/${titleUrlFormat(
                                        product?.title,
                                    )}`,
                                )
                            }
                        />
                    ) : (
                        <SecondaryButton
                            label="Login To Bid"
                            btnSize="small"
                            onClick={() => setLogPopup(true)}
                        />
                    )}
                    <div className="description my-4">
                        <Accordion defaultExpanded>
                            <AccordionSummary>Product Description</AccordionSummary>
                            <AccordionDetails>
                                {product.csvDataRaw?.length && isAuthenticated ? (
                                    <h6 className="lotCount">
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50]}
                                            component="div"
                                            count={product.csvDataRaw?.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={onHandlePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </h6>
                                ) : null}
                                <table className="table table-responsive saTable table-striped">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">Item No</th>
                                            <th scope="col">{phrase.brand}</th>
                                            <th scope="col">{phrase.product_name}</th>
                                            <th scope="col">{phrase.category}</th>
                                            <th scope="col">Item Condition</th>
                                            <th scope="col">{phrase.qty}</th>
                                        </tr>
                                    </thead>

                                    {/* {product.csvData == '' ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan="6" className="text-center">
                                                    {phrase.no_line_items_available}
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody
                                            className="mtInner"
                                            dangerouslySetInnerHTML={{
                                                __html: product.csvData,
                                            }}
                                        ></tbody>
                                    )} */}
                                    {!product.csvDataRaw?.length ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan="6" className="text-center">
                                                    No Items Found!
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : isAuthenticated ? (
                                        <tbody className="mtInner">
                                            {product.csvDataRaw
                                                ?.slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage,
                                                )
                                                ?.map((val) => (
                                                    <tr>
                                                        <td className="text-center">
                                                            {val.item_no}
                                                        </td>
                                                        <td className="text-center">{val.brand}</td>
                                                        <td className="text-center">
                                                            {val.product_name}
                                                        </td>
                                                        <td className="text-center">
                                                            {val.category}
                                                        </td>
                                                        <td className="text-center">
                                                            {val.itemcondition}
                                                        </td>
                                                        <td className="text-center">{val.qty}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan="6" className="text-center">
                                                    Log in to see all items
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                                {product.document && product.market_status == 'open' ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <PrimaryButton onClick={downloadFile} width="40%">
                                            <span
                                                className="material-icons"
                                                style={{ marginRight: '3px' }}
                                            >
                                                cloud_download
                                            </span>{' '}
                                            {phrase.download_complete_manfiest}
                                        </PrimaryButton>
                                    </div>
                                ) : null}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary>Condition</AccordionSummary>
                            <AccordionDetails>
                                {ReactHtmlParser(product?.desc_condition)}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary>FAQ</AccordionSummary>
                            <AccordionDetails>
                                {ReactHtmlParser(product?.faqContent)}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary>Shipping</AccordionSummary>
                            <AccordionDetails>
                                {ReactHtmlParser(phrase.productview_1)}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary>Payment Note</AccordionSummary>
                            <AccordionDetails>{phrase.payment_note_details}</AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            ) : (
                <div className="auctionView container-fluid mt-2">
                    <div className="customContainer">
                        <div className="d-flex justify-content-start">
                            <Button className="backBtn my-2" onClick={() => history.goBack()}>
                                <span className="material-icons">arrow_back</span>Back
                            </Button>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 ">
                                {user.isBuyer && product.isclosed >= 0 ? (
                                    <FavoriteCheckbox
                                        watchlisted={product.isWatchlisted}
                                        project_id={product.id}
                                    />
                                ) : null}

                                <ImageGallery
                                    items={images}
                                    thumbnailPosition="bottom"
                                    showNav={false}
                                    showBullets={false}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    autoPlay={true}
                                    onClick={redirectFile}
                                />
                                <PhotoSwipeGallery
                                    items={images}
                                    isOpen={isOpen}
                                    onClose={() => setOpen(false)}
                                    options={options}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-12  pvInfo">
                                <h2 className="pvTitle"> {product.title} </h2>

                                <div className="pvGrid pvMainGrid">
                                    <div className="pvGrid">
                                        <label> {phrase.no_of_items} </label>
                                        <span> {product.qty} </span>
                                    </div>
                                    <div className="pvGrid">
                                        <label> {phrase.condition} </label>
                                        <span>
                                            {phrase[product.condition]
                                                ? phrase[product.condition]
                                                : product.condition}
                                        </span>
                                    </div>
                                </div>

                                <div className="pvGrid pvMainGrid">
                                    <div className="pvGrid">
                                        <label> {phrase.lot_size} </label>
                                        <span>
                                            {phrase[product.lottype]} {phrase.lot}
                                        </span>
                                    </div>

                                    <div className="pvGrid">
                                        <label> {phrase.auction_type} </label>
                                        <span>
                                            {product.auctiontype == 'live'
                                                ? phrase.live_auction
                                                : product.auctiontype == 'lineitem'
                                                ? phrase.line_item_auction
                                                : phrase.blind_auction}{' '}
                                        </span>
                                    </div>
                                </div>

                                <div className="pvGrid pvMainGrid">
                                    <div className="pvGrid">
                                        <label> {phrase.location} </label>
                                        <span> {product.city} </span>
                                    </div>
                                    <div className="pvGrid">
                                        <label>REF NO</label>
                                        <span>{product.upc}</span>
                                    </div>
                                </div>

                                <div className="pvGrid pvMainGrid">
                                    <div className="pvGrid">
                                        <label>{phrase.manufacturer}</label>
                                        <span> {product.manufacturer} </span>
                                    </div>
                                    <div className="pvGrid">
                                        <label>Model</label>
                                        <span>{product.modelnumber}</span>
                                    </div>
                                </div>

                                <div className="pvGrid pvMainGrid">
                                    <div className="pvGrid">
                                        <label>Size</label>
                                        <span> {product.size} </span>
                                    </div>
                                    <div className="pvGrid">
                                        <label>{phrase.finish}</label>
                                        <span>{product.finish}</span>
                                    </div>
                                </div>
                                <div className="catgGrid">
                                    <div className="pvGrid">
                                        <label> {phrase.category} </label>
                                        <span> {categories} </span>
                                    </div>
                                </div>

                                <div className="pvDesc sdsd">
                                    <label> {phrase.description} </label>
                                    <ReadMore
                                        limit={50}
                                        data={product.desc_proc}
                                        setHTML={true}
                                        buttonText={phrase.view_more}
                                        readMoreFunction={setProductDescPopup}
                                    />
                                    {/* <p
                                dangerouslySetInnerHTML={{
                                    __html:
                                        product.desc_proc.length > 150
                                            ? product.desc_proc.substring(0, 150)
                                            : product.desc_proc,
                                }}
                            ></p>
                            {product.desc_proc.length > 150 ? (
                                <a className="font-25" onClick={() => setProductDescPopup(true)}>
                                    {phrase.view_more}
                                </a>
                            ) : null} */}
                                </div>
                            </div>

                            {/* {product.auctiontype == 'lineitem' ? null : ( */}
                            <div className="col-lg-4 col-md-12 col-12 biddingCardCnt">
                                {hideBidding ? (
                                    ''
                                ) : (
                                    <BiddingCard
                                        product={product}
                                        phrase={phrase}
                                        setProduct={setProduct}
                                        user={user}
                                    />
                                )}
                            </div>
                            {/* )} */}

                            <div className="col-lg-8 col-12 lotDetails">
                                {product.currencyexist ? (
                                    <CurrencyConverter product={product} />
                                ) : null}
                                {product.document ? (
                                    <div className="pull-right">
                                        <SecondaryButton onClick={downloadFile}>
                                            <span
                                                className="material-icons"
                                                style={{ marginRight: '3px' }}
                                            >
                                                cloud_download
                                            </span>{' '}
                                            {phrase.download_complete_manfiest}
                                        </SecondaryButton>
                                    </div>
                                ) : null}
                                <h2> {phrase.lot_details} </h2>
                                <div className="customTabs">
                                    <AppBar position="static">
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="Product Information"
                                        >
                                            <Tab
                                                label={phrase.products_description}
                                                {...a11yProps(0)}
                                            />
                                            <Tab label={phrase.condition} {...a11yProps(1)} />
                                            <Tab label={phrase.faq} {...a11yProps(2)} />
                                            <Tab label={phrase.shipping} {...a11yProps(3)} />
                                            <Tab label={phrase.payment_note} {...a11yProps(4)} />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel value={value} index={0}>
                                        {product.csvDataRaw?.length && isAuthenticated ? (
                                            <h6 className="lotCount">
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    component="div"
                                                    count={product.csvDataRaw?.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={onHandlePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </h6>
                                        ) : null}
                                        <table className="table table-responsive saTable table-striped">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col">Item No</th>
                                                    <th scope="col">{phrase.brand}</th>
                                                    <th scope="col">{phrase.product_name}</th>
                                                    <th scope="col">{phrase.category}</th>
                                                    <th scope="col">Item Condition</th>
                                                    <th scope="col">{phrase.qty}</th>
                                                </tr>
                                            </thead>

                                            {/* {product.csvData == '' ? (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="6" className="text-center">
                                                            {phrase.no_line_items_available}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <tbody
                                                    className="mtInner"
                                                    dangerouslySetInnerHTML={{
                                                        __html: product.csvData,
                                                    }}
                                                ></tbody>
                                            )} */}
                                            {!product.csvDataRaw?.length ? (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="6" className="text-center">
                                                            No Items Found!
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ) : isAuthenticated ? (
                                                <tbody className="mtInner">
                                                    {product.csvDataRaw
                                                        ?.slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage + rowsPerPage,
                                                        )
                                                        ?.map((val) => (
                                                            <tr>
                                                                <td className="text-center">
                                                                    {val.item_no}
                                                                </td>
                                                                <td className="text-center">
                                                                    {val.brand}
                                                                </td>
                                                                <td className="text-center">
                                                                    {val.product_name}
                                                                </td>
                                                                <td className="text-center">
                                                                    {val.category}
                                                                </td>
                                                                <td className="text-center">
                                                                    {val.itemcondition}
                                                                </td>
                                                                <td className="text-center">
                                                                    {val.qty}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="6" className="text-center">
                                                            Log in to see all items
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </table>

                                        {product.document && product.market_status == 'open' ? (
                                            <div style={{ textAlign: 'center' }}>
                                                <PrimaryButton onClick={downloadFile} width="40%">
                                                    <span
                                                        className="material-icons"
                                                        style={{ marginRight: '3px' }}
                                                    >
                                                        cloud_download
                                                    </span>{' '}
                                                    {phrase.download_complete_manfiest}
                                                </PrimaryButton>
                                            </div>
                                        ) : null}
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {product?.desc_condition?.replace(/<p>|<\/p>/g, '')}
                                    </TabPanel>
                                    <TabPanel
                                        value={value}
                                        index={2}
                                        html={product.faqContent}
                                    ></TabPanel>
                                    <TabPanel value={value} index={3}>
                                        {ReactHtmlParser(phrase.productview_1)}
                                    </TabPanel>
                                    <TabPanel value={value} index={4}>
                                        {phrase.payment_note_details}
                                    </TabPanel>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                <div className="col-md-12 assuranceCnt">
                    <div className="customContainer ">
                        <div className="row">
                            <div className="col-lg-8 col-12">
                                <div className="assuranceCard d-flex justify-content-between">
                                    <span className="material-icons">verified</span>
                                    <div>
                                        <h2>{phrase.buy_with_confidence}</h2>
                                        <p>{phrase.productview_2}</p>
                                        <p>{phrase.productview_3}</p>
                                        <p>{phrase.productview_4}</p>
                                        <span className="leaf">
                                            <span className="material-icons">eco</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

                    {/* Long Product Description Modal*/}
                    <Popup
                        open={productDescPopup}
                        modaltitle={phrase.description}
                        handleClose={productDescClose}
                        footer={<SecondaryButton label={phrase.close} onClick={productDescClose} />}
                    >
                        <div
                            style={{ textAlign: 'left' }}
                            dangerouslySetInnerHTML={{
                                __html: product.desc_proc,
                            }}
                        ></div>
                    </Popup>
                    <BidHistory user={userRef.current} type={product.auctiontype} />
                </div>
            )}
            <Dialog
                open={logPopup}
                function={toggleLogOpen}
                // maxWidth={'md'}
                className="lgnWrpr"
            >
                <div className="lgnCntnr">
                    <IconButton className="popClrBtn" onClick={toggleLogOpen}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                    <Login
                        autoComplete={1}
                        closeLogin={toggleLogOpen}
                        // openRegister={toggleRegOpen}
                        onMenuClose={() => setAnchorMenu(null)}
                    />
                </div>
            </Dialog>
        </>
    )
}

export default SingleAuction
