import React from 'react'
import { siteName } from '../../Utils'

export const terms_of_service = (
    <>
        <h4>{siteName} Website Terms of Use</h4>
        <p>(updated Sept. 5 2019)</p>

        <p>
            THIS WEBSITE IS OWNED AND OPERATED BY Ship Cycle Auctions LLC AND ITS AFFILIATES (“Ship
            Cycle Auctions”, “WE”, “OUR” AND “US”). ACCESS AND USE OF THIS WEBSITE AND ITS RELATED
            SERVICES, WEB PAGES, DATA, BLOGS, PRODUCTS AND COMMUNITY PORTAL (COLLECTIVELY, THE
            “WEBSITE”) ARE PROVIDED BY Ship Cycle Auctions TO YOU ON CONDITION THAT YOU ACCEPT THESE
            TERMS OF USE, AND BY ACCESSING OR USING THIS WEBSITE, YOU AGREE TO THESE TERMS OF USE.
            IF YOU DO NOT AGREE TO ACCEPT AND ABIDE BY THESE TERMS OF USE YOU SHOULD NOT ACCESS OR
            USE THIS WEBSITE.
        </p>

        <p>
            These Terms of Use, the Privacy Policy and all other documents referenced herein govern
            the relationship between you, the Website visitor and/or member (“you”) and Ship Cycle
            Auctions with respect to your use of the this Website. You agree that the agreement
            formed by these Terms of Use is binding like any written, negotiated agreement signed by
            you, and you agree to be bound by, and fully comply with, its terms. You represent and
            warrant that you have all necessary right, power and authority to enter into this
            agreement and to perform and otherwise discharge all of your obligations hereunder.
        </p>

        <p>
            We reserve the right at any time to change: (i) these Terms of Use; (ii) this Website,
            including terminating, eliminating, supplementing, modifying, adding or discontinuing
            any content or feature or data or service on or available through this Website or the
            hours that they are available; (iii) any fees or charges, if any, related to the use of
            this Website; and (iv) the equipment, hardware or software required to use and access
            this Website.
        </p>

        <p>
            Any changes we make to these Terms of Use will be effective immediately upon notice,
            which we may provide by any means including, without limitation, posting on this
            Website. Your continued use of this Website after such notice will be deemed acceptance
            of such changes. You should return to this Website periodically to ensure you are
            familiar with the most current version of these Terms of Use.
        </p>

        <p>
            You may not use our Website if you are not able to form legally binding contracts (for
            example, if you are under 18 years old), or are temporarily or indefinitely suspended
            from using our sites, services, applications or tools.
        </p>

        <p>
            You may not fail to pay for items purchased by you, unless you have a valid reason as
            set out in a Ship Cycle Auctions policy, for example, the seller has materially changed
            the item&apos;s description after you bid, a clear typographical error is made, or you
            cannot contact the seller.
        </p>

        <p>
            You may not fail to deliver items sold by you, unless you have a valid reason as set out
            in a Ship Cycle Auctions policy, for example, the buyer fails to comply with the posted
            terms in your listing or you cannot contact the buyer.
        </p>

        <p>
            You may not interfere with the security of, or otherwise abuse this Website or any
            system resources, services or networks connected to or accessible through this Website.
            You may only use this Website for lawful purposes.
        </p>

        <p>
            Ship Cycle Auctions does not knowingly or intentionally collect Personal Data (as
            defined in our Privacy Policy) from children under the age of 13. This Website is
            directed at adults and is intended for use only by adults over the age of 13. If you are
            under the age of 13, you may not submit any Personal Data to Ship Cycle Auctions at or
            through this Website, and you should consult with an adult for assistance in using this
            Website.
        </p>

        <p>1. JURISDICTION</p>

        <p>
            If you are residing in a jurisdiction that restricts the ability to enter into
            agreements such as set out in these Terms of Use, you may not enter into this agreement
            or use this Website.
        </p>

        <p>
            Furthermore, if you are residing in a jurisdiction where it is forbidden by law to
            participate in the activities or services offered by this Website, you may not enter
            into this agreement or use this Website. By using this Website you are explicitly
            stating that you have verified in your own jurisdiction if your use of this Website is
            allowed.
        </p>

        <p>
            We make no representation that materials in this Website are appropriate or available
            for use in all locations. Those who choose to access the Website do so on their own
            initiative and at their own risk, and are responsible for compliance with local laws, if
            and to the extent local laws are applicable.
        </p>

        <p>
            We reserve the right to limit the availability of the Website, materials, or other
            products described or offered thereon to any person, geographic area, or jurisdiction we
            so desire, at any time and in our sole discretion, and to limit the quantities of any
            such services, materials, or other product provided.
        </p>

        <p>2. PRIVACY</p>

        <p>
            Ship Cycle Auctions is committed to respecting the privacy of the Personal Data of the
            individuals with whom we interact. We have developed a Privacy Policy to describe our
            privacy practices and how we collect, use and disclose the Personal Data of those
            individuals who visit this Website. Please see our Privacy Policy for further
            information.
        </p>

        <p>3. MEMBERS</p>

        <p>
            In order to access and use certain services available on this Website, you must open and
            maintain an account (“Account”) with us. If you do not agree to these Terms of Use, you
            shall not have the right to use such services.
        </p>

        <p>
            During the registration process we will collect Personal Data that may include (but is
            not limited to) your name, email address, password, and home or business address. Your
            information may be used by us:
        </p>

        <ul>
            <li>
                to verify your eligibility to create and to maintain your Account and to use
                features of the Website limited only to registered users;
            </li>
            <li>to ensure that your Account, including your email, is unique;</li>
            <li>to enable us to provide certain security and privacy safeguards;</li>
            <li>to deal with security, debugging and technical support issues;</li>
            <li>to protect ourselves and others from abuse; and</li>
            <li>As permitted in our Privacy Policy.</li>
        </ul>

        <p>
            All of the information you provide to us or that we collect from you in connection with
            your use of the Website will be governed by these Terms of Use and our Privacy Policy.
            You agree at all times to: (i) provide accurate, current and complete information about
            yourself as prompted by our registration form or otherwise; and (ii) maintain and update
            your information (including your e-mail address) to keep it accurate, current and
            complete. You acknowledge that, if any information provided by you is untrue,
            inaccurate, not current or incomplete, we may terminate your Account and your ability to
            use this Website and, in our sole discretion, to report you to the appropriate
            authorities.
        </p>

        <p>
            You understand and agree that, in addition to the foregoing restrictions, we reserve the
            right to change, remove, alter or delete any username at any time and for any reason in
            our sole discretion. You will be responsible for the confidentiality and use of your
            username and password and agree not to transfer your right to use or access this Website
            via your username or password to any third person.
        </p>

        <p>
            YOU ARE ENTIRELY RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF YOUR USERNAME AND
            PASSWORD AND FOR ANY AND ALL ACTIVITIES THAT ARE CONDUCTED THROUGH YOUR ACCOUNT.
        </p>

        <p>
            You agree to notify us immediately of any unauthorized use, theft or misappropriation of
            your account, username, or password. We shall not be liable for any loss that you incur
            as a result of someone else using your username or password, either with or without your
            knowledge.
        </p>

        <p>
            You acknowledge and agree that Ship Cycle Auctions is not responsible for the conduct of
            users in the interactive areas of Ship Cycle Auctions’s websites. Ship Cycle Auctions
            SPECIFICALLY DISCLAIMS ANY RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY FOR ANY
            LOSS, LIABILITY, CLAIMS, SUITS, COSTS, EXPENSES, DAMAGES (WHETHER ACTUAL, CONSEQUENTIAL,
            PUNITIVE OR OTHERWISE), INJURY, OR OTHER CAUSE OF ANY KIND BASED UPON OR RESULTING FROM
            ANY MATERIAL OR CONTENT POSTED IN THE INTERACTIVE AREAS OF Ship Cycle Auctions’S
            WEBSITES. You acknowledge and agree that you may be exposed to content that you may find
            offensive, indecent or objectionable and, that in such respect, you use Ship Cycle
            Auctions’s websites at your own risk.
        </p>

        <p>4. CODE OF CONDUCT</p>

        <p>
            You are solely responsible for the content of any communications by you with third
            parties, including any communications about, concerning or through this Website.
        </p>

        <p>
            You agree that you will not attempt to, nor permit any third party to, enter restricted
            areas of Ship Cycle Auctions&apos;s computer systems or perform functions that you are
            not authorized to perform pursuant to these Terms of Use. Ship Cycle Auctions may,
            without notice, temporarily suspend your, or any other party’s access to this Website,
            (including, without limitation, any specific areas hosted within this Website) by
            deactivating any password(s) or links to the Internet if we reasonably suspect that you,
            or any other parties, are obtaining unauthorized access to our other systems or
            information, or are using otherwise valid user identifications or passwords in any other
            unauthorized manner. These suspensions will be for such periods of time as Ship Cycle
            Auctions may in its sole discretion determine is necessary to permit the thorough
            investigation of such suspended activity.
        </p>

        <p>
            While using this Website you agree to comply with all applicable laws, rules and
            regulations (including, but not limited to, export/import laws and laws relating to
            privacy, obscenity, copyright, trademark, confidential information, trade secret, libel,
            slander or defamation). We reserve the right, in our sole discretion, to take any
            actions we deem necessary and appropriate to preserve the integrity of this Website.
        </p>

        <p>
            If we believe you are abusing the Ship Cycle Auctions Website in any way, we may, in our
            sole discretion and without limiting other remedies, limit, suspend, or terminate your
            access to and ability to use the Website.
        </p>

        <p>
            WE CANNOT AND DO NOT ASSURE THAT OTHER USERS ARE OR WILL BE COMPLYING WITH THE FOREGOING
            CODE OF CONDUCT OR ANY OTHER PROVISIONS OF THESE TERMS OF USE, AND, AS BETWEEN YOU AND
            US, YOU HEREBY ASSUME ALL RISK OF HARM OR INJURY RESULTING FROM ANY SUCH LACK OF
            COMPLIANCE.
        </p>

        <p>5. AVAILABILITY</p>

        <p>
            While we endeavor to keep downtime to a minimum, we do not guarantee that this Website
            will be uninterrupted, secure or error-free. We reserve the right to interrupt/suspend
            this Website, or any part thereof, with or without prior notice for any reason and you
            shall not be entitled to any refunds of fees for interruption of this Website.
        </p>

        <p>6. CURRENCY OF WEBSITE</p>

        <p>
            Ship Cycle Auctions updates the information on this Website periodically. However, Ship
            Cycle Auctions cannot guarantee or accept any responsibility or liability for the
            accuracy, currency or completeness of the information on this Website. Ship Cycle
            Auctions may revise, supplement or delete information, services and/or the resources
            contained in this Website and reserves the right to make such changes without prior
            notification to past, current or prospective visitors. Contact your Ship Cycle Auctions
            sales representative for verification of prices, specials and dates of promotions.
        </p>

        <p>7. LINKED WEBSITES</p>

        <p>
            This Website may provide links to third party websites for your convenience only,
            including, without limitation, links to third parties that sell our products and social
            media sites such as Facebook, Twitter, LinkedIn, and YouTube. The inclusion of these
            links does not imply that Ship Cycle Auctions monitors or endorses these websites or
            their respective practices. Ship Cycle Auctions does not accept any responsibility for
            such websites. Ship Cycle Auctions shall not be responsible or liable, directly or
            indirectly, for any damage or loss, caused or alleged to be caused by or in connection
            with the use of or the reliance upon any information, content, goods or services
            available on or through any third party websites or linked resources. These Terms of Use
            and our Privacy Policy do not apply to your use of any third party websites, so be sure
            to review any applicable terms and policies of third party sites.
        </p>

        <p>8. INTERNET SOFTWARE OR COMPUTER VIRUSES</p>

        <p>
            Due to technical difficulties with the Internet, Internet software or transmission
            problems could produce inaccurate or incomplete copies of information contained on this
            Website. Due to the ability to share certain content and materials, computer viruses or
            other destructive programs may also be inadvertently downloaded from this Website.
        </p>

        <p>
            Ship Cycle Auctions shall not be responsible or liable for any software, computer
            viruses or other destructive, harmful or disruptive files or programs that may infect or
            otherwise impact your use of your computer equipment or other property on account of
            your access to, use of, or browsing on this Website or your downloading of any of User
            Materials or other content from this Website. Ship Cycle Auctions recommends that you
            install appropriate anti-virus or other protective software.
        </p>

        <p>9. OFFERINGS</p>

        <p>
            Ship Cycle Auctions provides you with the ability to purchase or access a variety of
            product and service offerings made available by Ship Cycle Auctions and various third
            party providers (“Offerings”) both directly from Ship Cycle Auctions and through
            resellers and distributors. EACH OFFERING MAY BE SUBJECT TO OFFERING-SPECIFIC TERMS OF
            USE AND/OR PRIVACY POLICIES, WHICH MAY SUPERCEDE THE TERMS OF THESE TERMS OF USE. Ship
            Cycle Auctions ENCOURAGES YOU TO CAREFULLY READ THE TERMS OF USE AND/OR PRIVACY POLICY
            OF ANY OFFERING BEFORE ACCESSING OR USING THAT OFFERING.
        </p>

        <p>
            Each seller is responsible for the accuracy and content of the listing and products
            offered on or through the Website.
        </p>

        <p>
            When you are purchasing products on the Website you are responsible for reading the full
            product listing before making a commitment to buy. Buyers agree that they will not
            transship any product from the country in which the buyer receives the goods to another
            country.
        </p>

        <p>10. RETURNS</p>

        <p>
            Ship Cycle Auctions will not accept the return of any Product unless sold by Ship Cycle
            Auctions or an affiliated entity. If there is a difference of condition and/or
            composition greater than 2%&nbsp;return requests must be made withing 7 days of receipt
            of goods.&nbsp;Any Product purchased from a third party seller via Ship Cycle
            Auctions.com must be returned to that seller or as designated by that seller. Buyer may
            only return erroneously shipped Products that do not match the order in a material
            manner or Products that were materially damaged prior to shipment. Products damaged
            after shipment may not be returned. In order to be eligible to receive credit for
            returned Products, Buyer must adhere to Ship Cycle Auctions’s then current returns
            processing guidelines. Buyer must obtain a valid return authorization number (“RMA”)
            from Ship Cycle Auctions for all returns prior to returning any Product. Ship Cycle
            Auctions has no obligation to issue RMAs. Buyer is responsible for ensuring that the RMA
            is clearly visible on the address label of the Product packaging and for complying with
            all other Ship Cycle Auctions requirements provided to Buyer when the RMA is issued.
            Unless otherwise agreed in writing by Ship Cycle Auctions, all Product returns from
            Buyer are DDP (Incoterms 2010) Ship Cycle Auctions’s designated facility, and title and
            risk of loss shall transfer to Ship Cycle Auctions upon receipt and acceptance of
            returned Products at Ship Cycle Auctions’s facility. If Buyer desires to return any
            Products, Buyer must initiate a new order for the replacement Products. Ship Cycle
            Auctions may refuse delivery of any package without a valid, clearly visible RMA. All
            Products erroneously shipped by Ship Cycle Auctions must be returned with the original
            packaging intact (including manufacturer’s shrink wrap) and otherwise in unused,
            resalable condition. Credit, if any, will be provided for Product returned in accordance
            with Ship Cycle Auctions’s return policies at the time the RMA was issued, provided
            Buyer is not in breach of any of these Sales Terms. If Buyer returns any Products
            without Ship Cycle Auctions’s authorization or does not comply with Ship Cycle
            Auctions’s return requirements, those Products may be subject to return to the shipping
            location and, if refused, Ship Cycle Auctions may consider the Products abandoned and
            dispose of them, without crediting Buyer’s account. Ship Cycle Auctions reserves the
            right to charge a restocking fee for handling any Product that is erroneously returned.
            Ship Cycle Auctions’s sole liability for any returned Products will be acceptance of
            their return and issuance of credits pursuant to Ship Cycle Auctions’s then current
            returns processing guidelines.
        </p>

        <p>&nbsp;</p>

        <p>11. SWEEPSTAKES, CONTESTS AND GAMES</p>

        <p>
            Any sweepstakes, contests, and games that are accessible through this Website are
            governed by specific rules. By entering such sweepstakes or contests or participating in
            such games you will become subject to those rules. We urge you to read the applicable
            rules, which are linked from the particular activity, and to review our Privacy Policy
            which addresses our commitments in respect of the information you submit in connection
            with such sweepstakes, contests, and games. These promotions may be administered by
            third parties and additional terms and policies may apply to specific promotions.
        </p>

        <p>12. OUR MATERIALS</p>

        <p>
            This Website is owned and operated by Ship Cycle Auctions in conjunction with others
            pursuant to contractual arrangements.
        </p>

        <p>
            Unless otherwise specified, all materials appearing on this site, including the text,
            site design, logos, graphics, icons, and images, as well as the selection, assembly and
            arrangement thereof, are the sole property of Ship Cycle Auctions Inc. and its
            licensors. ALL RIGHTS RESERVED.
        </p>

        <p>
            Ship Cycle Auctions may make certain content, software or other electronic materials
            (including all files, text, URLs, video, audio and images contained in or generated by
            such materials, and accompanying data) (collectively, “Ship Cycle Auctions Materials”)
            available to you from this Website, from time to time. If you download or use Ship Cycle
            Auctions Materials, you agree that such materials: (i) are licensed for your limited use
            only and not sold or assigned; (ii) may not be modified, used to create a derivative
            work, incorporated into any other work or otherwise exploited without Ship Cycle
            Auctions’s prior written permission; and (iii) may only be used in compliance with any
            additional license terms accompanying such materials. Ship Cycle Auctions neither
            transfers the title, nor grants any intellectual property rights to the Ship Cycle
            Auctions Materials. Ship Cycle Auctions retains full and complete title to the Ship
            Cycle Auctions Materials as well as all intellectual property rights therein. You agree
            not to sell, share, redistribute, or reproduce the Ship Cycle Auctions Materials. You
            further agree not to decompile, reverse-engineer, disassemble, or otherwise convert any
            compiled Ship Cycle Auctions Materials into a human-perceivable form. Ship Cycle
            Auctions or its licensors own all related trademarks and logos, and you agree not to
            copy or use them in any manner.
        </p>

        <p>
            Some Ship Cycle Auctions Materials contained in this Website may be made available only
            for specific users and purposes, and shall be deemed confidential in nature
            (“Confidential Information”). Ship Cycle Auctions will use reasonable efforts to
            identify such Confidential Information upon disclosure, but Ship Cycle Auctions’s
            failure to properly identify any Confidential Information does not eliminate your
            obligation to maintain the confidentiality of such information. Any Confidential
            Information provided to you must be protected and used only for the purpose for which it
            is provided to you, and shall not be disclosed or shared with any person(s) or used for
            any purpose other than those expressly permitted by Ship Cycle Auctions in writing.
        </p>

        <p>
            All rights not expressly granted herein are reserved by Ship Cycle Auctions. Any
            unauthorized use of the materials appearing on this site may violate copyright,
            trademark and other applicable laws and could result in criminal or civil penalties.
        </p>

        <p>
            This Website, (including its organization and presentation and Ship Cycle Auctions
            Materials) is the property of Ship Cycle Auctions and its licensors and may be protected
            by intellectual property laws including laws relating to copyrights, trade-marks,
            trade-names, internet domain names and other similar rights.
        </p>

        <p>13. YOUR MATERIALS</p>

        <p>
            Ship Cycle Auctions welcomes you to use the Website and the forums offered by Ship Cycle
            Auctions through the Website to post, download, display, perform, transmit or otherwise
            distribute appropriate information or other content at your direction, including but not
            limited to your name, username, personal comments, opinions, and ideas for product use
            using Ship Cycle Auctions’s products, photographs, video material, or other content
            (collectively, “User Materials”), subject to these Terms of Use.
        </p>

        <p>
            By providing User Materials to Ship Cycle Auctions through this Website, and other
            websites or forums owned by Ship Cycle Auctions, you represent that you are voluntarily
            providing such User Materials. You agree that your User Materials are not confidential
            and no confidential relationship is established with Ship Cycle Auctions. By posting
            User Materials to this Website, you represent and warrant that you have all right, title
            and interest necessary to post such User Materials and you agree that Ship Cycle
            Auctions may copy, reproduce, publish, distribute, display, transmit, sell, prepare
            derivative works based on, and otherwise use (for any purpose whatsoever and in any
            medium) any of your User Materials, without attribution or compensation to you.
        </p>

        <p>
            You hereby grant to Ship Cycle Auctions a non-exclusive, perpetual, unlimited,
            royalty-free and irrevocable license to copy, reproduce, publish, distribute, display,
            perform, transmit, broadcast, sell, offer for sale, license, modify, prepare derivative
            works based on, and otherwise use (for any purpose whatsoever and in any form or medium
            whatsoever) your User Materials, including any and all copyrights, patents, publicity
            rights and privacy rights therein, by and on behalf of Ship Cycle Auctions without
            compensation for any purpose on any medium, whether printed, digital, or otherwise. The
            foregoing authorization and any use of User Materials that you submit is without
            royalties, fees, or limitations. You represent and warrant that you are fully authorized
            to make all authorizations, licenses and/or warrantees that are made herein and
            acknowledge that any submissions that you make will not be returned to you regardless of
            whether the User Materials are used by Ship Cycle Auctions.
        </p>

        <p>
            You agree that Ship Cycle Auctions may publish or otherwise disclose your name or
            username in connection with your User Materials. By posting User Materials on this
            Website, you warrant and represent that you own the rights to the User Materials or are
            otherwise authorized to post, distribute, display, perform, transmit, or otherwise
            distribute User Materials and that such User Materials do not violate any applicable
            laws or any rights of third parties. You agree that the burden of proving that any User
            Materials do not violate any laws or third party rights rests solely with you.
        </p>

        <p>
            You are solely responsible for your User Materials. You are solely responsible for
            making and keeping backup copies of your User Materials and for applying the appropriate
            level of access rights to same. Ship Cycle Auctions shall not be responsible or liable
            for the deletion or accuracy of any User Materials, the failure to store, transmit,
            encrypt (or otherwise secure) or receive any User Materials, or the storage,
            transmission, encryption (or other security) or receipt of any other communication
            involving the use of this Website.
        </p>

        <p>
            You are solely responsible for obtaining and maintaining any necessary consents or
            permissions from any third party whose information may be collected, recorded,
            processed, stored, used, disclosed, transferred, exchanged or otherwise handled as a
            result of, or as part of, any User Materials or any communications involving the use of
            this Website and agree to obtain and maintain all such consents or permissions.
        </p>

        <p>14. DISCLAIMER OF WARRANTIES</p>

        <p>
            THIS WEBSITE, THE SERVICES AND THE CONTENT AND OTHER MATERIALS AVAILABLE THEREIN
            (“MATERIALS”) ARE PROVIDED “AS IS” WITHOUT REPRESENTATION, WARRANTY OR CONDITION OF ANY
            KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
            REPRESENTATIONS, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, OR FITNESS FOR A
            PARTICULAR PURPOSE. Ship Cycle Auctions DOES NOT REPRESENT OR WARRANT THAT THIS WEBSITE,
            THE SERVICES OR THE MATERIALS WILL MEET YOUR REQUIREMENTS OR THAT THEIR USE WILL BE
            SECURE, UNINTERRUPTED OR ERROR FREE. SOME STATES AND JURISDICTIONS DO NOT ALLOW
            LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. WHEN
            THE IMPLIED WARRANTIES ARE NOT ALLOWED TO BE EXCLUDED IN THEIR ENTIRETY, THEY WILL BE
            LIMITED TO THE SHORTEST DURATION PERMITTED BY LAW. YOU MAY ALSO HAVE OTHER RIGHTS WHICH
            VARY FROM STATE-TO-STATE. Ship Cycle Auctions PROVIDES NO WARRANTIES AND DISCLAIMS ALL
            LIABILITY WITH RESPECT TO ANY PRODUCTS SOLD THROUGH THE WEBSITE BY ANY THIRD PARTY
            (NON-Ship Cycle Auctions) SELLER AND ANY WARRANTIES AND/OR LIABILITIES RELATED TO SUCH
            TRANSACTIONS BETWEEN A BUYER AND THIRD PARTY SELLER SHALL BE DETERMINED BETWEEN THE
            PARTIES.
        </p>

        <p>15. LIMITATION OF LIABILITY</p>

        <p>
            YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THIS WEBSITE AND THE MATERIALS,
            INCLUDING, WITHOUT LIMITATION, ANY OF THE INFORMATION CONTAINED THEREIN.
        </p>

        <p>
            IN NO EVENT WILL Ship Cycle Auctions, ANY OF ITS AFFILIATES, OR THEIR RESPECTIVE
            DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, PARTNERS, OR AGENTS BE LIABLE FOR ANY
            INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY, CONSEQUENTIAL OR OTHER SIMILAR DAMAGES
            WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF PROFITS, INTERRUPTION, LOSS OF BUSINESS
            INFORMATION, OR ANY OTHER PECUNIARY LOSS) IN CONNECTION WITH ANY CLAIM, LOSS, DAMAGE,
            ACTION, SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT OF THESE TERMS OF USE, INCLUDING
            WITHOUT LIMITATION YOUR USE OF, RELIANCE UPON, ACCESS TO, OR EXPLOITATION OF THIS
            WEBSITE, THE MATERIALS OR ANY PART THEREOF, OR ANY RIGHTS GRANTED TO YOU HEREUNDER, EVEN
            IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER THE ACTION IS BASED
            ON CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS
            OR OTHERWISE.
        </p>

        <p>
            IN EVERY EVENT, OUR TOTAL MAXIMUM AGGREGATE LIABILITY UNDER THESE TERMS OF USE OR THE
            USE OR EXPLOITATION OF ANY OR ALL PART OF THIS WEBSITE OR THE MATERIALS IN ANY MANNER
            WHATSOEVER IS LIMITED TO FIVE ($5.00) US DOLLARS.
        </p>

        <p>
            THE CONSIDERATION BEING PAID HEREUNDER DOES NOT INCLUDE ANY CONSIDERATION FOR US TO
            ASSUME ANY RISKS BEYOND THOSE EXPRESSLY ASSUMED HEREIN AND IF ANY SUCH RISKS WERE TO BE
            ASSUMED BY US, WE WOULD NOT HAVE ENTERED INTO THIS AGREEMENT WITHOUT CHARGING
            SUBSTANTIALLY HIGHER FEES.
        </p>

        <p>
            SOME STATES AND JURISDICTIONS DO NOT ALLOW FOR THE LIMITATION OR EXCLUSION OF LIABILITY
            FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT
            APPLY TO YOU.
        </p>

        <p>16. INDEMNIFICATION</p>

        <p>
            You agree to, upon request, defend, indemnify and hold harmless Ship Cycle Auctions, its
            affiliates, and their respective directors, officers, employees, shareholders, partners,
            or agents from any and all liabilities, claims, demands, losses and expenses, including
            attorneys’ fees, which arise directly or indirectly from, or relate to: (1) any breach
            of these Terms of Use for which you are responsible; (2) any User Materials submitted by
            you or through your Account; or (3) your use of this Website or transmission of any
            materials or other content through this Website. We reserve the right, at its own
            expense, to assume the exclusive defense and control of any matter otherwise subject to
            indemnification by you, and you shall not in any event settle any such matter without
            our prior written consent. This indemnification section will survive any termination or
            expiration of these Terms of Use.
        </p>

        <p>17. CONTACTING Ship Cycle Auctions</p>

        <p>
            If you need to contact us regarding this Website or these Terms of Use, please e-mail us
            at{' '}
            <a href="mailto:legaldept@Ship Cycle Auctions.com">legaldept@Ship Cycle Auctions.com</a>
            . You can also contact us via mail addressed to Ship Cycle Auctions LLC, 1605 Rock Creek
            Blvd, Joliet, IL 60431.
        </p>

        <p>18. YOUR COMMENTS</p>

        <p>
            Feel free to email your comments, suggestions and feedback (“Comments”) to Ship Cycle
            Auctions at the e-mail addresses provided in this Website. You agree with respect to any
            Comments you provide, via e-mail or otherwise, that: (i) hawse have no obligation
            concerning such Comments; (ii) such Comments are non-confidential; (iii) we may use,
            disclose, distribute or copy such Comments and may use any ideas, concepts or know-how
            contained in such Comments for any purpose and without restriction or obligation to you
            and without payment of any royalties to you; and (iv) such Comments are truthful and do
            not violate the legal rights of others.
        </p>

        <p>19. ACCESS RELATED TO ALLEGED VIOLATIONS</p>

        <p>
            To ensure that we provide a high quality experience for you and for other users of this
            Website, you agree that we may access your Account and all information and any records
            related thereto on a case-by-case basis to investigate complaints or allegations of
            abuse, infringement of third party rights, or other unauthorized uses of this Website,
            and you agree to give us full access to your Account and all information and records
            related thereto without the need for additional authorization and without any claim to
            privacy or other rights to such Account and related information and records.
        </p>

        <p>
            We do not intend to disclose the existence or occurrence of such an investigation unless
            required by law or if requested by law enforcement officials, but Ship Cycle Auctions
            reserves the right to terminate your Account or your access to this Website immediately,
            with or without notice to you, and without liability to you, if we believe you have
            violated any of these Terms of Use, furnished false or misleading information, or
            interfered with use of this Website by others.
        </p>

        <p>20. MISCELLANEOUS</p>

        <p>
            These Terms of Use are governed by and construed in accordance with the laws of the
            State of California and the United States of America, without regard to their respective
            principles of conflicts of law. You agree to the exclusive personal jurisdiction by the
            federal and state courts located in Orange County, California, United States of America,
            in any matter arising from or related to these Terms of Use and your use of the Website
            and you waive any jurisdictional, venue, or inconvenient forum objections to such
            courts.
        </p>

        <p>
            If any provision of these Terms of Use is found to be unlawful, void, or for any reason
            unenforceable, then that provision shall be deemed severable from these Terms of Use and
            shall not affect the validity and enforceability of any remaining provisions.
        </p>

        <p>
            These Terms of Use set out the entire agreement between us relating to the subject
            matter herein and supersede any and all prior or contemporaneous written or oral
            agreements between us. These Terms of Use are not assignable, transferable or
            sub-licensable by you except with our prior written consent. These Terms of Use provide
            only for the use of this Website. These Terms of Use shall not be construed as a
            teaming, joint venture, or other such arrangement, unless the parties expressly and
            explicitly execute an agreement to that effect. No waiver by either party of any breach
            or default hereunder shall be deemed to be a waiver of any preceding or subsequent
            breach or default. Any heading, caption or section title contained in these Terms of Use
            is inserted only as a matter of convenience and in no way defines or explains any
            section or provision hereof.
        </p>
    </>
)
