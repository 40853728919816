import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import './index.css'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CommonContext from '../../../context/common/commonContext'
import ProductContext from '../../../context/product/productContext'
import { Pagination } from '@material-ui/lab'
import { currencyFormat, dateTimeFormatFunction } from '../../../common/components'
import { Button } from '@material-ui/core'
import { logo, siteName } from '../../../Utils'
import authContext from '../../../context/auth/authContext'

const useStyles = makeStyles((theme, maxWidth) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))

const BidHistory = (props) => {
    const classes = useStyles()
    const [bidPopUp, setBidPopUp] = React.useState({
        popup: false,
        id: 0,
    })
    let [viewProduct, setViewProduct] = useState([])

    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const { bidHistoryValue, clearBidHistoryValue, phrase } = commonContext
    const { get_allbidhistory, getAllBidHistory } = productContext
    // const { isAuthenticated, user } = authContext
    const [user] = useState(props.user)
    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
        id: 0,
    })
    const [customer, setCustomer] = useState([])
    console.log(user, 'user')
    useEffect(() => {
        if (bidHistoryValue) {
            setSearch({ ...search, id: bidHistoryValue, page: 1 })
            setBidPopUp({ popup: true, id: bidHistoryValue })
            clearBidHistoryValue()
        }
    }, [bidHistoryValue])

    useEffect(() => {
        if (search.id !== 0) {
            console.log('GET ALL BID HISTORY: ', search)
            getAllBidHistory(search)
        }
    }, [search])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }

    useEffect(() => {
        setViewProduct(get_allbidhistory.records.length ? get_allbidhistory.records : [])
    }, [get_allbidhistory])

    useEffect(() => {
        //console.log('VIEW PRODUCT: ', viewProduct)
        let temp = []
        let temp1 = [...viewProduct]
        temp1?.reverse().map((val) => {
            if (!temp.includes(val.user_id) && user?.id != val.user_id) {
                temp.push(val.user_id)
            }
        })
        setCustomer(temp)
    }, [viewProduct])

    return (
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={bidPopUp.popup}
            className={`${classes.modal} customModal`}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={bidPopUp.popup}>
                <div className="bidHistoryModal">
                    <div className={classes.paper}>
                        <div className="modal fade show custom-modal">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Bid History</h4>
                                        <Button
                                            className="close"
                                            onClick={() => setBidPopUp({ popup: false, id: 0 })}
                                        >
                                            <span className="material-icons">close</span>
                                        </Button>
                                    </div>
                                    <div className="modal-body cm-body table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{phrase.username}</th>
                                                    <th scope="col">{phrase.date_time}</th>
                                                    <th scope="col">{phrase.amount}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {viewProduct.length > 0
                                                    ? viewProduct.map((data, index) => (
                                                          <>
                                                              <tr>
                                                                  {props.type === 'live' ? (
                                                                      <td>
                                                                          {user?.id == data.user_id
                                                                              ? data.name
                                                                              : `Customer No.${
                                                                                    customer.indexOf(
                                                                                        data.user_id,
                                                                                    ) + 1
                                                                                }`}
                                                                      </td>
                                                                  ) : (
                                                                      <td>{data.namesec}</td>
                                                                  )}
                                                                  <td>
                                                                      {dateTimeFormatFunction(
                                                                          data.created_at,
                                                                      )}
                                                                  </td>
                                                                  <td>
                                                                      {data.formattedCurrency}
                                                                      {data.proposed_amount}
                                                                  </td>
                                                              </tr>
                                                          </>
                                                      ))
                                                    : null}
                                            </tbody>
                                        </table>
                                        <div className="d-flex justify-content-center align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
                                            {viewProduct.length == 0 ? (
                                                <div>{phrase.no_bids_found}</div>
                                            ) : null}
                                            {/* <h6>Showing of {get_allbidhistory.totalRecords}</h6>
                                            <Pagination
                                                count={Math.ceil(
                                                    get_allbidhistory.totalRecords / search.limit,
                                                )}
                                                page={search.page}
                                                onChange={onHandlePage}
                                                siblingCount={3}
                                                showFirstButton
                                                showLastButton
                                                boundaryCount={2}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default BidHistory
