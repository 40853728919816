import React, { useState, useEffect, useContext } from 'react'
import { Button, Dialog, IconButton, Popover } from '@material-ui/core'
import PrimaryButton from '../../atoms/PrimaryButton'
import SecondaryButton from '../../atoms/SecondaryButton'
import './Bidding.css'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Timer from '../../../common/timer'
import CommonContext from '../../../context/common/commonContext'
import UserContext from '../../../context/user/userContext'
import CustomInput from '../../atoms/Inputs/CustomInput'
import BiddingItem from './BiddingItem'
import Popup from '../../organisms/Popup'
import { currencyFormat, dateFormatFront, handleRedirectInternal } from '../../../common/components'
import { apiCall } from '../../../common/api'

import AlertContext from '../../../context/alert/alertContext'
import authContext from '../../../context/auth/authContext'
import TertiaryButton from '../../atoms/TertiaryButton'
import Login from '../../../views/Login'

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
        '& h4': {
            fontWeight: '700',
            color: '#666',
            textAlign: 'center',
        },
        '& p': {
            fontSize: '14px',
            color: '#666',
            textAlign: 'center',
        },
    },
    paper: {
        padding: theme.spacing(1),
        width: '500px',
        padding: '20px',
    },
}))

const BiddingCard = ({ phrase, product, setProduct, user }) => {
    const [bidValue, setBidValue] = useState(product.buyerPrice)
    const [anchorEl, setAnchorEl] = useState(null)
    const [bidPopup, setBidPopup] = useState(false)
    const [contactPopup, setContactPopup] = useState(false)
    const [makeOfferPopup, setMakeOfferPopup] = useState(false)
    const [makeOfferData, setMakeOfferData] = useState(null)
    const [makeOfferValue, setMakeOfferValue] = useState(0)
    const [makeOfferAmount, setMakeOfferAmount] = useState(null)
    const [errorMsg, setErrorMsg] = useState('')
    const [csvBidReq, setCsvBidReq] = useState(false)
    const classes = useStyles()
    const commonContext = useContext(CommonContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const { isAuthenticated } = useContext(authContext)
    const history = useHistory()
    const [logPopup, setLogPopup] = useState(false)
    const { setBidHistoryValue } = commonContext

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    /** Convert Bid Amount
     * @param {string} type valToFormat = Actual bid amount to formatted amount, formatToVal = Formatted amount to actual value
     */
    const convertBidAmt = (amt, type) => {
        if (type == 'valToFormat') {
            if (amt == '0') return ''
            return amt
                .toString()
                .replace(/,/g, '')
                .replace(/\D/g, '') // replace non-numeric char with empty string
                .replace(/[^\d].+/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else if (type == 'formatToVal') {
            if (amt == '') return 0
            return amt
                .toString()
                .replace(/,/g, '')
                .replace(/[^\d].+/, '')
        }
    }

    const [bidValDisplay, setBidValDisplay] = useState(
        convertBidAmt(product.buyerPrice, 'valToFormat'),
    )

    const bidInputChangeHandler = (value) => {
        setBidValDisplay(convertBidAmt(value, 'valToFormat'))
        setBidValue(convertBidAmt(value, 'formatToVal'))
    }

    const open = Boolean(anchorEl)
    let bidValid = false

    useEffect(() => {
        setBidValue(product.buyerPrice)
        setBidValDisplay(convertBidAmt(product.buyerPrice, 'valToFormat'))
    }, [product.buyerPrice])

    useEffect(() => {
        if (parseFloat(bidValue) >= parseFloat(product.next_bid)) {
            setErrorMsg('')
        }
    }, [bidValue, product])
    useEffect(() => {
        if (csvBidReq) {
            setCsvBidReq(!csvBidReq)
            popupOpen()
        }
    }, [csvBidReq])

    const getMakeOfferValue = (pid) => {
        setMakeOfferValue(pid)
    }
    const toggleLogOpen = () => {
        setLogPopup(!logPopup)
        if (window.location.hash.includes('#login')) {
            handleRedirectInternal(history, '')
        }
    }
    useEffect(async () => {
        if (makeOfferValue !== 0) {
            let data = {}
            data.pid = makeOfferValue

            const [res] = await Promise.all([apiCall('post', '', data, '', 'getAuctionOffers')])
            if (res.data.success) {
                setMakeOfferPopup(true)
                setMakeOfferData(res.data.data)
            } else {
                setAlert('Something wrong happend.. Please try again.', 'error')
            }
        }
    }, [makeOfferValue])

    // runs before opening bid confirmation popup
    const popupOpen = () => {
        if (new Date(product.date_added) > new Date()) {
            return setAlert('Wait until auction starts!', 'warning')
        }
        console.log('bid valid ? ', bidValid)
        // validate bid amount
        if (parseFloat(bidValue) < parseFloat(product.next_bid)) {
            console.log('BID Value: ', bidValue)
            console.log('NEXT BID: ', product.next_bid)
            if (product.auctiontype == 'live') {
                setErrorMsg(
                    `${phrase.parsley_greater_equal_message} ${product.currency_symbol} ${product.next_bid}`,
                )
            } else {
                // blind auction
                if (product.sprice == product.next_bid) {
                    setErrorMsg(
                        `${phrase.parsley_greater_equal_message} ${product.currency_symbol} ${product.next_bid}`,
                    )
                } else {
                    setErrorMsg(
                        `${phrase.next_bid_must_increase_by}  ${product.currency_symbol} ${product.blindIncrementVal}`,
                    )
                }
            }

            return
        }
        setErrorMsg('')
        setBidPopup(true)
    }
    const popupClose = () => {
        setBidPopup(false)
    }
    const contactPopupOpen = () => {
        setContactPopup(true)
    }
    const contactPopupClose = () => {
        setContactPopup(false)
    }
    const offerPopupClose = () => {
        setMakeOfferPopup(false)
        getMakeOfferValue(0)
    }
    const contactSeller = () => {
        let msg = document.getElementById('contactSellerMsg')
        if (msg.value != '') {
            contactPopupClose()
            let data = {}
            data.to_id = product.sellerid
            data.projectmsgpost = product.id
            data.message = msg.value
            data.from_id = user.id
            apiCall('post', 'savemessagefromview', data, '', 'messages')
                .then((res) => {
                    msg.value = ''
                    setAlert(phrase.message_successfully_sent, 'success')
                })
                .catch((err) => {
                    console.log(err)
                    setAlert('Something wrong happend.. Please try again.', 'error')
                })
        }
    }

    const makeOffer = async (pid) => {
        if (makeOfferAmount && Number(makeOfferAmount) != 0) {
            let data = {}
            data.pid = pid
            data.offer_amount = Number(makeOfferAmount)

            const [res] = await Promise.all([apiCall('post', '', data, '', 'makeOffer')])
            if (res.data.success) {
                setAlert('Offer submitted successfully', 'success')
                offerPopupClose()
            } else {
                setAlert('Something wrong happend.. Please try again.', 'error')
            }
        }
    }

    const anotherOffer = () => {
        setMakeOfferData(null)
        setMakeOfferAmount('')
    }

    const acceptCounterOffer = async (oid) => {
        handleRedirectInternal(history, `product/offer/${product.id}/${oid}`)
    }

    const declineCounterOffer = async (oid) => {
        let data = {}
        data.oid = oid

        const [res] = await Promise.all([apiCall('post', '', data, '', 'declineCounterOffer')])
        if (res.data.success) {
            setAlert('Counter offer declined successfully', 'success')
            offerPopupClose()
        } else {
            setAlert(res.data.error || 'Something wrong happend.. Please try again.', 'error')
        }
    }

    const openCsvBidFile = () => {
        document.getElementById('bid_csv').click()
    }
    const uploadCsvBidFile = (e) => {
        console.log('UPLOAD FILE EVENT: ', e)
        var formData = new FormData()
        var bidFile = document.querySelector('#bid_csv')
        if (bidFile.files.length > 0) {
            formData.append('bid_csv', bidFile.files[0])
            apiCall('post', 'upload-bid-csv', formData, 'formdata', 'product')
                .then((res) => {
                    console.log(res)
                    if (res.data.msgdata == 'success') {
                        console.log('SUCCESS')
                        let productCopy = { ...product }
                        productCopy.csvfile = res.data.csvfile
                        setProduct(productCopy)
                        setBidValue(parseFloat(res.data.bidamount))
                        setBidValDisplay(
                            convertBidAmt(parseFloat(res.data.bidamount), 'valToFormat'),
                        )
                        setCsvBidReq(true)
                    }
                })
                .catch((err) => {
                    console.log('ERROR: ', err)
                    setAlert('Something wrong happend.. Please try again.', 'error')
                })
        }
    }

    const redirectToBuynow = () => {
        handleRedirectInternal(history, `checkout?buynow_id=${product.id}`)
    }

    // top part consists of current bid & timer
    let topPart = []
    let viewHistory = null
    let enterOrMoreMsg = null
    let reserveMetMessage = null
    let submitBtn = []

    const [isBuyer, setIsBuyer] = useState(true)
    // useEffect(() => {
    //     if (user) {
    //         // if user is admin, or user is seller
    //         // is_emp = 1, is seller
    //         if (user.id && (user.admin != '0' || user.is_emp == '1')) {
    //             console.log('IS BUYER:: false')
    //             setIsBuyer(false)
    //         }
    //     }
    // }, [user])

    // buynow btn
    if (
        product.market_status == 'open' &&
        product.buynow === 1 &&
        product.wprice < product.bprice
    ) {
        let label = phrase.buy_it_now + ' ' + product.currency_symbol + product.bprice
        submitBtn.push(
            <div className="col">
                <TertiaryButton
                    btnSize="large"
                    label={label}
                    onClick={redirectToBuynow}
                    disabled={!isBuyer ? true : false}
                />
            </div>,
        )
    }

    // offer btn
    if (
        product.market_status == 'open' &&
        product.makeoffer === 1 &&
        new Date() < new Date(product.date_closed)
    ) {
        let label = 'Make Offer'
        submitBtn.push(
            <div className="col">
                <PrimaryButton
                    btnSize="large"
                    label={label}
                    onClick={() => getMakeOfferValue(product.id)}
                    disabled={!isBuyer ? true : false}
                />
            </div>,
        )
    }

    let bidInput =
        product.market_status == 'open' ? (
            <input
                type="text"
                value={bidValDisplay}
                onChange={(e) => bidInputChangeHandler(e.target.value)}
            />
        ) : (
            <input type="number" min="0" value={bidValue} disabled />
        )

    // current bid can only be shown if the auction type is live
    if (product.auctiontype == 'live') {
        submitBtn.push(
            <div className="col">
                <PrimaryButton
                    btnSize="large"
                    label={phrase.submit_bid}
                    onClick={popupOpen}
                    handleClose={popupClose}
                    disabled={!isBuyer ? true : false}
                />
            </div>,
        )
        topPart.push(
            <div key="currentBid" className="bidTimeCntLt" style={{ width: '35%' }}>
                <label> {product.bid_count ? phrase.current_bid : phrase.starting_price} </label>
                <h5>
                    {product.currency_symbol}
                    {product.wprice} &nbsp;
                    {product.currency}
                </h5>
            </div>,
        )
        viewHistory = isAuthenticated ? (
            <Button className="viewBids" onClick={() => setBidHistoryValue(product.id)}>
                {' '}
                {phrase.view_all_bids}({product.bid_count})
            </Button>
        ) : (
            <p>Bids ({product.bid_count})</p>
        )
        if (product.market_status == 'open') {
            enterOrMoreMsg = (
                <>
                    {' '}
                    {phrase.enter} {product.currency} {product.currency_symbol}
                    {parseInt(product.next_bid)} {phrase.or_more}
                </>
            )
        }
    }
    // blind auction
    else {
        if (product.market_status == 'open') {
            // CSV Bid
            if (!product.isNotCsvBid) {
                submitBtn.push(
                    <div className="col">
                        <PrimaryButton
                            btnSize="large"
                            label={phrase.upload}
                            onClick={openCsvBidFile}
                            handleClose={popupClose}
                            disabled={!isBuyer ? true : false}
                        />
                    </div>,
                )
                bidInput = (
                    <>
                        <input type="text" value={bidValDisplay} disabled />
                        <input
                            type="file"
                            id="bid_csv"
                            accept=".csv,.xls,.xlsx"
                            onChange={(e) => uploadCsvBidFile(e)}
                            hidden
                        />
                    </>
                )
            } else {
                submitBtn.push(
                    <div className="col">
                        <PrimaryButton
                            btnSize="large"
                            label={phrase.submit_bid}
                            onClick={popupOpen}
                            handleClose={popupClose}
                            disabled={!isBuyer ? true : false}
                        />
                    </div>,
                )
            }
        }
    }
    topPart.push(
        <div key="bidTimer" className="bidTimeCntRt" style={{ width: '65%' }}>
            {/* <label> {phrase.time_left} </label> */}
            <div className="primeColor">
                <Timer
                    date_added={product.date_added}
                    date_closed={product.date_closed}
                    withText={1}
                ></Timer>
                <h6>{dateFormatFront(product.date_closed)}</h6>
            </div>
        </div>,
    )

    // Reserve Price Met? message
    if (product.userHasBid == 1) {
        if (parseFloat(product.wprice) >= parseFloat(product.rprice)) {
            reserveMetMessage = <span className="primeColor">{phrase.reserve_price_is_met}</span>
        } else {
            reserveMetMessage = <span className="red">{phrase.reserve_price_is_not_met}</span>
        }
    }

    return (
        <>
            <div className="biddingCard">
                <h3> {phrase.auction_details} </h3>
                {product.inventorytype == 1 ? (
                    <div className="tpTag">
                        <div className="tpTagInner">
                            <span>Approved</span>
                            <h6>
                                <span>3rd</span> Party Seller
                            </h6>
                        </div>
                    </div>
                ) : null}
                {/* bid status / award status message */}
                {product.market_status == 'open' ? (
                    product.auctiontype == 'live' ? (
                        <div
                            className="bidMessage"
                            dangerouslySetInnerHTML={{ __html: product.bidMessage }}
                        ></div>
                    ) : /* Blind Auction - show message only if the bidder is not the highest 
                & after the reserve price is met    parseInt(product.buyerPrice) < parseInt(product.wprice) && */

                    parseInt(product.wprice) >= parseInt(product.rprice) ? (
                        <div
                            className="bidMessage"
                            dangerouslySetInnerHTML={{ __html: product.bidMessage }}
                        ></div>
                    ) : null
                ) : (
                    <div
                        className="bidMessage"
                        dangerouslySetInnerHTML={{ __html: product.bidMessage }}
                    ></div>
                )}

                <div className="bidTimeCnt">
                    <div className=" d-flex justify-content-center align-items-start">
                        {topPart}
                    </div>
                    {product.market_status === 'sold' &&
                    product.bidMessage?.toLowerCase()?.includes('congratulations') ? (
                        <div className="row">
                            <div className="col checkoutBtn">
                                <TertiaryButton
                                    btnSize="large"
                                    label={'Go to checkout'}
                                    onClick={() => history.push('/dashboard/won')}
                                />
                            </div>
                        </div>
                    ) : null}
                    {viewHistory}
                </div>
                {isAuthenticated ? (
                    product.market_status === 'open' && (
                        <>
                            <div className="bidInputCnt">
                                <Button
                                    onClick={() => {
                                        if (bidValue > 0) {
                                            setBidValDisplay(
                                                convertBidAmt(
                                                    parseInt(bidValue) - 1,
                                                    'valToFormat',
                                                ),
                                            )
                                            setBidValue(
                                                convertBidAmt(
                                                    parseInt(bidValue) - 1,
                                                    'formatToVal',
                                                ),
                                            )
                                        }
                                    }}
                                >
                                    <span className="material-icons">remove</span>
                                </Button>
                                <div className="bcBidInput">
                                    <label> {phrase.your_bid} </label>
                                    {bidInput}
                                </div>
                                <Button
                                    onClick={() => {
                                        setBidValDisplay(
                                            convertBidAmt(parseInt(bidValue) + 1, 'valToFormat'),
                                        )
                                        setBidValue(
                                            convertBidAmt(parseInt(bidValue) + 1, 'formatToVal'),
                                        )
                                    }}
                                >
                                    <span className="material-icons">add</span>
                                </Button>
                            </div>
                            <h6 className="nextBidValue">
                                {enterOrMoreMsg}
                                <div>
                                    {reserveMetMessage}
                                    {errorMsg != '' ? (
                                        <>
                                            <br />
                                            <span className="red">{errorMsg}</span>
                                        </>
                                    ) : null}
                                </div>
                            </h6>
                            <div className="mt-3 mb-2">
                                <div className="row bcActBtn flex-wrap">
                                    <div className="col">
                                        <Button
                                            className="contactSeller"
                                            onClick={contactPopupOpen}
                                            disabled={isBuyer ? false : true}
                                        >
                                            <span className="material-icons">chat</span>
                                            <span> {phrase.contact_seller} </span>
                                        </Button>
                                    </div>
                                    {submitBtn.map((val, index) =>
                                        index === 0 && product.buy_now == 1
                                            ? product.wprice > product.bprice
                                                ? null
                                                : val
                                            : val,
                                    )}
                                </div>
                            </div>
                            <div>
                                <Button
                                    variant="outlined"
                                    className="autoBid"
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    {phrase.autobidcaps}{' '}
                                    <span className="material-icons"> info </span>
                                </Button>
                                <Popover
                                    id="mouse-over-popover"
                                    className={classes.popover}
                                    classes={{
                                        paper: classes.paper,
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <h4> {phrase.autobid_msg_line1.replace(/<br>/g, '')} </h4>
                                    <p> {phrase.autobid_msg_line2_outer} </p>
                                </Popover>
                            </div>
                        </>
                    )
                ) : (
                    <PrimaryButton
                        btnSize="large"
                        label={'Login To Bid'}
                        onClick={() => setLogPopup(true)}
                    />
                )}
                {/* // Bid confirmation popup */}
                <Popup
                    open={bidPopup}
                    data={product}
                    modaltitle={phrase.bid_confirmation}
                    handleClose={popupClose}
                    footer={
                        <BiddingItem
                            wsprice={bidValue}
                            product={product}
                            phrases={phrase}
                            handleClose={popupClose}
                        ></BiddingItem>
                    }
                >
                    <p>
                        {phrase.you_are_placing_a_bid_of} {product.currency_symbol} {bidValDisplay}
                        {phrase.to_confirm_this_bid_click_below}
                    </p>
                    <div>
                        <h4>{phrase.autobid_msg_line1.replace(/<br>/g, '')}</h4>
                        <p>{phrase.autobid_msg_line2_outer}</p>
                    </div>
                </Popup>
                {/* Contact Seller Popup */}
                <Popup
                    open={contactPopup}
                    handleClose={contactPopupClose}
                    modaltitle={phrase.send_message}
                    footer={
                        <>
                            <PrimaryButton label={phrase.send} onClick={contactSeller} />
                            <SecondaryButton label={phrase.cancel} onClick={contactPopupClose} />
                        </>
                    }
                >
                    <div className="row text-left">
                        <div className="col-md-2 col-3">
                            <label>{phrase.title}</label>
                        </div>
                        <div className="col-md-8">
                            <label>{product.title}</label>
                        </div>
                    </div>
                    <div className="row text-left">
                        <div className="col-md-2 col-3">
                            <label>{phrase.message}</label>
                        </div>
                        <div className="col-md-8">
                            <textarea
                                className="form-control"
                                id="contactSellerMsg"
                                rows="4"
                                placeholder={phrase.enter_your_message}
                            ></textarea>
                        </div>
                    </div>
                </Popup>
                {/* Make Offer Popup */}
                <Popup
                    open={makeOfferPopup}
                    data={product}
                    modaltitle={'Make Offer'}
                    handleClose={offerPopupClose}
                    footer={
                        <>
                            {makeOfferData ? (
                                makeOfferData.accepted_by > 0 ? (
                                    <>
                                        <label>
                                            <b>Offer Approved</b>
                                        </label>
                                        <br></br>
                                    </>
                                ) : makeOfferData.declined_by > 0 ? (
                                    <>
                                        <label>
                                            <b>Offer Declined</b>
                                        </label>
                                        <br></br>
                                        <PrimaryButton
                                            label={'Make Another Offer'}
                                            onClick={(e) => anotherOffer()}
                                        />
                                    </>
                                ) : makeOfferData.counter_by > 0 ? (
                                    <>
                                        <PrimaryButton
                                            label={'Accept Counter Offer'}
                                            onClick={(e) => acceptCounterOffer(makeOfferData.id)}
                                        />
                                        <PrimaryButton
                                            label={'Decline Counter Offer'}
                                            onClick={(e) => declineCounterOffer(makeOfferData.id)}
                                        />
                                    </>
                                ) : makeOfferData.counter_by == 0 ? (
                                    <>
                                        <label>Offer submitted, awaiting Admin review.</label>
                                        <br></br>
                                    </>
                                ) : null
                            ) : (
                                <PrimaryButton
                                    label={'Make Offer'}
                                    onClick={(e) => makeOffer(product.id)}
                                />
                            )}
                            {/* <SecondaryButton label={phrase.cancel} onClick={offerPopupClose} /> */}
                        </>
                    }
                >
                    <div className="row text-left">
                        <div className="col-2">
                            <label>Your Offer</label>
                        </div>
                        <div className="col-8">
                            <CustomInput
                                value={makeOfferData ? makeOfferData.offer_amount : makeOfferAmount}
                                disabled={makeOfferData ? true : false}
                                onChange={(e) =>
                                    setMakeOfferAmount(e.target.value.replace(/\D/g, ''))
                                }
                                type={'text'}
                            />
                        </div>
                    </div>
                    <div className="row text-left">
                        <div className="col-2">
                            <label>Counter Offer</label>
                        </div>
                        <div className="col-8">
                            <label>
                                {makeOfferData
                                    ? makeOfferData.counter_amount
                                        ? makeOfferData.counter_amount
                                        : '-'
                                    : '-'}
                            </label>
                        </div>
                    </div>
                </Popup>
            </div>
            <Dialog
                open={logPopup}
                function={toggleLogOpen}
                // maxWidth={'md'}
                className="lgnWrpr"
            >
                <div className="lgnCntnr">
                    <IconButton className="popClrBtn" onClick={toggleLogOpen}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                    <Login
                        autoComplete={1}
                        closeLogin={toggleLogOpen}
                        // openRegister={toggleRegOpen}
                        onMenuClose={() => setAnchorMenu(null)}
                    />
                </div>
            </Dialog>
        </>
    )
}

export default BiddingCard
