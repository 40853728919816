import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import openSocket from 'socket.io-client'

// Components
import PrimaryButton from '../../components/atoms/PrimaryButton'
import Invoice from '../../components/molecules/Invoice'

// Context
import CommonContext from '../../context/common/commonContext'
import AlertContext from '../../context/alert/alertContext'
import AuthContext from '../../context/auth/authContext'

// Common Function
import { apiCall } from '../../common/api'
import { handleRedirectInternal } from '../../common/components'

const OfferConfirm = (props) => {
    const history = useHistory()
    const { phrase } = useContext(CommonContext)
    const { setAlert } = useContext(AlertContext)
    const { user } = useContext(AuthContext)

    // state
    const [invoiceData, setInvoiceData] = useState({})
    const [shipping, setShippingData] = useState({})
    const invoiceDataRef = useRef(invoiceData)
    const userRef = useRef(user)

    const socketHandler = (data) => {
        // console.log('Socket Handler invoiceDataRef: ', invoiceDataRef.current)
        // console.log('Socket Handler UserRef: ', userRef.current)
        if (data.id == invoiceDataRef.current.id) {
            if (data.bpop_cbidder == userRef.current.id) {
                if (data.status == 'success') {
                    setAlert(phrase.order_completed, 'success')
                    handleRedirectInternal(history, `invoice/${data.invoiceid}`)
                } else {
                    setAlert(data.error, 'error')
                }
            } else {
                setAlert(phrase.this_item_is_out_of_stock, 'error')
            }
        }
    }
    useEffect(() => {
        if (props.match.params.id && props.match.params.oid) {
            console.log('params id: ', props.match.params.id)
            getInvoiceData(props.match.params.id, props.match.params.oid)
        }
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        socket.on('buynowAddtime', (data) => {
            console.log('[BUYNOW SOCKET] ', data)
            socketHandler(data)
        })

        return () => {
            socket.off('buynowAddtime', (data) => {
                socketHandler(data)
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        invoiceDataRef.current = invoiceData
        userRef.current = user
    })

    const calculateShipping = async (body) => {
        const res = await apiCall('post', ``, body, '', 'getShippingAmount')
        if (res.data.status) {
            return parseFloat(res.data.shipping_amount)
        } else {
            return 0
        }
    }
    // Get Invoice Data
    const getInvoiceData = (id, oid) => {
        apiCall('get', `viewsample/${id}/${oid}`, '', '', 'product')
            .then(async (results) => {
                let { data } = results
                if (data.status == 'success') {
                    // convert bprice to amount
                    data.data.invoicedata.amount = data.data.invoicedata.counter_amount
                    data.data.invoicedata.paid =
                        data.data.invoicedata.paid !== undefined ? data.data.invoicedata.paid : 0
                    var temp_shipping = {}
                    if (data.data.shipping?.length > 0) {
                        temp_shipping = data.data.shipping[0]
                    }
                    setShippingData(temp_shipping)
                    data.data.invoicedata.lineitem = data.data.invoicelineitem
                    // if (data.data.invoicedata.paid == 0)
                    data.data.invoicedata.shipping_amount = await calculateShipping({
                        product_id:
                            data.data.invoicedata.lineproject_id > 0
                                ? data.data.invoicedata.lineproject_id
                                : data.data.invoicedata.id,
                        city: temp_shipping?.city,
                        zipcode: temp_shipping?.zipcode,
                        country: temp_shipping?.country,
                    })
                    data.data.invoicedata.total =
                        data.data.invoicedata.amount +
                        data.data.invoicedata.buyers_premium +
                        parseFloat(data.data.invoicedata.sales_tax) +
                        data.data.invoicedata.shipping_amount
                    setInvoiceData(data.data.invoicedata)
                } else {
                    handleRedirectInternal(history, 'search')
                }
            })
            .catch((err) => {
                handleRedirectInternal(history, 'search')
            })
    }

    // Confirm Order
    const confirmOrder = () => {
        let data = {
            id: invoiceData.id,
            buyprice: invoiceData.counter_amount,
            oid: invoiceData.oid,
        }
        apiCall('post', 'acceptCounterOffer', data, '', 'product')
            .then((response) => {
                return
            })
            .catch((err) => {
                console.log(err)
                setAlert(phrase.something_went_wrong, 'error')
            })
    }

    return (
        <>
            {Object.keys(invoiceData).length > 0 ? (
                <>
                    <Invoice data={invoiceData} buynowConfirm={true} shippingData={shipping} />
                    <div
                        className=" d-flex justify-content-center align-items-start mb-5"
                        style={{ backgroundColor: 'white', padding: '10px' }}
                    >
                        <div className="mr-1">
                            <PrimaryButton label={phrase.cancel} onClick={() => history.goBack()} />
                        </div>

                        <div>
                            <PrimaryButton
                                onClick={() => confirmOrder()}
                                label={phrase.confirm_your_order}
                            />
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default OfferConfirm
