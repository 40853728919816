import React, { useContext, useState, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import { Accordion, AccordionDetails, AccordionSummary, Button, MenuItem } from '@material-ui/core'
import { apiCall } from '../../common/api'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import BiddingCard from '../../components/molecules/Bidding/BiddingCard'
import './ProductView.css'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router-dom'
import { handleRedirectInternal, currencyFormat, dateFormatFront } from '../../common/components'
import { bidSocketHandler, messageHandler } from '../Common/socketHandler'
import FavoriteCheckbox from '../../components/atoms/FavoriteCheckbox'
import CurrencyConverter from './CurrencyConverter'
import Popup from '../../components/organisms/Popup'
import { PhotoSwipeGallery } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'
import ReactHtmlParser from 'react-html-parser'
// context
import CommonContext from '../../context/common/commonContext'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import ReadMore from '../../components/atoms/ReadMore'
import BidHistory from '../../components/organisms/BidHistory'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'

function TabPanel(props) {
    const { children, value, index, html, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {html ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: html,
                            }}
                        ></span>
                    ) : (
                        <span>{children}</span>
                    )}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}))

// includes both live / blind auction
const SliderAuction = ({
    product,
    images,
    downloadFile,
    setProduct,
    getProductView,
    hideBidding,
    slider,
}) => {
    const authContext = useContext(AuthContext)
    const classes = useStyles()
    const alertContext = useContext(AlertContext)
    const { phrase } = useContext(CommonContext)
    const { setAlert } = alertContext
    const [value, setValue] = React.useState(0)
    const { user, responseStatus, clearResponse, saveSearch, isAuthenticated } = authContext
    const [productDescPopup, setProductDescPopup] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [pageLimit, setPageLimit] = useState(10)
    let history = useHistory()
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const userRef = useRef(user)
    const productRef = useRef(product)
    useEffect(() => {
        userRef.current = user
        productRef.current = product
    })
    const socketHandler = (data, type) => {
        bidSocketHandler(
            data,
            type,
            userRef.current,
            productRef.current,
            phrase,
            setProduct,
            setAlert,
        )
    }
    const changePageLimit = (e) => {
        // setLineItemsReady(false)
        setPageLimit(e.target.value)
        // setPageNum(1)
    }
    const cancelBidHandler = (data) => {
        if (data.project_id == productRef.current.id) {
            getProductView(productRef.current.id)
        }
    }

    const productDescClose = () => {
        setProductDescPopup(false)
    }

    let options = {}

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        socket.on('realclosedupdates', (data) => {
            console.log('[PRODUCT CLOSED SOCKET] ', data)
            socketHandler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            socketHandler(data, 'bidAddtime')
        })
        socket.on('drop_price', (data) => {
            socketHandler(data, 'drop_price')
        })
        // Bid Cancellation Event => Reload the product info
        socket.on('cancelbidemitted', function (data) {
            cancelBidHandler(data)
        })
        socket.on('reservepriceupdate', function (data) {
            socketHandler(data, 'reservepriceupdate')
        })
        socket.on('bid_extended', function (data) {
            socketHandler(data, 'bid_extended')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                socketHandler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                socketHandler(data, 'bidAddtime')
            })
            socket.off('drop_price', (data) => {
                socketHandler(data, 'drop_price')
            })
            socket.off('cancelbidemitted', function (data) {
                cancelBidHandler(data)
            })
            socket.off('reservepriceupdate', function (data) {
                socketHandler(data, 'reservepriceupdate')
            })
            socket.off('bid_extended', function (data) {
                socketHandler(data, 'bid_extended')
            })
            socket.disconnect()
        }
    }, [])

    let categories = ''
    if (product) {
        for (let i in product.multiplecategoryshow) {
            // last index
            if (i == product.multiplecategoryshow.length - 1) {
                categories += phrase[product.multiplecategoryshow[i]]
            } else {
                categories += phrase[product.multiplecategoryshow[i]] + ', '
            }
        }
    }
    const redirectFile = (event) => {
        if (event.target.alt !== 'img') {
            window.location.href = `${global.site_url}/uploads/product/${event.target.title}`
        } else {
            event.preventDefault()
            setOpen(true)
        }
    }

    return (
        <>
            <div>
                <div>
                    <ImageGallery
                        items={images}
                        thumbnailPosition="bottom"
                        showNav={false}
                        showBullets={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        autoPlay={true}
                        onClick={redirectFile}
                    />
                    <PhotoSwipeGallery
                        items={images}
                        isOpen={isOpen}
                        onClose={() => setOpen(false)}
                        options={options}
                    />
                </div>
                <h1 className="price my-4">${product?.wprice}</h1>
                {isAuthenticated ? null : (
                    <SecondaryButton
                        label="Login To Bid"
                        btnSize="small"
                        onClick={() => handleRedirectInternal(history, '/login')}
                    />
                )}
                <div className="description my-4">
                    <Accordion>
                        <AccordionSummary>Product Description</AccordionSummary>
                        <AccordionDetails>
                            <div className="lotDataSort mt-4 d-flex justify-content-between align-items-center flex-wrap">
                                <h6 className="lotCount"></h6>
                                <CustomSelect
                                    size="small"
                                    label="Show Results"
                                    onChange={changePageLimit}
                                    value={pageLimit}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </CustomSelect>
                            </div>
                            <div className="table-responsive">
                                <table className="table  laTable table-striped table-responsive">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">{phrase.sublot_id}</th>
                                            <th scope="col">{phrase.brand}</th>
                                            <th scope="col">{phrase.product_name}</th>
                                            <th scope="col">{phrase.category}</th>
                                            <th scope="col">{phrase.qty}</th>
                                            <th scope="col">{phrase.current_bid}</th>
                                            <th scope="col">{phrase.action}</th>
                                            <th scope="col">{phrase.status}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="mtInner"></tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-center mb-5"></div>
                            {isAuthenticated ? (
                                <div style={{ textAlign: 'center' }}>
                                    <PrimaryButton width="40%">
                                        <span
                                            className="material-icons"
                                            style={{ marginRight: '3px' }}
                                        >
                                            cloud_download
                                        </span>
                                    </PrimaryButton>
                                </div>
                            ) : null}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>Condition</AccordionSummary>
                        <AccordionDetails>
                            {ReactHtmlParser(product?.desc_condition)}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>FAQ</AccordionSummary>
                        <AccordionDetails>
                            {ReactHtmlParser(product?.desc_condition)}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>Shipping</AccordionSummary>
                        <AccordionDetails>
                            {product.buyership == 1
                                ? phrase.productview_1
                                : product.shipping_service}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary>Payment Note</AccordionSummary>
                        <AccordionDetails>{phrase.payment_note_details}</AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </>
    )
}

export default SliderAuction
