import React, { useEffect, useContext, useState } from 'react'
import CommonContext from '../../context/common/commonContext'
import LoaderContext from '../../context/loaders/loader.context'
import aboutImg from '../../assets/images/about-us.jpg'
import './StaticPages.css'
import Loader from '../../components/molecules/Loader'

const StaticPage = (props) => {
    let [pageData, setPageData] = useState('')
    let [pageTitle, setPageTitle] = useState('')
    let { getStaticPage, nonloginToken, staticPages, language, getLangPhrase, phrase } =
        useContext(CommonContext)
    let { setStaticPageload, isStaticPageLoad } = useContext(LoaderContext)
    useEffect(async () => {
        let { page } = props
        let payload = {
            page,
            nonloginToken,
        }
        setStaticPageload(true)
        getStaticPage(payload)
        getLangPhrase(language)
    }, [props.page, language])
    useEffect(() => {
        setStaticPageload(false)
        setPageData(staticPages)
        staticPageTitle(phrase)
    }, [staticPages, localStorage.getItem('language')])

    const staticPageTitle = (phrase) => {
        switch (props.page) {
            case 'about':
                setPageTitle(phrase.about)
                document.title = global.site_title + ' | About'
                break
            case 'how_to_bid':
                setPageTitle(phrase.how_to_bid)
                document.title = global.site_title + ' | How To Bid'
                break
            case 'privacy':
                setPageTitle(phrase.privacy_policy)
                document.title = global.site_title + ' | Privacy'
                break
            case 'privacy_shield':
                setPageTitle(phrase.privacy_shield)
                document.title = global.site_title + ' | Privacy Shield'
                break
            case 'terms':
                setPageTitle(phrase.terms_conditions)
                document.title = global.site_title + ' | Terms and Conditions'
                break
            case 'grading':
                setPageTitle(phrase.product_grading)
                document.title = global.site_title + ' | Grading'
                break
            case 'FAQ':
                setPageTitle(phrase.faq)
                document.title = global.site_title + ' | FAQs'
            case 'how_to':
                setPageTitle(phrase.how_to)
                document.title = global.site_title + ' | How To'
                break
            default:
                break
        }
    }

    const checkLanguage = (data) => {
        switch (language) {
            case 'English':
                return data.for_english
            case 'French':
                return data.for_french
            case 'German':
                return data.for_german
            case 'Mandarin':
                return data.for_mandarin
            case 'Portugese':
                return data.for_portugese
            case 'Spanish':
                return data.for_spanish
            default:
                return data.for_english
        }
    }

    return (
        <>
            {pageData !== '' ? (
                <>
                    {/* {typeof pageData !== 'object' && pageData !== null ? ( */}
                    <div>
                        {/* {console.log(pageData, 'pageData')}
                        {pageTitle != phrase.faq ? (
                            <div className="pp-banner">
                                <div className="pp-label">{pageTitle}</div>
                            </div>
                        ) : (
                            ''
                        )} */}

                        <div className="staticPageContent">
                            {pageData.content ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: checkLanguage(pageData),
                                    }}
                                ></div>
                            ) : (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: checkLanguage(pageData),
                                    }}
                                ></div>
                            )}
                        </div>
                    </div>
                    {/* ) : null} */}
                </>
            ) : isStaticPageLoad ? (
                <Loader />
            ) : (
                'No data'
            )}
        </>
    )
}

export default StaticPage
