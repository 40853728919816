import React, { useContext, useEffect, useState } from 'react'
// import Layout from '../Layout'
import './Static.css'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { handleRedirectInternal } from '../../common/components'
import UserContext from '../../context/user/userContext'
const Static = (props) => {
    const userContext = useContext(UserContext)
    const { emailverification, responseStatus } = userContext
    const [loading, setLoading] = useState(true)
    const location = useLocation()
    const history = useHistory()
    const urlString = location.search.split('=')
    const { id } = useParams()
    const getFunction = () => {
        setLoading(true)
        emailverification(id)
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from == 'user_emailverification') {
                if (responseStatus?.status == 'success') {
                    setLoading(false)
                    handleRedirectInternal(history, '')
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        getFunction()
    }, [])

    return (
        // <Layout>
        <div className="stcPgWrpr">
            {loading ? (
                <h1 style={{ textAlign: 'center' }}>
                    Your Verification Are Inprogress.Please Wait....
                </h1>
            ) : (
                <h1 style={{ textAlign: 'center' }}>
                    Your Email Verification Successfully Completed.
                </h1>
            )}
        </div>
        // </Layout>
    )
}

export default Static
