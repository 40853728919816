import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import './PriceLock.css'
import { useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../common/components'
import SearchNav from '../../components/molecules/SearchNav'
import CommonContext from '../../context/common/commonContext'
import ProductContext from '../../context/product/productContext'
import { apiCall } from '../../common/api'

function PriceLock() {
    const { phrase, language } = useContext(CommonContext)
    const { selectedCategories, dispatchSelectedCategories } = useContext(ProductContext)
    const history = useHistory()
    const [content, setContent] = useState('')
    useEffect(() => {
        apiCall('get', '', '', '', 'price_lock').then((results) => {
            if (results.data.status == 'success') {
                switch (language) {
                    case 'English':
                        setContent(results.data.data.content.for_english)
                        break
                    case 'Mandarin':
                        setContent(results.data.data.content.for_mandarin)
                        break
                    case 'French':
                        setContent(results.data.data.content.for_french)
                        break
                    case 'Spanish':
                        setContent(results.data.data.content.for_spanish)
                        break
                    case 'Portugese':
                        setContent(results.data.data.content.for_portugese)
                        break
                    case 'BrazilPortugese':
                        setContent(results.data.data.content.for_brazilportugese)
                        break
                    case 'German':
                        setContent(results.data.data.content.for_german)
                        break
                }
            }
        })
    }, [language])

    const showPriceLockAuction = () => {
        let selcat = []
        selcat.push({
            id: 'auctiontype4',
            name: 'pricelock',
            type: 'listing',
            filterBy: 'pricelock',
        })
        dispatchSelectedCategories(selcat)
        history.push('/search')
    }

    return (
        <>
            {/* <SearchNav /> */}
            <div
                className="priceLock container-fluid"
                style={{ backgroundColor: 'var(--secColor)' }}
            >
                <div className="plInfo">
                    <div className="plInfoLt"></div>
                    <div className="plInfoRt">
                        <div dangerouslySetInnerHTML={{ __html: content }}></div>
                        <a className="container" onClick={() => showPriceLockAuction()}>
                            {phrase.click_here_pricelock}
                        </a>
                    </div>
                </div>
                <div className="plAction d-flex flex-wrap justify-content-around align-items-center">
                    <img src="./assets/images/home-2.png" alt="Price Lock" />
                    <PrimaryButton
                        label={phrase.price_lock_auction}
                        onClick={() => showPriceLockAuction()}
                    />
                </div>
            </div>
        </>
    )
}

export default PriceLock
