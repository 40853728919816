import * as Yup from 'yup'
import { apiCall } from '../../common/api'

export const step1InitialValues = () => {
    return {
        first_name: '',
        last_name: '',
        company_name: '',
        company_id: '',
        parent_company: '',
        business_type: '',
        username: '',
        language: '',
        phone: '',
        // landline: '',
        email: '',
        confirm_email: '',
        password: '',
        password1: '',
        city: '',
        counntry: '',
        state: '',
        zip_code: '',
        street_address: '',
        vat_number: '',
        ein_number: '',
    }
}

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const step1ValidationSchema = (phrase) => {
    return Yup.object({
        // first_name: Yup.string()
        //     .required(`${phrase.enter} ${phrase.first_name}`)
        //     .max(20, 'Maximum 20 characters')
        //     .matches(/^[a-zA-Z ]*$/g, `${phrase.invalid_format}`),
        // last_name: Yup.string()
        //     .required(`${phrase.enter} ${phrase.last_name}`)
        //     .max(20, 'Maximum 20 characters')
        //     .matches(/^[a-zA-Z ]*$/g, `${phrase.invalid_format}`),
        // company_name: Yup.string().required(`${phrase.enter} ${phrase.company_name}`),
        // company_id: Yup.string().required(`${phrase.this_is_required}`),
        // business_type: Yup.string().required(`${phrase.enter} ${phrase.type_of_business}`),
        // username: Yup.string()
        //     .required(`${phrase.enter} ${phrase.username}`)
        //     .matches(/^[a-zA-Z ]*$/g, `${phrase.invalid_format}`)
        //     .max(20, 'Maximum 20 characters'),
        // language: Yup.string().required(`${phrase.select} ${phrase.language}`),
        // phone: Yup.string().required(`${phrase.enter} ${phrase.phone_number}`),
        //.matches(phoneRegExp, phrase.invalid_number),
        // landline: Yup.string().required(phrase.invalid_number),
        //Yup.string().matches(phoneRegExp, phrase.invalid_number),
        email: Yup.string().required(`${phrase.enter} ${phrase.email}`),
        // confirm_email: Yup.string()
        //     .required(`${phrase.confirm} ${phrase.email}`)
        //     .oneOf([Yup.ref('email'), null], phrase.this_value_should_be_the_same),
        password: Yup.string()
            .min(8, phrase.min8_char)
            .required(`${phrase.enter} ${phrase.password}`),
        password1: Yup.string()
            .required(`${phrase.confirm} ${phrase.password}`)
            .oneOf([Yup.ref('password'), null], phrase.this_value_should_be_the_same),
        // city: Yup.string().required(`${phrase.enter} ${phrase.city}`),
        // counntry: Yup.string().required(`${phrase.enter} ${phrase.country}`),
        // state: Yup.string()
        //     .test('checkUndefined', `${phrase.enter} ${phrase.state}`, (value) => {
        //         console.log('state validation::: ', value)
        //         if (typeof value === 'undefined') {
        //             console.log('UNDEFINED')
        //             return false
        //         }
        //         return true
        //     })
        //     .required(`${phrase.enter} ${phrase.state}`),
        // zip_code: Yup.string()
        //     // .matches(/^[a-zA-Z0-9 ]*$/g, `${phrase.invalid_format} ${phrase.zip}`)
        //     .min(5, `${phrase.invalid_format} ${phrase.zip}`)
        //     .max(8, `${phrase.invalid_format} ${phrase.zip}`)
        //     .test('checkMin', `${phrase.invalid_format} ${phrase.zip}`, (value) => {
        //         let zip = parseInt(value)
        //         if (zip < 0) return false
        //         return true
        //     })
        //     .required(`${phrase.enter} ${phrase.zip}`),
        // street_address: Yup.string().required(`${phrase.enter} ${phrase.street_address}`),
        // vat_number: Yup.string()
        //     .max(13, `${phrase.invalid_format}`)
        //     .matches(/^[a-zA-Z0-9]*$/g, `${phrase.invalid_format}`)
        //     .required(`${phrase.enter} ${phrase.vat_number}`),
        // ein_number: Yup.string()
        //     .max(11, `${phrase.invalid_format}`)
        //     .matches(/^[a-zA-Z0-9]*$/g, `${phrase.invalid_format}`)
        //     .required(`${phrase.enter} ${phrase.ein_number}`),
    })
}

export const step1Data = (formik, phrase, languageArr, allCountries, statesinLocal) => {
    return [
        // {
        //     label: phrase.first_name,
        //     name: 'first_name',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.first_name}`,
        //     class: 'col-md-6 col-12',
        //     variant: 'outlined',
        //     autoFocus: true,
        //     formik: formik,
        // },
        // {
        //     label: phrase.last_name,
        //     name: 'last_name',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.last_name}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },
        {
            label: phrase.email,
            name: 'email',
            type: 'email',
            placeholder: `${phrase.enter} ${phrase.email}`,
            class: 'col-12',
            formik: formik,
            autoFocus: true,
        },
        // {
        //     label: phrase.phone_number,
        //     name: 'phone',
        //     type: 'countrySelect',
        //     defaultCountry: 'us',
        //     regions: 'america',
        //     placeholder: `${phrase.enter} ${phrase.phone_number}`,
        //     class: 'col-12',
        //     formik: formik,
        // },
        // {
        //     label: phrase.company_name,
        //     name: 'company_name',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.company_name}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },
        // {
        //     label: `${phrase.company_id} / ${phrase.vat_number} / ${phrase.ein_number}`,
        //     name: 'company_id',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.company_id} / ${phrase.vat_number} / ${phrase.ein_number}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },
        {
            label: phrase.password,
            name: 'password',
            type: 'password',
            placeholder: `${phrase.enter} ${phrase.password}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: `${phrase.confirm} ${phrase.password}`,
            name: 'password1',
            type: 'password',
            placeholder: `${phrase.confirm} ${phrase.password}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        // {
        //     label: phrase.company_name,
        //     name: 'company_name',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.company_name}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },
        // {
        //     label: `${phrase.company_id} / ${phrase.vat_number} / ${phrase.ein_number}`,
        //     name: 'company_id',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.company_id} / ${phrase.vat_number} / ${phrase.ein_number}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },
        // {
        //     label: phrase.vat_number,
        //     name: 'vat_number',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.vat_number}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },
        // {
        //     label: phrase.ein_number,
        //     name: 'ein_number',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.ein_number}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },
        // {
        //     label: 'Parent Company',
        //     name: 'parent_company',
        //     type: 'text',
        //     placeholder: `${phrase.enter} Parent Company Name`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },
        // {
        //     label: phrase.type_of_business,
        //     name: 'business_type',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.type_of_business}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },
        // {
        //     label: phrase.username,
        //     name: 'username',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.username}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },

        //     label: phrase.landline_number,
        //     name: 'landline',
        //     type: 'countrySelect',
        //     defaultCountry: 'us',
        //     regions: 'america',
        //     placeholder: `${phrase.enter} ${phrase.landline_number}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },

        // {
        //     label: `${phrase.confirm} ${phrase.email}`,
        //     name: 'confirm_email',
        //     type: 'email',
        //     placeholder: `${phrase.confirm} ${phrase.email}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },

        // {
        //     label: phrase.language,
        //     placeholder: `${phrase.select} ${phrase.language}`,
        //     class: 'col-md-6 col-12',
        //     type: 'select',
        //     options: languageArr,
        //     name: 'language',
        //     formik: formik,
        // },
        // {
        //     label: 'Address Line 1',
        //     name: 'street_address',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.street_address}`,
        //     class: 'col-md-6 col-12',
        //     autoFocus: false,
        //     formik: formik,
        // },
        // {
        //     label: 'Address Line 2',
        //     name: 'street_address2',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.street_address}`,
        //     class: 'col-md-6 col-12',
        //     autoFocus: false,
        //     formik: formik,
        // },
        // {
        //     label: phrase.country,
        //     placeholder: `${phrase.select} ${phrase.country}`,
        //     class: 'col-md-6 col-12',
        //     type: 'select',
        //     options: allCountries,
        //     name: 'counntry',
        //     formik: formik,
        // },
        // {
        //     label: phrase.state,
        //     placeholder: `${phrase.select} ${phrase.state}`,
        //     class: 'col-md-6 col-12',
        //     type: 'select',
        //     options: statesinLocal,
        //     name: 'state',
        //     formik: formik,
        // },
        // {
        //     label: phrase.city,
        //     name: 'city',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.city}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        // },
        // {
        //     label: phrase.zip,
        //     name: 'zip_code',
        //     type: 'text',
        //     placeholder: `${phrase.enter} ${phrase.zip}`,
        //     class: 'col-md-6 col-12',
        //     formik: formik,
        //     inputProps: { min: 0 },
        // },
    ]
}

export const step2InitialValues = () => {
    return {
        city: '',
        counntry: '',
        state: '',
        zip_code: '',
        street_address: '',
    }
}
export const step2ValidationSchema = (phrase) => {
    return Yup.object({
        city: Yup.string().required(`${phrase.enter} ${phrase.city}`),
        counntry: Yup.string().required(`${phrase.enter} ${phrase.country}`),
        state: Yup.string()
            .test('checkUndefined', `${phrase.enter} ${phrase.state}`, (value) => {
                console.log('state validation::: ', value)
                if (typeof value === 'undefined') {
                    console.log('UNDEFINED')
                    return false
                }
                return true
            })
            .required(`${phrase.enter} ${phrase.state}`),
        zip_code: Yup.string()
            // .matches(/^[a-zA-Z0-9 ]*$/g, `${phrase.invalid_format} ${phrase.zip}`)
            .min(4, 'Min 4 Characters')
            .max(10, 'Max 10 Characters')
            .test('checkMin', `${phrase.invalid_format} ${phrase.zip}`, (value) => {
                let zip = parseInt(value)
                if (zip < 0) return false
                return true
            }),
        street_address: Yup.string().required(`${phrase.enter} ${phrase.street_address}`),
    })
}
export const step2Data = (formik, phrase, allCountries, statesinLocal) => {
    return [
        {
            label: phrase.street_address,
            name: 'street_address',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.street_address}`,
            class: 'col-md-6 col-12',
            autoFocus: false,
            formik: formik,
        },
        {
            label: phrase.country,
            placeholder: `${phrase.select} ${phrase.country}`,
            class: 'col-md-6 col-12',
            type: 'select',
            options: allCountries,
            name: 'counntry',
            formik: formik,
        },
        {
            label: phrase.state,
            placeholder: `${phrase.select} ${phrase.state}`,
            class: 'col-md-6 col-12',
            type: 'select',
            options: statesinLocal,
            name: 'state',
            formik: formik,
        },
        {
            label: phrase.city,
            name: 'city',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.city}`,
            class: 'col-md-3 col-12',
            formik: formik,
        },
        {
            label: phrase.zip,
            name: 'zip_code',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.zip}`,
            class: 'col-md-3 col-12',
            formik: formik,
            inputProps: { min: 0 },
        },
    ]
}

export const step3InitialValues = () => {
    return {
        categories: '',
    }
}

export const step3ValidationSchema = (phrase) => {
    return Yup.object({
        categories: Yup.array().required(`${phrase.select} ${phrase.category}`),
    })
}

export const step4InitialValues = () => {
    return {
        tax_exempt: 0,
        tax_exempt_document: '',
        db_number: '',
        tax_number: '',
        business_years: '',
        payment_method: 0,
        business_structure: 0,
        other_business_structure: '',
        ap_name: '',
        ap_phone: '',
        ap_email: '',
        buyer_name: '',
        buyer_phone: '',
        buyer_email: '',
        owner_email: '',
        owner_title: '',
        owner_name: '',
    }
}
export const step4ValidationSchema = (phrase) => {
    return Yup.object({
        tax_exempt: Yup.string().required(`${phrase.enter} Tax Exempt`),
        tax_exempt_document: Yup.mixed().when('tax_exempt', {
            is: 1,
            then: Yup.mixed().required('Upload Tax Exempt Document'),
        }),
        owner_title: Yup.string().required(`${phrase.enter} Title`),
        owner_name: Yup.string().required(`${phrase.enter} Name`),
        owner_email: Yup.string().required(`${phrase.enter} Email`),
        ap_name: Yup.string().required(`${phrase.enter} Name`),
        ap_phone: Yup.string().required(`${phrase.enter} Phone Number`),
        ap_email: Yup.string().required(`${phrase.enter} Email`),
        buyer_name: Yup.string().required(`${phrase.enter} Name`),
        buyer_phone: Yup.string().required(`${phrase.enter} Phone Number`),
        buyer_email: Yup.string().required(`${phrase.enter} Email`),
    })
}
export const step4Data = (
    formik,
    phrase,
    taxExemptOptions,
    paymentMethods,
    businessStructures,
    isOtherSelected,
) => {
    return [
        [
            {
                label: 'D&B Number',
                name: 'db_number',
                type: 'number',
                placeholder: `${phrase.enter} D&B Number`,
                class: 'col-md-3 col-12',
                formik: formik,
                autoFocus: true,
                inputProps: { min: 0 },
            },
            {
                label: 'Federal Tax ID Number',
                name: 'tax_number',
                type: 'number',
                placeholder: `${phrase.enter} Federal Tax ID Number`,
                class: 'col-md-3 col-12',
                formik: formik,
                inputProps: { min: 0 },
            },
            {
                label: 'Years of Business',
                name: 'business_years',
                type: 'number',
                placeholder: `${phrase.enter} Years of Business`,
                class: 'col-md-3 col-12',
                formik: formik,
                inputProps: { min: 0 },
            },
            {
                label: 'Payment Method',
                name: 'payment_method',
                type: 'select',
                options: paymentMethods,
                placeholder: `${phrase.enter} Payment Method`,
                class: 'col-md-3 col-12',
                formik: formik,
            },
            {
                label: 'Business Structure',
                name: 'business_structure',
                type: 'select',
                options: businessStructures,
                placeholder: `${phrase.enter} Business Structure`,
                class: 'col-md-6 col-12',
                formik: formik,
            },
            {
                label: 'Business Structure if Other',
                name: 'other_business_structure',
                type: 'text',
                placeholder: `${phrase.enter} Business Structure if Other`,
                class: 'col-md-6 col-12',
                formik: formik,
                disabled: !isOtherSelected,
            },
            {
                label: 'Tax Exempt',
                name: 'tax_exempt',
                type: 'select',
                options: taxExemptOptions,
                placeholder: `${phrase.enter} Tax Exempt`,
                class: 'col-md-12 col-12',
                formik: formik,
            },
        ],
        //A/P Contact
        [
            {
                label: 'Name',
                name: 'ap_name',
                type: 'text',
                placeholder: `${phrase.enter} Name`,
                class: 'col-md-4 col-12',
                formik: formik,
            },
            {
                label: 'Phone Number',
                name: 'ap_phone',
                type: 'countrySelect',
                defaultCountry: 'us',
                regions: 'america',
                placeholder: `${phrase.enter} Phone Number`,
                class: 'col-md-4 col-12',
                formik: formik,
            },
            {
                label: 'Email',
                name: 'ap_email',
                type: 'text',
                placeholder: `${phrase.enter} Email`,
                class: 'col-md-4 col-12',
                formik: formik,
            },
        ],
        //Buyer Contact
        [
            {
                label: 'Name',
                name: 'buyer_name',
                type: 'text',
                placeholder: `${phrase.enter} Name`,
                class: 'col-md-4 col-12',
                formik: formik,
            },
            {
                label: 'Phone Number',
                name: 'buyer_phone',
                type: 'countrySelect',
                defaultCountry: 'us',
                regions: 'america',
                placeholder: `${phrase.enter} Phone Number`,
                class: 'col-md-4 col-12',
                formik: formik,
            },
            {
                label: 'Email',
                name: 'buyer_email',
                type: 'text',
                placeholder: `${phrase.enter} Email`,
                class: 'col-md-4 col-12',
                formik: formik,
            },
        ],
    ]
}

export const step5InitialValues = () => {
    return {
        agree: [],
        agreeSalesTax: [],
        categories: [],
    }
}

export const step5ValidationSchema = (phrase) => {
    return Yup.object({
        agree: Yup.string().required('Required'),
        agreeSalesTax: Yup.string().required('Required'),
        categories: Yup.array().required(`${phrase.select} ${phrase.category}`),
    })
}
