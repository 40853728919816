import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Checkout.css'
import OneImg from '../../assets/images/teams.png'
import { currencyFormat, handleRedirectInternal, mapData } from '../../common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CheckBox from '../../components/atoms/CheckBox'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { apiCall } from '../../common/api'
import { useHistory } from 'react-router-dom'
import AuthContext from '../../context/auth/authContext'
import UserContext from '../../context/user/userContext'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import csc from 'country-state-city'
import commonContext from '../../context/common/commonContext'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'
import moment from 'moment'
import _ from 'lodash'
import alertContext from '../../context/alert/alertContext'
import Loader from '../../components/molecules/Loader'
import { MenuItem, Tooltip } from '@material-ui/core'
import Popup from '../../components/organisms/Popup'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import { Button } from '@material-ui/core'
import lookup from 'country-code-lookup'

const Checkout = () => {
    const params = new URLSearchParams(window.location.search.slice(1))
    const checkoutType = params.get('invoice_id') ? 'auction' : 'buynow'
    const [popup, setPopup] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)
    const [showTooltipTwo, setShowTooltipTwo] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const cardDetailsInfo = [
        {
            label: 'Card Holder Name',
            name: 'cardName',
            type: 'text',
            class: 'col-12',
            placeholder: 'Enter card holder name',
        },
        {
            label: 'Card Number',
            name: 'cardNumber',
            type: 'text',
            class: 'col-12',
            placeholder: 'Enter your card number',
        },
        {
            label: 'Expiry Month',
            name: 'expiryMonth',
            type: 'text',
            class: 'col-6',
            placeholder: 'MM',
        },
        {
            label: 'Expiry Year',
            name: 'expiryYear',
            type: 'text',
            class: 'col-6',
            placeholder: 'YYYY',
        },
        {
            label: 'CVV',
            name: 'cvv',
            type: 'text',
            class: 'col-6',
            placeholder: 'Enter your CVV',
        },
        {
            label: 'Card Address Line 1',
            name: 'cardAddress1',
            type: 'text',
            class: 'col-12',
            placeholder: 'Enter your card Address Line 1',
        },
        {
            label: 'Card Address Line 2',
            name: 'cardAddress2',
            type: 'text',
            class: 'col-12',
            placeholder: 'Enter your card Address Line 2',
        },
        {
            label: 'Select Country',
            name: 'country',
            type: 'text',
            class: 'col-6',
            placeholder: 'Select Country',
        },
        {
            label: 'Select State',
            name: 'state',
            type: 'text',
            class: 'col-6',
            placeholder: 'Select State',
        },
        {
            label: 'Card City',
            name: 'city',
            type: 'text',
            class: 'col-6',
            placeholder: 'Enter card city',
        },
        {
            label: 'Card Zip Code',
            name: 'state',
            type: 'text',
            class: 'col-6',
            placeholder: 'Enter card zipcode',
        },
    ]

    const history = useHistory()
    // state
    let {
        getUserData,
        userData,
        shippingAddressData,
        userNotifications,
        updateUserNumber,
        updateBillingAddress,
        updateUserLanguage,
        updateUserPassword,
        checkOldPassword,
        updateUserNotifications,
        updateShippingAddress,
    } = useContext(UserContext)
    const [invoiceData, setInvoiceData] = useState({})
    const [shippingData, setShippingData] = useState({})
    const { user } = useContext(AuthContext)
    const { allCountries, phrase } = useContext(commonContext)
    const { setAlert } = useContext(alertContext)
    const [error, setError] = useState({})
    const [cardData, setCardData] = useState('nocard')
    const [saveCard, setSaveCard] = useState(false)
    const [stateList, setStatesinLocal] = useState([])
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true)
    const [userCards, setUserCards] = useState([])
    const [shippingPopup, setShippingPopup] = useState(false)
    const [billingPopup, setBillingPopup] = useState(false)
    const [cardDetails, setCardDetails] = useState({
        cardName: '',
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cvv: '',
        cardAddress: '',
        cardAddress1: '',
        cardCountry: '',
        cardState: '',
        cardCity: '',
        cardZip: '',
    })
    const [addressInitialValues, setAddressInitialValues] = useState({
        first_name: '',
        last_name: '',
        company_name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    })
    const [shippingAddressInitialValues, setShippingAddressInitialValues] = useState({
        first_name: '',
        last_name: '',
        company_name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        shipid: '',
    })
    const addressValidationArray = Yup.object({
        // company_name: Yup.string().required('Required Field'),
        address: Yup.string().required('Required Field'),
        first_name: Yup.string().required('Required Field'),
        last_name: Yup.string().required('Required Field'),
        city: Yup.string().required('Required Field'),
        state: Yup.string().required('Required Field'),
        country: Yup.string().required('Required Field'),
        zip: Yup.string()
            .required('Required Field')
            .matches(/^[a-zA-Z0-9 ]*$/g, `${phrase.invalid_format} ${phrase.zip}`)
            .min(5, `${phrase.invalid_format} ${phrase.zip}`)
            .max(8, `${phrase.invalid_format} ${phrase.zip}`)
            .test('checkMin', `${phrase.invalid_format} ${phrase.zip}`, (value) => {
                let zip = parseInt(value)
                if (zip < 0) return false
                return true
            }),
    })
    const editAddressFormik = useFormik({
        initialValues: addressInitialValues,
        validationSchema: addressValidationArray,
        onSubmit: async (values) => {
            setLoading(true)
            let payload = {}
            let { name: countryName } = csc.getCountryById(values['country'])
            let { name: stateName } = csc.getStateById(values['state'])
            payload['billing_firstname'] = values['first_name']
            payload['billing_lastname'] = values['last_name']
            payload['billing_companyname'] = values['company_name']
            payload['billing_address'] = values['address']
            payload['billing_city'] = values['city']
            payload['billing_state'] = stateName
            payload['billing_country'] = countryName
            payload['billing_zipcode'] = values['zip']

            setBillingPopup(false)
            editAddressFormik.resetForm()
            let addressUpdated = await updateBillingAddress(payload)
            setLoading(false)
            if (addressUpdated) {
                await getUserData()
                setAlert('Billing Info Updated Successfully', 'success')
            }
        },
        enableReinitialize: true,
    })
    const editShippingAddressFormik = useFormik({
        initialValues: shippingAddressInitialValues,
        validationSchema: addressValidationArray,
        onSubmit: async (values) => {
            setLoading(true)
            let payload = {}
            let { name: countryName } = csc.getCountryById(values['country'])
            let { name: stateName } = csc.getStateById(values['state'])
            payload['billing_firstname'] = values['first_name']
            payload['billing_lastname'] = values['last_name']
            payload['billing_companyname'] = values['company_name']
            payload['billing_address'] = values['address']
            payload['billing_city'] = values['city']
            payload['billing_state'] = stateName
            payload['billing_country'] = countryName
            payload['billing_zipcode'] = values['zip']

            if (shippingAddressData) {
                payload['shipid'] = values['shipid']
            }
            setShippingPopup(false)
            editShippingAddressFormik.resetForm()
            let addressUpdated = await updateShippingAddress(payload)
            setLoading(false)
            if (addressUpdated) {
                await getUserData()
                setAlert('Shipping Info Updated Successfully', 'success')
                setTrigger(!trigger)
            }
        },
        enableReinitialize: true,
    })
    const editBillingAddress = [
        {
            label: phrase.first_name,
            name: 'first_name',
            type: 'text',
            class: 'col-6',
            autoFocus: true,
            formik: editAddressFormik,
        },
        {
            label: phrase.last_name,
            name: 'last_name',
            type: 'text',
            class: 'col-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.company_name,
            name: 'company_name',
            type: 'text',
            class: 'col-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.billing_address,
            name: 'address',
            type: 'text',
            class: 'col-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.country,
            name: 'country',
            type: 'select',
            options: allCountries,
            class: 'col-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.city,
            name: 'city',
            type: 'text',
            class: 'col-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.state,
            name: 'state',
            type: 'select',
            options: stateList,
            class: 'col-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.zip,
            name: 'zip',
            type: 'text',
            class: 'col-6',
            formik: editAddressFormik,
        },
    ]
    const editShippingAddress = [
        {
            label: phrase.first_name,
            name: 'first_name',
            type: 'text',
            class: 'col-6',
            autoFocus: true,
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.last_name,
            name: 'last_name',
            type: 'text',
            class: 'col-6',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.company_name,
            name: 'company_name',
            type: 'text',
            class: 'col-6',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.shipping_address,
            name: 'address',
            type: 'text',
            class: 'col-6',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.country,
            name: 'country',
            type: 'select',
            options: allCountries,
            class: 'col-6',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.city,
            name: 'city',
            type: 'text',
            class: 'col-6',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.state,
            name: 'state',
            type: 'select',
            options: stateList,
            class: 'col-6',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.zip,
            name: 'zip',
            type: 'text',
            class: 'col-6',
            formik: editShippingAddressFormik,
        },
    ]
    const onTextChange = (e) => {
        const re = /^[0-9\b]+$/
        if (
            e.target.name === 'cardNumber' ||
            e.target.name === 'cvv' ||
            e.target.name === 'expiryMonth' ||
            e.target.name === 'expiryYear'
        ) {
            if (e.target.value !== '' && !re.test(e.target.value)) {
                return null
            }
            if (e.target.name === 'cvv') {
                if (e.target.value.length > 4) {
                    return
                }
            }
        }
        setCardDetails({ ...cardDetails, [e.target.name]: e.target.value })
        let errorR = error
        delete errorR[e.target.name]
        setError({ ...errorR })
    }
    const onCheckBoxChange = () => setSaveCard((prevState) => !prevState)
    const onChange = (e) => (e.target.checked ? setCardData(e.target.value) : setCardData('nocard'))
    useEffect(() => {
        const { id, name } = csc.getCountryById(cardDetails.cardCountry)
        let states = csc.getStatesOfCountry(id)
        states = states.map((ele) => {
            let o = {
                show: ele.name,
                value: ele.id,
            }
            return o
        })
        setStatesinLocal(states)
    }, [cardDetails.cardCountry])
    const getAllCards = async (body) => {
        const res = await apiCall('post', `api/customer/source/list`, body, '', '', 1)
        if (res.data.status === 'success') {
            setUserCards(res.data.data.responseData.data)
        }
    }
    useEffect(async () => {
        await getUserData()
    }, [])
    useEffect(() => {
        if ((params.get('invoice_id') || params.get('buynow_id')) && Object.keys(user).length) {
            document.title = `${global.site_title} | Checkout`
            getInvoiceData(params.get('invoice_id') || params.get('buynow_id'))

            getAllCards({
                account_id: process.env.REACT_APP_ACCOUNT_ID,
                customer_id: user.card_paymentid,
                object: 'card',
            })
        }
    }, [user, trigger])

    useEffect(() => {
        if (stateList.length > 0) {
            let stateId = stateList.findIndex((element) => element.show == userData.state)
            if (lookup.byCountry(userData.country) != null) {
                let { id: countryId } = csc.getCountryByCode(
                    lookup.byCountry(userData.country).iso2,
                )
                setAddressInitialValues({
                    ...addressInitialValues,
                    country: countryId,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    company_name: userData.companyname,
                    address: userData.address1,
                    city: userData.city,
                    zip: userData.zip,
                    state: stateList[stateId] && stateList[stateId].value,
                })
            }
            let shipstateId = stateList.findIndex(
                (element) => element.show == shippingAddressData.state,
            )
            if (lookup.byCountry(shippingAddressData.country) != null) {
                let { id: countryId } = csc.getCountryByCode(
                    lookup.byCountry(shippingAddressData.country).iso2,
                )
                setShippingAddressInitialValues({
                    ...shippingAddressInitialValues,
                    country: countryId,
                    first_name: shippingAddressData.first_name,
                    last_name: shippingAddressData.last_name,
                    company_name: shippingAddressData.companyname,
                    address: shippingAddressData.address,
                    city: shippingAddressData.city,
                    zip: shippingAddressData.zipcode,
                    state: stateList[shipstateId] && stateList[shipstateId].value,
                    shipid: shippingAddressData.id,
                })
            }
        } else {
            if (lookup.byCountry(userData.country) != null) {
                let { id: countryId } = csc.getCountryByCode(
                    lookup.byCountry(userData.country).iso2,
                )
                setAddressInitialValues({
                    ...addressInitialValues,
                    country: countryId,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    company_name: userData.companyname,
                    address: userData.address1,
                    city: userData.city,
                    zip: userData.zip,
                })
            }
            if (lookup.byCountry(shippingAddressData.country) != null) {
                let { id: countryId } = csc.getCountryByCode(
                    lookup.byCountry(shippingAddressData.country).iso2,
                )
                setShippingAddressInitialValues({
                    ...shippingAddressInitialValues,
                    country: countryId,
                    first_name: shippingAddressData.first_name,
                    last_name: shippingAddressData.last_name,
                    company_name: shippingAddressData.companyname,
                    address: shippingAddressData.address,
                    city: shippingAddressData.city,
                    zip: shippingAddressData.zipcode,
                    shipid: shippingAddressData.id,
                })
            }
        }
    }, [userData, shippingAddressData])

    useEffect(() => {
        const { id } = csc.getCountryById(editAddressFormik.values.country)
        let states = csc.getStatesOfCountry(id)
        states = states.map((ele) => {
            let o = {
                show: ele.name,
                value: ele.id,
            }
            return o
        })
        setStatesinLocal(states)
        let stateIndex = states.findIndex((element) => element.show == userData.state)
        if (stateIndex != -1) {
            setAddressInitialValues({
                ...addressInitialValues,
                state: states[stateIndex] && states[stateIndex].value,
            })
        }
    }, [editAddressFormik.values.country])

    useEffect(() => {
        const { id } = csc.getCountryById(editShippingAddressFormik.values.country)
        let states = csc.getStatesOfCountry(id)
        states = states.map((ele) => {
            let o = {
                show: ele.name,
                value: ele.id,
            }
            return o
        })
        setStatesinLocal(states)
        let stateIndex = states.findIndex((element) => element.show == shippingAddressData.state)
        if (stateIndex != -1) {
            setShippingAddressInitialValues({
                ...shippingAddressInitialValues,
                state: states[stateIndex] && states[stateIndex].value,
            })
        }
    }, [editShippingAddressFormik.values.country])

    const calculateShipping = async (body) => {
        const res = await apiCall('post', ``, body, '', 'getShippingAmount')
        if (res.data.status) {
            return parseFloat(res.data.shipping_amount)
        } else {
            return 0
        }
    }
    console.log(user, 'cardData')

    const getInvoiceData = (id) => {
        apiCall(
            'get',
            `${checkoutType === 'auction' ? 'invoice' : 'viewsample'}/${id}`,
            '',
            '',
            'product',
        )
            .then(async (results) => {
                let { data } = results
                console.log('GOT INVOICE DATA: ', data)
                if (data.status == 'success') {
                    if (data.data.invoicedata.paid == 1) {
                        return history.goBack()
                    }
                    var temp_shipping = {}
                    if (data.data.shipping?.length > 1) {
                        temp_shipping = data.data.shipping[0]
                    } else {
                        temp_shipping = data.data.shipping[0]
                    }
                    setShippingData(temp_shipping)
                    console.log(temp_shipping, 'temp_shippingtemp_shippingtemp_shipping')
                    const shipping_fee = await calculateShipping({
                        product_id:
                            data.data.invoicedata.lineproject_id > 0
                                ? data.data.invoicedata.lineproject_id
                                : data.data.invoicedata.id,
                        city: temp_shipping?.city,
                        zipcode: temp_shipping?.zipcode,
                        country: temp_shipping?.country,
                    })
                    data.data.invoicedata.shipping_amount = shipping_fee

                    data.data.invoicedata.lineitem = data.data.invoicelineitem
                    data.data.invoicedata.total =
                        data.data.invoicedata.amount +
                        data.data.invoicedata.buyers_premium +
                        parseFloat(data.data.invoicedata.sales_tax) +
                        data.data.invoicedata.shipping_amount
                    data.data.invoicedata.transaction_fee = parseFloat(
                        ((data.data.invoicedata.total * 3.1) / 100 + 0.3).toFixed(2),
                    )
                    data.data.invoicedata.total_invoice =
                        data.data.invoicedata.total + data.data.invoicedata.transaction_fee
                    data.data.invoicedata.lineItemInvoice =
                        data.data.invoicedata.auctiontype == 'lineitem'
                            ? data.data.invoicelineitem
                            : []
                    setInvoiceData(data.data.invoicedata)
                    setInitialLoading(false)
                } else {
                    history.goBack()
                }
            })
            .catch((err) => {
                handleRedirectInternal(history, 'search')
            })
    }

    const createStripeCard = async (body) => {
        const res = await apiCall('post', `api/customer/source/create`, body, '', '', 1)
        return res
    }
    const makePayment = async (body) => {
        const res = await apiCall('post', `checkout`, body, '', 'product')
        setLoading(false)
        if (res.data.success) {
            setAlert(res.data.message, 'success')
            handleRedirectInternal(history, `invoice/${params.get('invoice_id')}`)
        } else {
            setAlert(res.data.message || res.data?.data?.message, 'error')
        }
    }

    const makePaymentBuy = async (body) => {
        const res = await apiCall('post', `checkout_buy`, body, '', 'product')
        setLoading(false)
        if (res.data.success) {
            setAlert(res.data.message, 'success')
            handleRedirectInternal(
                history,
                `invoice/${res.data.invoice_id ? res.data.invoice_id : params.get('invoice_id')}`,
            )
        } else {
            setAlert(res.data.message || res.data?.data?.message, 'error')
        }
    }

    const handleSubmit = async () => {
        if (!shippingAddressData.zipcode) {
            return setAlert('Kindly add shipping address!', 'error')
        }
        if (!userData.zip) {
            return setAlert('Kindly add billing address!', 'error')
        }
        const regex = /^[a-zA-Z ]+$/
        const regexZip = /^[a-zA-Z0-9 ]*$/g
        let error = {}
        if (cardData === 'nocard') {
            if (
                isNaN(cardDetails.cardNumber) ||
                cardDetails.cardNumber.length < 14 ||
                cardDetails.cardNumber.length > 16
            ) {
                error['cardNumber'] = 'Enter Valid Card Number!'
            }
            if (!cardDetails.cardName || !regex.test(cardDetails.cardName)) {
                error['cardName'] = 'Enter Valid Name!'
            }
            if (!cardDetails.cardCountry) {
                error['cardCountry'] = 'Enter Valid Country!'
            }
            if (!cardDetails.cardCity || !regex.test(cardDetails.cardCity)) {
                error['cardCity'] = 'Enter Valid City!'
            }
            // if (!regex.test(cardDetails.cardCity)) {
            //     error['cardCity'] = 'Only characters allowed!'
            // }
            if (!cardDetails.cardState) {
                error['cardState'] = 'Enter Valid State!'
            }
            if (!cardDetails.cardAddress) {
                error['cardAddress'] = 'Enter Valid Address!'
            }
            if (
                cardDetails.cardZip.length === 0 ||
                !(
                    regexZip.test(cardDetails.cardZip) &&
                    cardDetails.cardZip.length < 9 &&
                    cardDetails.cardZip.length > 4
                )
            ) {
                error['cardZip'] = 'Enter Valid Zip Code!'
            }
            if (
                cardDetails.expiryMonth.length === 0 ||
                isNaN(cardDetails.expiryMonth) ||
                cardDetails.expiryMonth.length > 2 ||
                cardDetails.expiryMonth.length < 2 ||
                cardDetails.expiryMonth <= 0 ||
                cardDetails.expiryMonth > 12
            ) {
                error['expiryMonth'] = 'Enter Valid Month!'
            }
            if (
                cardDetails.cvv.length === 0 ||
                isNaN(cardDetails.cvv) ||
                cardDetails.cvv.length > 4 ||
                cardDetails.cvv.length < 3
            ) {
                error['cvv'] = 'Enter Valid CVV!'
            }
            if (
                isNaN(cardDetails.expiryYear) ||
                cardDetails.expiryYear.length === 0 ||
                (cardDetails.expiryYear.length < 2 && cardDetails.expiryYear.length > 4)
            ) {
                error['expiryYear'] = 'Enter Valid Year!'
            }
            if (!error['expiryMonth'] && !error['expiryYear']) {
                let month =
                    cardDetails.expiryMonth.length === 1
                        ? `${0}${cardDetails.expiryMonth}`
                        : cardDetails.expiryMonth
                let year = cardDetails.expiryYear.length === 2 ? `YY` : 'YYYY'
                if (moment(`${month}/${cardDetails.expiryYear}`, `MM/${year}`).isBefore(moment())) {
                    error['expiryYear'] = 'Enter Valid Year!'
                }
            }
            if (!shippingAddressData) {
                error['shippingAddress'] = 'Please Select Shipping Address'
                setAlert(error['shippingAddress'], 'error')
            }
            if (!userData) {
                error['billingData'] = 'Please Select Billing Address'
                setAlert(error['billingData'], 'error')
            }
            setError(error)
        }
        if (_.isEmpty(error)) {
            setLoading(true)
            var body = {}
            const { name: countryName, sortname } = csc.getCountryById(cardDetails?.cardCountry)
            console.log(sortname, 'sortname', csc.getCountryById(cardDetails?.cardCountry))
            if (cardData === 'nocard') {
                body = {
                    // amount: parseFloat(
                    //     ((parseFloat(amount) + parseFloat(buyers_premium)) * 100).toFixed(2),
                    // ),
                    customer: user.card_paymentid,
                    currency: 'usd',
                    description: `Ship Cycle Auction Checkout - #${
                        invoiceData?.common_invoice || invoiceData?.id
                    }`,
                    card_name: cardDetails.cardName,
                    card_number: cardDetails.cardNumber,
                    card_cvc: cardDetails.cvv,
                    card_exp_month: cardDetails.expiryMonth,
                    card_exp_year: cardDetails.expiryYear,
                    card_token: true,
                    message: 'Manual Payment!',
                    card_address_line1: cardDetails.cardAddress,
                    card_address_line2: cardDetails.cardAddress1,
                    card_address_city: cardDetails.cardCity,
                    card_address_state: cardDetails.cardState,
                    card_address_country: sortname,
                    card_address_zip: cardDetails.cardZip,
                    billing_details: {
                        email: user.email,
                        name: user.first_name,
                        address: {
                            line1: user.address1,
                            line2: user.address2,
                            city: user.city,
                            state: user.state,
                            country: sortname,
                            postal_code: user.zip,
                        },
                    },
                }
            } else {
                body = {
                    source: cardData,
                    customer: user.card_paymentid,
                    currency: 'usd',
                    description: `Ship Cycle Auction Checkout - #${invoiceData?.common_invoice}`,
                }
            }
            if (saveCard) {
                let newCard = {
                    account_id: process.env.REACT_APP_ACCOUNT_ID,
                    customer_id: user ? user.card_paymentid : '',
                    cvv: '',
                    card_address_zip: cardDetails.cardZip,
                    card_address_country: sortname,
                    is_primary: '',
                    user_id: '',
                    id: '',
                    card_token: true,
                    card_name: cardDetails.cardName,
                    card_number: cardDetails.cardNumber,
                    card_cvc: cardDetails.cvv,
                    card_exp_month: cardDetails.expiryMonth,
                    card_exp_year: cardDetails.expiryYear,
                    card_address_line1: cardDetails.cardAddress,
                    card_address_line2: cardDetails.cardAddress1,
                    card_address_city: cardDetails.cardCity,
                    card_address_state: cardDetails.cardState,
                }
                const resp = await createStripeCard(newCard)
                if (resp.data.status === 'error') {
                    setLoading(false)
                    return setAlert(resp.data?.data?.message, 'error')
                }
            }
            if (checkoutType === 'auction') {
                await makePayment({
                    invoice_id: [invoiceData?.common_invoice],
                    payment_details: body,
                    user_country: sortname,
                    shipping_fee: invoiceData?.shipping_amount,
                })
            } else {
                await makePaymentBuy({
                    invoice_id: [invoiceData?.id],
                    payment_details: body,
                    user_country: sortname,
                    shipping_fee: invoiceData?.shipping_amount,
                })
            }
        }
    }
    return (
        <>
            <div
                className="container-fluid checkout py-4"
                style={{ backgroundColor: 'var(--secColor)' }}
            >
                <div className="customContainer">
                    {initialLoading ? (
                        <Loader />
                    ) : (
                        <div className="row">
                            <div className="col-lg-8 col-12">
                                <h2 style={{ minHeight: '24px' }}></h2>
                                <div
                                    className="media"
                                    style={{ margin: '20px 0px', flexWrap: 'wrap' }}
                                >
                                    <div className="media-left">
                                        <div className="grid-img-wrap">
                                            <div className="pcgImg">
                                                {/* {(props.dashboard &&
                                            props.dashboardType != 'watchlist' &&
                                            props.dashboardType != 'active') ||
                                        props.completed ? null : user.isBuyer ? (
                                            <FavouriteCheckbox
                                                watchlisted={product.watchlistid}
                                                project_id={product.id}
                                                isDashboard={props.dashboard}
                                            />
                                        ) : null} */}
                                                <Link>
                                                    <img
                                                        src={`${process.env.REACT_APP_DOMAIN}/uploads/product/${invoiceData?.avatar}`}
                                                        alt=""
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {invoiceData.auctiontype == 'lineitem' ? (
                                        <div className=" w-100 align-items-stretch flex-wrap">
                                            {invoiceData.lineItemInvoice.map((item) => {
                                                return (
                                                    <div className="media-body co-box">
                                                        <div className="cob-top">
                                                            <div className="co-label">Title</div>
                                                            <div className="co-text">
                                                                {item.title}
                                                            </div>
                                                        </div>

                                                        <div className="cob-top">
                                                            <div className="co-label">Amount</div>
                                                            <div className="co-text">
                                                                {currencyFormat(
                                                                    item?.amount,
                                                                    item?.currency,
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ) : (
                                        <div className="media-body co-box">
                                            <div className="cob-top">
                                                <div className="co-label">Title</div>
                                                <div className="co-text">
                                                    <li>{invoiceData?.title}</li>
                                                </div>
                                            </div>

                                            <div className="cob-top">
                                                <div className="co-label">Amount</div>
                                                <div className="co-text">
                                                    {currencyFormat(
                                                        invoiceData?.amount,
                                                        invoiceData?.currency,
                                                    )}
                                                </div>
                                            </div>
                                            <div className="cob-top">
                                                <div className="co-label">
                                                    Buyer&apos;s Premium(
                                                    {invoiceData?.buyers_premium_percentage}%)
                                                </div>
                                                <div className="co-text">
                                                    {currencyFormat(
                                                        invoiceData?.buyers_premium,
                                                        invoiceData?.currency,
                                                    )}
                                                </div>
                                            </div>
                                            <div className="cob-top">
                                                <div className="co-label">
                                                    Sales Tax(
                                                    {invoiceData?.sales_tax_percentage}%)
                                                </div>
                                                <div className="co-text">
                                                    {currencyFormat(
                                                        invoiceData?.sales_tax,
                                                        invoiceData?.currency,
                                                    )}
                                                </div>
                                            </div>
                                            <div className="cob-top">
                                                <div className="co-label">
                                                    Packing + Shipping Fee
                                                </div>
                                                {parseFloat(invoiceData?.lbs) >= 70 &&
                                                invoiceData?.auctiontype == 'live' ? (
                                                    <div className="co-text TBCText">
                                                        <p
                                                            className="mb-0"
                                                            onClick={() => setPopup(true)}
                                                        >
                                                            TBC
                                                        </p>
                                                        <Tooltip
                                                            title="All packing and shipping costs will be provided upon request - please email contact@shipcycleauctions.com to get a quote."
                                                            arrow
                                                            placement="top-start"
                                                            open={showTooltipTwo}
                                                            onOpen={() => setShowTooltipTwo(true)}
                                                            onClose={() => setShowTooltipTwo(false)}
                                                            opensOn="Click"
                                                            className="tooling ml-1"
                                                        >
                                                            <span
                                                                onClick={() =>
                                                                    setShowTooltipTwo(
                                                                        !showTooltipTwo,
                                                                    )
                                                                }
                                                            >
                                                                i
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                ) : (
                                                    <div className="co-text">
                                                        {currencyFormat(
                                                            invoiceData?.shipping_amount,
                                                            invoiceData?.currency,
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="cob-top">
                                                <div className="co-label">Total</div>
                                                <div className="co-text">
                                                    {currencyFormat(
                                                        invoiceData?.total,
                                                        invoiceData?.currency,
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="d-flex align-items-stretch flex-wrap"
                                    style={{ gap: '20px' }}
                                >
                                    <div
                                        className="media-body co-box"
                                        // style={{ margin: '0px 0px 43px 0px' }}
                                    >
                                        <div className="cob-top">
                                            <h3>{phrase.shipping_address}</h3>
                                            <div className="address-row">
                                                {' '}
                                                <address>
                                                    {shippingAddressData?.companyname}{' '}
                                                    {shippingAddressData?.address},
                                                    {shippingAddressData?.city},{' '}
                                                    {shippingAddressData?.state}{' '}
                                                    {shippingAddressData?.zipcode}{' '}
                                                    {shippingAddressData?.country}
                                                </address>
                                                <Button
                                                    onClick={() => {
                                                        setShippingPopup(true)
                                                    }}
                                                >
                                                    <span className="material-icons">edit</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="media-body co-box">
                                        <div className="cob-top">
                                            <h3>{phrase.billing_address}</h3>

                                            <div className="address-row">
                                                <address>
                                                    {userData?.companyname} {userData?.address1},
                                                    {userData?.city}, {userData?.state}{' '}
                                                    {userData?.zip} {userData?.country}
                                                </address>

                                                <Button
                                                    onClick={() => {
                                                        setBillingPopup(true)
                                                    }}
                                                >
                                                    <span className="material-icons">edit</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12">
                                <div className="ptp-wrapper">
                                    <h2 className="od-head">Payment Details</h2>
                                    <div className="chkcard">
                                        <span>
                                            <p>Total Amount</p>
                                            <p>
                                                :{' '}
                                                {currencyFormat(
                                                    invoiceData?.amount,
                                                    invoiceData?.currency,
                                                )}
                                            </p>
                                        </span>
                                        <span>
                                            <p>Buyer&apos;s Premium</p>
                                            <p>
                                                :{' '}
                                                {currencyFormat(
                                                    invoiceData?.buyers_premium,
                                                    invoiceData?.currency,
                                                )}
                                            </p>
                                        </span>
                                        <span>
                                            <p>Sales Tax</p>
                                            <p>
                                                :{' '}
                                                {currencyFormat(
                                                    invoiceData?.sales_tax,
                                                    invoiceData?.currency,
                                                )}
                                            </p>
                                        </span>
                                        <span>
                                            <p>Packing + Shipping Fee</p>
                                            {parseFloat(invoiceData?.lbs) >= 70 &&
                                            invoiceData?.auctiontype == 'live' ? (
                                                <p
                                                    onClick={() => setPopup(true)}
                                                    className="d-flex align-items-center"
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <p onClick={() => setPopup(true)}>: TBC</p>
                                                    <Tooltip
                                                        title="All packing and shipping costs will be provided upon request - please email contact@shipcycleauctions.com to get a quote."
                                                        arrow
                                                        placement="top-start"
                                                        open={showTooltip}
                                                        onOpen={() => setShowTooltip(true)}
                                                        onClose={() => setShowTooltip(false)}
                                                        opensOn="Click"
                                                        className="tooling ml-1"
                                                    >
                                                        <span
                                                            onClick={() =>
                                                                setShowTooltip(!showTooltip)
                                                            }
                                                        >
                                                            i
                                                        </span>
                                                    </Tooltip>
                                                </p>
                                            ) : (
                                                <p>{`: ${currencyFormat(
                                                    invoiceData?.shipping_amount,
                                                    invoiceData?.currency,
                                                )}`}</p>
                                            )}
                                        </span>
                                        <span>
                                            <p>Transaction Fee</p>
                                            <p>
                                                :{' '}
                                                {currencyFormat(
                                                    invoiceData?.transaction_fee,
                                                    invoiceData?.currency,
                                                )}
                                            </p>
                                        </span>
                                        <span>
                                            <p>Grand Total </p>
                                            <p>
                                                :{' '}
                                                {currencyFormat(
                                                    invoiceData?.total_invoice,
                                                    invoiceData?.currency,
                                                )}
                                            </p>
                                        </span>
                                    </div>

                                    <div className="od-box">
                                        <form>
                                            {userCards.length
                                                ? userCards.map((data, index) => (
                                                      <div key={index} className="cardSelect">
                                                          <label
                                                              htmlFor={`ritemb${index}`}
                                                              className="cardmain"
                                                          >
                                                              <div className="flx">
                                                                  <p>
                                                                      {'xxxx-xxxx-xxxx-' +
                                                                          data.last4}
                                                                  </p>
                                                                  <div className="radio-item">
                                                                      <input
                                                                          type="checkbox"
                                                                          id={`ritemb${index}`}
                                                                          name="ritem"
                                                                          value={data.id}
                                                                          checked={
                                                                              cardData === data.id
                                                                          }
                                                                          onChange={onChange}
                                                                      />
                                                                      {/* <span className="vcRad"></span> */}
                                                                  </div>
                                                              </div>
                                                              <div className="flx">
                                                                  <p>{data.brand}</p>
                                                                  <p>
                                                                      {data.exp_month}/
                                                                      {data.exp_year}
                                                                  </p>
                                                              </div>
                                                          </label>
                                                      </div>
                                                  ))
                                                : null}
                                            {cardData === 'nocard' ? (
                                                <>
                                                    <div className="row justify-content-center mx-0">
                                                        <>
                                                            <div>
                                                                <div className="form-group">
                                                                    <CustomInput
                                                                        title={'Card Holder Name'}
                                                                        name="cardName"
                                                                        type="text"
                                                                        placeholder="Enter card holder name"
                                                                        onChange={onTextChange}
                                                                        value={
                                                                            cardDetails.cardName ||
                                                                            ''
                                                                        }
                                                                        autoFocus={true}
                                                                        helperText={
                                                                            error.cardName || ''
                                                                        }
                                                                        error={error.cardName || ''}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <CustomInput
                                                                        title={'Card Number'}
                                                                        name="cardNumber"
                                                                        type="text"
                                                                        placeholder="Enter card number"
                                                                        length="16"
                                                                        onChange={onTextChange}
                                                                        value={
                                                                            cardDetails.cardNumber ||
                                                                            ''
                                                                        }
                                                                        // autoFocus={true}
                                                                        helperText={
                                                                            error.cardNumber || ''
                                                                        }
                                                                        error={
                                                                            error.cardNumber || ''
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6 form-group mb-0">
                                                                        <CustomInput
                                                                            title={'Expiry Month'}
                                                                            name="expiryMonth"
                                                                            type="text"
                                                                            length="2"
                                                                            onChange={onTextChange}
                                                                            placeholder="MM"
                                                                            value={
                                                                                cardDetails.expiryMonth ||
                                                                                ''
                                                                            }
                                                                            helperText={
                                                                                error.expiryMonth ||
                                                                                ''
                                                                            }
                                                                            error={
                                                                                error.expiryMonth ||
                                                                                ''
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-6 form-group mb-0">
                                                                        <CustomInput
                                                                            title={'Expiry Year'}
                                                                            name="expiryYear"
                                                                            type="text"
                                                                            length="4"
                                                                            onChange={onTextChange}
                                                                            placeholder="YYYY"
                                                                            value={
                                                                                cardDetails.expiryYear ||
                                                                                ''
                                                                            }
                                                                            helperText={
                                                                                error.expiryYear ||
                                                                                ''
                                                                            }
                                                                            error={
                                                                                error.expiryYear ||
                                                                                ''
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-6 form-group mb-0">
                                                                        <CustomInput
                                                                            title={'CVV'}
                                                                            name="cvv"
                                                                            onChange={onTextChange}
                                                                            type="text"
                                                                            length="4"
                                                                            placeholder="Enter CVV"
                                                                            value={
                                                                                cardDetails.cvv ||
                                                                                ''
                                                                            }
                                                                            helperText={
                                                                                error.cvv || ''
                                                                            }
                                                                            error={error.cvv || ''}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <CustomInput
                                                                        title={
                                                                            'Address Line' +
                                                                            ' ' +
                                                                            '1'
                                                                        }
                                                                        name="cardAddress"
                                                                        type="text"
                                                                        placeholder="Enter card address line 1"
                                                                        onChange={onTextChange}
                                                                        value={
                                                                            cardDetails.cardAddress ||
                                                                            ''
                                                                        }
                                                                        helperText={
                                                                            error.cardAddress || ''
                                                                        }
                                                                        error={
                                                                            error.cardAddress || ''
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <CustomInput
                                                                        title={
                                                                            'Address Line' +
                                                                            ' ' +
                                                                            '2'
                                                                        }
                                                                        name="cardAddress1"
                                                                        type="text"
                                                                        placeholder="Enter card address line 2"
                                                                        onChange={onTextChange}
                                                                        value={
                                                                            cardDetails.cardAddress1 ||
                                                                            ''
                                                                        }
                                                                        helperText={
                                                                            error.cardAddress1 || ''
                                                                        }
                                                                        error={
                                                                            error.cardAddress1 || ''
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="row pymtSlect">
                                                                    <div className="col-12 form-group mb-0">
                                                                        <CustomSelect
                                                                            label={`Select country`}
                                                                            value={
                                                                                cardDetails.cardCountry
                                                                            }
                                                                            onChange={onTextChange}
                                                                            name="cardCountry"
                                                                            error={
                                                                                error.cardCountry ||
                                                                                ''
                                                                            }
                                                                            helperText={
                                                                                error.cardCountry ||
                                                                                ''
                                                                            }
                                                                        >
                                                                            <MenuItem value="">
                                                                                Select Country
                                                                            </MenuItem>
                                                                            {allCountries.map(
                                                                                (
                                                                                    country,
                                                                                    index,
                                                                                ) => (
                                                                                    <MenuItem
                                                                                        key={index}
                                                                                        value={
                                                                                            country.value
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            country.show
                                                                                        }
                                                                                    </MenuItem>
                                                                                ),
                                                                            )}
                                                                        </CustomSelect>
                                                                    </div>
                                                                    <div className="col-12 form-group mb-0">
                                                                        <CustomSelect
                                                                            label={`Select State`}
                                                                            value={
                                                                                cardDetails.cardState
                                                                            }
                                                                            onChange={onTextChange}
                                                                            name="cardState"
                                                                            error={
                                                                                error.cardState ||
                                                                                ''
                                                                            }
                                                                            helperText={
                                                                                error.cardState ||
                                                                                ''
                                                                            }
                                                                        >
                                                                            <option value="">
                                                                                Select state
                                                                            </option>
                                                                            {stateList.map(
                                                                                (country) => (
                                                                                    <option
                                                                                        value={
                                                                                            country.show
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            country.show
                                                                                        }
                                                                                    </option>
                                                                                ),
                                                                            )}
                                                                        </CustomSelect>
                                                                    </div>
                                                                    <div className="col-12 form-group mb-0">
                                                                        <CustomInput
                                                                            title={'Card City'}
                                                                            name="cardCity"
                                                                            type="text"
                                                                            placeholder="Enter card city"
                                                                            onChange={onTextChange}
                                                                            value={
                                                                                cardDetails.cardCity ||
                                                                                ''
                                                                            }
                                                                            helperText={
                                                                                error.cardCity || ''
                                                                            }
                                                                            error={
                                                                                error.cardCity || ''
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-12 form-group mb-0">
                                                                        <CustomInput
                                                                            title={`Card Zip Code`}
                                                                            name="cardZip"
                                                                            type="text"
                                                                            className="zipClass"
                                                                            placeholder={`Enter card zipcode`}
                                                                            onChange={onTextChange}
                                                                            value={
                                                                                cardDetails.cardZip ||
                                                                                ''
                                                                            }
                                                                            helperText={
                                                                                error.cardZip || ''
                                                                            }
                                                                            error={
                                                                                error.cardZip || ''
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                                    <div className="">
                                                        <CheckBox
                                                            onchange={onCheckBoxChange}
                                                            checked={saveCard}
                                                            value={saveCard}
                                                            label="Save Card"
                                                        />
                                                    </div>
                                                </>
                                            ) : null}
                                            <PrimaryButton
                                                label={
                                                    loading
                                                        ? 'Loading...'
                                                        : `Pay ${currencyFormat(
                                                              invoiceData?.total_invoice,
                                                              invoiceData?.currency,
                                                          )}`
                                                }
                                                type="button"
                                                onClick={handleSubmit}
                                                disabled={loading}
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Popup open={popup} modaltitle="Quote" handleClose={() => setPopup(false)}>
                <div>
                    All packing and shipping costs will be provided upon request - please email
                    <a rel="noreferrer" href="mailto:contact@shipcycleauctions.com" target="_blank">
                        {' '}
                        contact@shipcycleauctions.com{' '}
                    </a>{' '}
                    to get a quote.
                </div>
            </Popup>
            <Popup
                open={shippingPopup}
                modaltitle="Shipping Address"
                handleClose={() => setShippingPopup(false)}
            >
                <form
                    className="row addressForm"
                    onSubmit={editShippingAddressFormik.handleSubmit}
                    action=""
                >
                    {Object.values(mapData(editShippingAddress))}
                    <div className="col-12 addrActBtn d-flex justify-content-center">
                        <SecondaryButton
                            onClick={() => {
                                setShippingPopup(false)
                                editShippingAddressFormik.resetForm()
                            }}
                            label={phrase.cancel}
                        />
                        <PrimaryButton type="submit" label={phrase.save} />
                    </div>
                </form>
            </Popup>
            <Popup
                open={billingPopup}
                modaltitle="Billing Address"
                handleClose={() => setBillingPopup(false)}
            >
                <form
                    className="row addressForm"
                    onSubmit={editAddressFormik.handleSubmit}
                    action=""
                >
                    {Object.values(mapData(editBillingAddress))}
                    <div className="col-12 addrActBtn d-flex justify-content-center">
                        <SecondaryButton
                            onClick={() => {
                                setBillingPopup(false)
                                editAddressFormik.resetForm()
                            }}
                            label={phrase.cancel}
                        />
                        <PrimaryButton type="submit" label={phrase.save} />
                    </div>
                </form>
            </Popup>
        </>
    )
}

export default Checkout
