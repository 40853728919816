import { Button, Divider, Menu, MenuItem } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import CheckBox from '../../components/atoms/CheckBox'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import UserContext from '../../context/user/userContext'
import CommonContext from '../../context/common/commonContext'
import { useFormik } from 'formik'
import { mapData } from '../../common/components'
import * as Yup from 'yup'
import csc from 'country-state-city'
import lookup from 'country-code-lookup'
import AlertContext from '../../context/alert/alertContext'
import './MyAccount.css'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import authContext from '../../context/auth/authContext'

function MyAccount() {
    let {
        getUserData,
        userData,
        shippingAddressData,
        userNotifications,
        updateUserNumber,
        updateBillingAddress,
        updateUserLanguage,
        updateUserPassword,
        checkOldPassword,
        updateUserNotifications,
        updateShippingAddress,
    } = useContext(UserContext)
    let { language, getLangPhrase, phrase, allCountries, changeLanguage } =
        useContext(CommonContext)
    const { setAlert } = useContext(AlertContext)
    const { loadUser } = useContext(authContext)
    const [isEditPhoneNumberClicked, setEditPhoneNumberClick] = useState(false)
    const [isEditOfficeNumberClicked, setEditOfficeNumberClick] = useState(false)
    const [isEditAddressClicked, setEditAddressClick] = useState(false)
    const [isEditShippingAddressClicked, setEditShippingAddressClick] = useState(false)
    const [anchorLang, setAnchorLang] = useState(null)
    const [notificationState, setNotificationState] = useState({
        watch_notify: false,
        watch_notify_sms: false,
        bid_notify: false,
        bid_notify_sms: false,
        win_notify: false,
        win_notify_sms: false,
        prefer: false,
        prefer_sms: false,
        endsoon_notify: false,
        endsoon_notify_sms: false,
        wsoon_notify: false,
        wsoon_notify_sms: false,
    })
    const [addressInitialValues, setAddressInitialValues] = useState({
        first_name: '',
        last_name: '',
        company_name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    })
    const [shippingAddressInitialValues, setShippingAddressInitialValues] = useState({
        first_name: '',
        last_name: '',
        company_name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        shipid: '',
    })

    let languageKey = 'english'
    switch (language) {
        case 'English':
            languageKey = 'english'
            break
        case 'Mandarin':
            languageKey = 'mandarin'
            break
        case 'French':
            languageKey = 'french'
            break
        case 'Spanish':
            languageKey = 'spanish'
            break
        case 'Portugese':
            languageKey = 'portugese'
            break
        case 'BrazilPortugese':
            languageKey = 'brazil_portugese'
            break
        case 'English':
            languageKey = 'german'
            break
    }

    useEffect(async () => {
        await getUserData()
        getLangPhrase(language)
        document.title = global.site_title + ' | My Account'
    }, [language])

    useEffect(() => {
        setNotificationState({
            ...notificationState,
            bid_notify: userNotifications.bid_notify && userNotifications.bid_notify.length > 0,
            bid_notify_sms:
                userNotifications.bid_notify_sms && userNotifications.bid_notify_sms.length > 0,
            win_notify: userNotifications.win_notify && userNotifications.win_notify.length > 0,
            win_notify_sms:
                userNotifications.win_notify_sms && userNotifications.win_notify_sms.length > 0,
            prefer: userNotifications.prefer && userNotifications.prefer.length > 0,
            prefer_sms: userNotifications.prefer_sms && userNotifications.prefer_sms.length > 0,
            endsoon_notify:
                userNotifications.endsoon_notify && userNotifications.endsoon_notify.length > 0,
            endsoon_notify_sms:
                userNotifications.endsoon_notify_sms &&
                userNotifications.endsoon_notify_sms.length > 0,
            wsoon_notify:
                userNotifications.wsoon_notify && userNotifications.wsoon_notify.length > 0,
            wsoon_notify_sms:
                userNotifications.wsoon_notify_sms && userNotifications.wsoon_notify_sms.length > 0,
            watch_notify:
                userNotifications.watch_notify && userNotifications.watch_notify.length > 0,
            watch_notify_sms:
                userNotifications.watch_notify_sms && userNotifications.watch_notify_sms.length > 0,
        })
    }, [userNotifications])

    const phoneNumberFormik = useFormik({
        initialValues: {
            phone: userData.phone,
        },
        onSubmit: async (values) => {
            let payload = {}
            payload['p_val'] = values['phone']
            payload['type'] = 'phone'

            updateUserNumber(payload)
            setEditPhoneNumberClick(false)
        },
        enableReinitialize: true,
    })

    const officeNumberFormik = useFormik({
        initialValues: {
            office: userData.landline,
        },
        onSubmit: async (values) => {
            let payload = {}
            payload['p_val'] = values['office']
            payload['type'] = 'landline'

            updateUserNumber(payload)
            setEditOfficeNumberClick(false)
        },
        enableReinitialize: true,
    })

    const addressValidationArray = Yup.object({
        // company_name: Yup.string().required('Required Field'),
        address: Yup.string().required('Required Field'),
        first_name: Yup.string().required('Required Field'),
        last_name: Yup.string().required('Required Field'),
        city: Yup.string().required('Required Field'),
        state: Yup.string().required('Required Field'),
        country: Yup.string().required('Required Field'),
        zip: Yup.string()
            .matches(/^[a-zA-Z0-9 ]*$/g, `${phrase.invalid_format} ${phrase.zip}`)
            .min(5, `${phrase.invalid_format} ${phrase.zip}`)
            .max(8, `${phrase.invalid_format} ${phrase.zip}`)
            .test('checkMin', `${phrase.invalid_format} ${phrase.zip}`, (value) => {
                let zip = parseInt(value)
                if (zip < 0) return false
                return true
            }),
    })

    const passwordValidationArray = Yup.object({
        old_password: Yup.string().required(`${phrase.enter} ${phrase.old_password}`),
        new_password: Yup.string()
            .min(8, 'Min 8 Characters')
            .required(`${phrase.enter} ${phrase.new_password}`),
        confirm_password: Yup.string()
            .required(`${phrase.enter} ${phrase.confirm_password}`)
            .when('new_password', {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref('new_password')], 'Password Mismatch'),
            }),
    })

    const changePasswordFormik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            confirm_password: '',
        },
        validationSchema: passwordValidationArray,
        onSubmit: async (values) => {
            let checkPasswordPayload = {}
            checkPasswordPayload['userid'] = userData.id
            checkPasswordPayload['password'] = values['old_password']
            checkPasswordPayload['username'] = userData.email

            let changePasswordPayload = {}
            changePasswordPayload['new_password1'] = values['new_password']
            changePasswordPayload['email_encry'] = userData.email

            const oldPasswordMatch = await checkOldPassword(checkPasswordPayload)
            if (oldPasswordMatch) {
                const passwordChanged = await updateUserPassword(changePasswordPayload)
                if (passwordChanged) {
                    setAlert(phrase.password_successfully_changed, 'success')
                }
            } else {
                setAlert('Old Password Mismatch', 'error')
            }

            changePasswordFormik.resetForm()
        },
    })

    const editPhoneNumber = [
        {
            type: 'countrySelect',
            name: 'phone',
            defaultCountry: 'us',
            class: 'col-8',
            regions: 'america',
            autoFocus: true,
            formik: phoneNumberFormik,
        },
    ]

    const editOfficeNumber = [
        {
            type: 'countrySelect',
            name: 'office',
            defaultCountry: 'us',
            class: 'col-8',
            regions: 'america',
            autoFocus: true,
            formik: officeNumberFormik,
        },
    ]

    //-------------------------------------------------------------------------------------------

    const switchLanguage = (e, lang) => {
        if (language != lang) {
            updateUserLanguage(lang)
            changeLanguage(lang)
            handleMenuClose()
        }
    }

    const handleMenuClose = () => {
        setAnchorLang(null)
    }

    //--------------------------------------------------------------------------------------------

    const editAddressFormik = useFormik({
        initialValues: addressInitialValues,
        validationSchema: addressValidationArray,
        onSubmit: async (values) => {
            let payload = {}
            let { name: countryName } = csc.getCountryById(values['country'])
            let { name: stateName } = csc.getStateById(values['state'])
            payload['billing_firstname'] = values['first_name']
            payload['billing_lastname'] = values['last_name']
            payload['billing_companyname'] = values['company_name']
            payload['billing_address'] = values['address']
            payload['billing_city'] = values['city']
            payload['billing_state'] = stateName
            payload['billing_country'] = countryName
            payload['billing_zipcode'] = values['zip']

            let addressUpdated = await updateBillingAddress(payload)
            if (addressUpdated) {
                await getUserData()
                await loadUser()
                setEditAddressClick(false)
                editAddressFormik.resetForm()
            }
        },
        enableReinitialize: true,
    })

    const editShippingAddressFormik = useFormik({
        initialValues: shippingAddressInitialValues,
        validationSchema: addressValidationArray,
        onSubmit: async (values) => {
            let payload = {}
            let { name: countryName } = csc.getCountryById(values['country'])
            let { name: stateName } = csc.getStateById(values['state'])
            payload['billing_firstname'] = values['first_name']
            payload['billing_lastname'] = values['last_name']
            payload['billing_companyname'] = values['company_name']
            payload['billing_address'] = values['address']
            payload['billing_city'] = values['city']
            payload['billing_state'] = stateName
            payload['billing_country'] = countryName
            payload['billing_zipcode'] = values['zip']

            if (shippingAddressData) {
                payload['shipid'] = values['shipid']
            }
            let addressUpdated = await updateShippingAddress(payload)
            if (addressUpdated) {
                await getUserData()
                setEditShippingAddressClick(false)
                editShippingAddressFormik.resetForm()
            }
        },
        enableReinitialize: true,
    })

    const [statesinLocal, setStatesinLocal] = useState([])
    useEffect(() => {
        if (statesinLocal.length > 0) {
            let stateId = statesinLocal.findIndex((element) => element.show == userData.state)
            if (lookup.byCountry(userData.country) != null) {
                let { id: countryId } = csc.getCountryByCode(
                    lookup.byCountry(userData.country).iso2,
                )
                setAddressInitialValues({
                    ...addressInitialValues,
                    country: countryId,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    company_name: userData.companyname,
                    address: userData.address1,
                    city: userData.city,
                    zip: userData.zip,
                    state: statesinLocal[stateId] && statesinLocal[stateId].value,
                })
            }
            let shipstateId = statesinLocal.findIndex(
                (element) => element.show == shippingAddressData.state,
            )
            if (lookup.byCountry(shippingAddressData.country) != null) {
                let { id: countryId } = csc.getCountryByCode(
                    lookup.byCountry(shippingAddressData.country).iso2,
                )
                setShippingAddressInitialValues({
                    ...shippingAddressInitialValues,
                    country: countryId,
                    first_name: shippingAddressData.first_name,
                    last_name: shippingAddressData.last_name,
                    company_name: shippingAddressData.companyname,
                    address: shippingAddressData.address,
                    city: shippingAddressData.city,
                    zip: shippingAddressData.zipcode,
                    shipid: shippingAddressData.id,
                    state: statesinLocal[shipstateId] && statesinLocal[shipstateId].value,
                })
            }
        } else {
            if (lookup.byCountry(userData.country) != null) {
                let { id: countryId } = csc.getCountryByCode(
                    lookup.byCountry(userData.country).iso2,
                )
                setAddressInitialValues({
                    ...addressInitialValues,
                    country: countryId,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    company_name: userData.companyname,
                    address: userData.address1,
                    city: userData.city,
                    zip: userData.zip,
                })
            }
            if (lookup.byCountry(shippingAddressData.country) != null) {
                let { id: countryId } = csc.getCountryByCode(
                    lookup.byCountry(shippingAddressData.country).iso2,
                )
                setShippingAddressInitialValues({
                    ...shippingAddressInitialValues,
                    country: countryId,
                    first_name: shippingAddressData.first_name,
                    last_name: shippingAddressData.last_name,
                    company_name: shippingAddressData.companyname,
                    address: shippingAddressData.address,
                    city: shippingAddressData.city,
                    zip: shippingAddressData.zipcode,
                    shipid: shippingAddressData.id,
                })
            }
        }
    }, [userData, shippingAddressData])

    useEffect(() => {
        const { id } = csc.getCountryById(editAddressFormik.values.country)
        let states = csc.getStatesOfCountry(id)
        states = states.map((ele) => {
            let o = {
                show: ele.name,
                value: ele.id,
            }
            return o
        })
        setStatesinLocal(states)
        let stateIndex = states.findIndex((element) => element.show == userData.state)
        if (stateIndex != -1) {
            setAddressInitialValues({
                ...addressInitialValues,
                state: states[stateIndex] && states[stateIndex].value,
            })
        }
    }, [editAddressFormik.values.country])

    useEffect(() => {
        const { id } = csc.getCountryById(editShippingAddressFormik.values.country)
        let states = csc.getStatesOfCountry(id)
        states = states.map((ele) => {
            let o = {
                show: ele.name,
                value: ele.id,
            }
            return o
        })
        setStatesinLocal(states)
        let stateIndex = states.findIndex((element) => element.show == shippingAddressData.state)
        if (stateIndex != -1) {
            setShippingAddressInitialValues({
                ...shippingAddressInitialValues,
                state: states[stateIndex] && states[stateIndex].value,
            })
        }
    }, [editShippingAddressFormik.values.country])

    const editBillingAddress = [
        {
            label: phrase.first_name,
            name: 'first_name',
            type: 'text',
            class: 'col-6',
            autoFocus: true,
            formik: editAddressFormik,
        },
        {
            label: phrase.last_name,
            name: 'last_name',
            type: 'text',
            class: 'col-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.company_name,
            name: 'company_name',
            type: 'text',
            class: 'col-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.company_id,
            name: 'company_id',
            type: 'text',
            class: 'col-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.billing_address,
            name: 'address',
            type: 'text',
            class: 'col-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.country,
            name: 'country',
            type: 'select',
            options: allCountries,
            class: 'col-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.city,
            name: 'city',
            type: 'text',
            class: 'col-4',
            formik: editAddressFormik,
        },
        {
            label: phrase.state,
            name: 'state',
            type: 'select',
            options: statesinLocal,
            class: 'col-4',
            formik: editAddressFormik,
        },
        {
            label: phrase.zip,
            name: 'zip',
            type: 'text',
            class: 'col-4',
            formik: editAddressFormik,
        },
    ]
    const editShippingAddress = [
        {
            label: phrase.first_name,
            name: 'first_name',
            type: 'text',
            class: 'col-6',
            autoFocus: true,
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.last_name,
            name: 'last_name',
            type: 'text',
            class: 'col-6',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.company_name,
            name: 'company_name',
            type: 'text',
            class: 'col-6',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.company_id,
            name: 'company_id',
            type: 'text',
            class: 'col-6',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.shipping_address,
            name: 'address',
            type: 'text',
            class: 'col-6',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.country,
            name: 'country',
            type: 'select',
            options: allCountries,
            class: 'col-6',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.city,
            name: 'city',
            type: 'text',
            class: 'col-4',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.state,
            name: 'state',
            type: 'select',
            options: statesinLocal,
            class: 'col-4',
            formik: editShippingAddressFormik,
        },
        {
            label: phrase.zip,
            name: 'zip',
            type: 'text',
            class: 'col-4',
            formik: editShippingAddressFormik,
        },
    ]

    const changePassword = [
        {
            label: phrase.old_password,
            name: 'old_password',
            type: 'password',
            class: 'col-12',
            formik: changePasswordFormik,
        },
        {
            label: phrase.new_password,
            name: 'new_password',
            type: 'password',
            class: 'col-12',
            formik: changePasswordFormik,
        },
        {
            label: phrase.confirm_password,
            name: 'confirm_password',
            type: 'password',
            class: 'col-12',
            formik: changePasswordFormik,
        },
    ]

    const notificationList = [
        {
            label: 'Item Matching My Wishlist Is Listed For Auction',
            email: 'watch_notify',
            sms: 'watch_notify_sms',
        },
        { label: 'When I Bid On An Item', email: 'bid_notify', sms: 'bid_notify_sms' },
        { label: 'When I Win An Auction', email: 'win_notify', sms: 'win_notify_sms' },
        {
            label: 'When An Item Of My Preferred Category Is Posted',
            email: 'prefer',
            sms: 'prefer_sms',
        },
        {
            label: 'Auction Ending Soon',
            email: 'endsoon_notify',
            sms: 'endsoon_notify_sms',
        },
        {
            label: 'Watchlist Ending Soon',
            email: 'wsoon_notify',
            sms: 'wsoon_notify_sms',
        },
    ]

    const notificationSubmitHandler = async (event) => {
        event.preventDefault()
        event.stopPropagation()

        let payloadFormData = new FormData()
        payloadFormData.append('user_id', userData.id)

        notificationState.watch_notify ? payloadFormData.append('user_notify', 1) : ''
        notificationState.watch_notify_sms ? payloadFormData.append('user_notify_sms', 1) : ''
        notificationState.bid_notify ? payloadFormData.append('user_notify', 2) : ''
        notificationState.bid_notify_sms ? payloadFormData.append('user_notify_sms', 2) : ''
        notificationState.win_notify ? payloadFormData.append('user_notify', 4) : ''
        notificationState.win_notify_sms ? payloadFormData.append('user_notify_sms', 4) : ''
        notificationState.prefer ? payloadFormData.append('user_notify', 5) : ''
        notificationState.prefer_sms ? payloadFormData.append('user_notify_sms', 5) : ''
        notificationState.endsoon_notify ? payloadFormData.append('user_notify', 6) : ''
        notificationState.endsoon_notify_sms ? payloadFormData.append('user_notify_sms', 6) : ''
        notificationState.wsoon_notify ? payloadFormData.append('user_notify', 7) : ''
        notificationState.wsoon_notify_sms ? payloadFormData.append('user_notify_sms', 7) : ''

        const notificationUpdated = await updateUserNotifications(payloadFormData)
        if (notificationUpdated) {
            setAlert('Notifications Updated Successfully', 'success')
        }
    }

    const checkboxChangeHandler = (event) => {
        let toChange = event.target.value
        if (notificationState[toChange]) {
            setNotificationState({
                ...notificationState,
                [toChange]: false,
            })
        } else {
            setNotificationState({
                ...notificationState,
                [toChange]: true,
            })
        }
    }
    console.log(userData, '++++++++++++++++++')
    return (
        <div className="myAccount">
            <h3 className="maHeading">{phrase.my_account}</h3>
            <div className="myAccountCnt container">
                <h4 className="maTitle">{phrase.my_information}</h4>
                <div className="row myinfo">
                    <div className="col-md-6 col-12">
                        <ul>
                            <li>
                                <label>{phrase.username}:</label>
                                <span>{userData.username}</span>
                            </li>
                            <li>
                                <label>{phrase.phone_number}:</label>
                                <div className="d-flex align-items-center phoneEdit">
                                    {isEditPhoneNumberClicked ? (
                                        <form
                                            action=""
                                            onSubmit={phoneNumberFormik.handleSubmit}
                                            className="row"
                                        >
                                            {Object.values(mapData(editPhoneNumber))}
                                            <Button
                                                onClick={() => {
                                                    setEditPhoneNumberClick(false)
                                                }}
                                            >
                                                <span className="material-icons">close</span>
                                            </Button>
                                            <Button type="submit" className=".editphone">
                                                <span className="material-icons">done</span>
                                            </Button>
                                        </form>
                                    ) : (
                                        <>
                                            <span>
                                                {userData.phone === '' ? '(Empty)' : userData.phone}
                                            </span>
                                            <Button
                                                onClick={() => {
                                                    setEditPhoneNumberClick(true)
                                                }}
                                            >
                                                <span className="material-icons">edit</span>
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </li>
                            {/* <li>
                                <label>Account #1:</label>
                                <span>{userData.iff_num}</span>
                            </li>
                            <li>
                                <label>Account #3:</label>
                                <span>{userData.ifs_imm}</span>
                            </li> */}
                        </ul>
                    </div>
                    <div className="col-md-6 col-12">
                        <ul>
                            <li>
                                <label>{phrase.email}: </label>
                                <span>{userData.email}</span>
                            </li>
                            {/* <li>
                                <label>{phrase.landline_number}: </label>
                                <div className="d-flex align-items-center phoneEdit">
                                    {isEditOfficeNumberClicked ? (
                                        <form
                                            action=""
                                            onSubmit={officeNumberFormik.handleSubmit}
                                            className="row"
                                        >
                                            {Object.values(mapData(editOfficeNumber))}
                                            <Button
                                                onClick={() => {
                                                    setEditOfficeNumberClick(false)
                                                }}
                                            >
                                                <span className="material-icons">close</span>
                                            </Button>
                                            <Button type="submit">
                                                <span className="material-icons">done</span>
                                            </Button>
                                        </form>
                                    ) : (
                                        <>
                                            <span>{userData.landline}</span>
                                            <Button
                                                onClick={() => {
                                                    setEditOfficeNumberClick(true)
                                                }}
                                            >
                                                <span className="material-icons">edit</span>
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </li> */}
                            {/* <li>
                                <label>Account #2:</label>
                                <span>{userData.ifs_impacc}</span>
                            </li>
                            <li>
                                <label>Account #4:</label>
                                <span>{userData.ifs_rl}</span>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <Divider className="my-4" />
                <h4 className="maTitle">{phrase.language}</h4>
                <div className="row">
                    <div className="col-md-6 offset-md-3 col-12 maLang">
                        <Button
                            aria-controls="languageMenu"
                            aria-haspopup="true"
                            variant="outlined"
                            onClick={(e) => setAnchorLang(e.currentTarget)}
                        >
                            {phrase[languageKey]}
                            <span className="material-icons">arrow_drop_down</span>
                        </Button>
                        <Menu
                            id="languageMenu"
                            anchorEl={anchorLang}
                            keepMounted
                            open={Boolean(anchorLang)}
                            value={language}
                            onClose={() => setAnchorLang(null)}
                        >
                            <MenuItem
                                value="English"
                                onClick={(event) => switchLanguage(event, 'English')}
                            >
                                {phrase.english}
                            </MenuItem>
                            <MenuItem
                                value="Mandarin"
                                onClick={(event) => switchLanguage(event, 'Mandarin')}
                            >
                                {phrase.mandarin}
                            </MenuItem>
                            <MenuItem
                                value="French"
                                onClick={(event) => switchLanguage(event, 'French')}
                            >
                                {phrase.french}
                            </MenuItem>
                            <MenuItem
                                value="Spanish"
                                onClick={(event) => switchLanguage(event, 'Spanish')}
                            >
                                {phrase.spanish}
                            </MenuItem>
                            <MenuItem
                                value="Portugese"
                                onClick={(event) => switchLanguage(event, 'Portugese')}
                            >
                                {phrase.portugese}
                            </MenuItem>
                            <MenuItem
                                value="BrazilPortugese"
                                onClick={(event) => switchLanguage(event, 'BrazilPortugese')}
                            >
                                {phrase.brazil_portugese}
                            </MenuItem>
                            <MenuItem
                                value="German"
                                onClick={(event) => switchLanguage(event, 'German')}
                            >
                                {phrase.german}
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                <Divider className="my-4" />
                <div className="maContainer">
                    <div>
                        {isEditShippingAddressClicked ? (
                            ''
                        ) : (
                            <h4 className="maTitle">
                                {phrase.billing_information}
                                {isEditAddressClicked ? (
                                    ' '
                                ) : (
                                    <Button
                                        onClick={() => {
                                            setEditAddressClick(true)
                                            console.log('Initial Values are', addressInitialValues)
                                        }}
                                    >
                                        <span className="material-icons">edit</span>
                                    </Button>
                                )}
                            </h4>
                        )}
                        <div>
                            {isEditAddressClicked ? (
                                <form
                                    className="row addressForm"
                                    onSubmit={editAddressFormik.handleSubmit}
                                    action=""
                                >
                                    {Object.values(mapData(editBillingAddress))}
                                    <div className="col-12 addrActBtn d-flex justify-content-center">
                                        <SecondaryButton
                                            onClick={() => {
                                                setEditAddressClick(false)
                                                editAddressFormik.resetForm()
                                            }}
                                            label={phrase.cancel}
                                        />
                                        <PrimaryButton type="submit" label={phrase.save} />
                                    </div>
                                </form>
                            ) : isEditShippingAddressClicked ? (
                                ''
                            ) : (
                                <div className="maAddress">
                                    {Object.keys(userData).length > 0 ? (
                                        <>
                                            <h5>
                                                {userData.first_name + ' ' + userData.last_name}
                                            </h5>
                                            <address>
                                                {userData.companyname}
                                                <br />
                                                {userData.address1}
                                                <br />
                                                {userData.city
                                                    ? userData.city +
                                                      ', ' +
                                                      userData.state +
                                                      ' - ' +
                                                      userData.zip
                                                    : null}
                                                <br />
                                                {userData.country}
                                            </address>{' '}
                                        </>
                                    ) : null}
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        {isEditAddressClicked ? (
                            ''
                        ) : (
                            <h4 className="maTitle">
                                {phrase.shipping_address}
                                {isEditShippingAddressClicked || isEditAddressClicked ? (
                                    ' '
                                ) : (
                                    <Button
                                        onClick={() => {
                                            setEditShippingAddressClick(true)
                                            console.log('Initial Values are', addressInitialValues)
                                        }}
                                    >
                                        <span className="material-icons">edit</span>
                                    </Button>
                                )}
                            </h4>
                        )}
                        <div>
                            {isEditShippingAddressClicked ? (
                                <form
                                    className="row addressForm"
                                    onSubmit={editShippingAddressFormik.handleSubmit}
                                    action=""
                                >
                                    {Object.values(mapData(editShippingAddress))}
                                    <div className="col-12 addrActBtn d-flex justify-content-center">
                                        <SecondaryButton
                                            onClick={() => {
                                                setEditShippingAddressClick(false)
                                                editShippingAddressFormik.resetForm()
                                            }}
                                            label={phrase.cancel}
                                        />
                                        <PrimaryButton type="submit" label={phrase.save} />
                                    </div>
                                </form>
                            ) : isEditAddressClicked ? (
                                ''
                            ) : (
                                <div className="maAddress">
                                    {Object.keys(shippingAddressData).length > 0 ? (
                                        <>
                                            <h5>
                                                {shippingAddressData.first_name +
                                                    ' ' +
                                                    shippingAddressData.last_name}
                                            </h5>
                                            <address>
                                                {shippingAddressData.companyname}
                                                <br />
                                                {shippingAddressData.address}
                                                <br />
                                                {shippingAddressData.city +
                                                    ', ' +
                                                    shippingAddressData.state +
                                                    ' - ' +
                                                    shippingAddressData.zipcode}
                                                <br />
                                                {shippingAddressData.country}
                                            </address>{' '}
                                        </>
                                    ) : null}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Divider className="my-4" />
                <h4 className="maTitle">{phrase.change_password}</h4>
                <div className="row">
                    <div className="col-12 col-md-6 offset-md-3">
                        <form
                            action=""
                            onSubmit={changePasswordFormik.handleSubmit}
                            className="row"
                        >
                            {Object.values(mapData(changePassword))}
                            <div className="col-12">
                                <PrimaryButton label={phrase.change_password} type="submit" />
                            </div>
                        </form>
                    </div>
                </div>
                <Divider className="my-4" />
                <h4 className="maTitle">{phrase.notifications}</h4>
                <form
                    action=""
                    className="row"
                    onSubmit={(e) => {
                        e.preventDefault()
                        notificationSubmitHandler(e)
                    }}
                >
                    <div className="col-12 maNotifications">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">{phrase.notification_type}</th>
                                        <th scope="col">{phrase.email}</th>
                                        {/* <th scope="col">{phrase.sms}</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {notificationList.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.label}</td>
                                            <td>
                                                <CheckBox
                                                    value={data.email}
                                                    checked={
                                                        notificationState[data.email]
                                                            ? 'checked'
                                                            : ''
                                                    }
                                                    onchange={checkboxChangeHandler}
                                                />
                                            </td>
                                            {/* <td>
                                                <CheckBox
                                                    value={data.sms}
                                                    checked={
                                                        notificationState[data.sms] ? 'checked' : ''
                                                    }
                                                    onchange={checkboxChangeHandler}
                                                />
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-12">
                        <PrimaryButton label={phrase.save} type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default MyAccount
