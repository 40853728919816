import React from 'react'
import Slider from 'react-slick'
import PrimaryButton from '../../atoms/PrimaryButton'
import ImageGallery from 'react-image-gallery'
import './Banner.css'

function Banner({ bannerList }) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        adaptiveHeight: true,
        slidesToScroll: 1,
    }

    return (
        <Slider {...settings}>
            {/* <div className="bannerLt"> */}
            {bannerList.length > 0
                ? bannerList.map((banner, index) => {
                      return (
                          <div className="banner">
                              <a href={banner.subtitle}>
                                  <img
                                      key={'banner' + index}
                                      src={global.site_url + '/uploads/banner/' + banner.image}
                                      alt="Banner"
                                  />
                              </a>
                          </div>
                      )
                  })
                : null}

            {/* <h1>New Arrivals</h1>
                <h5>
                    Exclusively serving the IT channel, Procurri is the world&apos;s largest
                    independent distributor of Post-Warranty Maintenance, ITAD, and OEM Recertified
                    Hardware enabling our partners to create value for clients at ALL stages of the
                    IT lifecycle.
                </h5>
                <PrimaryButton label="Shop Now" /> */}
            {/* </div> */}
            {/* <div className="bannerRt">
                <img src="./assets/images/banner.jpg" alt="Banner" />
            </div> */}
            {/* <Slider {...settings}>
                <a href="#">    
                    <img src="./assets/images/banner.jpg" alt="Ads banner" />
                </a>
                <a href="#">
                    <img src="./assets/images/banner.jpg" alt="Ads banner" />
                </a>
                <a href="#">
                    <img src="./assets/images/banner.jpg" alt="Ads banner" />
                </a>
            </Slider> */}
        </Slider>
    )
}

export default Banner
