import React, { useState, useRef, useEffect, useContext } from 'react'
import { Button, Drawer } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import SecondaryButton from '../../atoms/SecondaryButton'
import './ProductCard.css'
import FavouriteCheckbox from '../../atoms/FavoriteCheckbox'
import Popup from '../../organisms/Popup'
import Timer from '../../../common/timer'
import AddDispute from './AddDispute'
import Bidding from '../../molecules/Bidding/BiddingItem'
import { dateFormatFront, titleUrlFormat } from '../../../common/components'
import { handleRedirectInternal, currencyFormat } from '../../../common/components'
import CommonContext from '../../../context/common/commonContext'
import AuthContext from '../../../context/auth/authContext'
import { Link } from 'react-router-dom'
import CheckoutImg from '../../../assets/svg/checkout.svg'
import ProductView from '../../../views/ProductView'
const ListView = (props) => {
    const [addCard, setAddCard] = React.useState(false)
    const product = props.data
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)

    const { isAuthenticated, user } = authContext
    const { setBidHistoryValue, phrase } = commonContext
    const [disputePopup, setDisputePopup] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)
    const toggleProductDrawer = () => {
        setOpenDrawer(!openDrawer)
    }
    const history = useHistory()

    const openDisputePopup = () => {
        setDisputePopup(true)
    }
    const closeDisputePopup = () => {
        setDisputePopup(false)
    }
    let dashboardLink = null
    if (props.dashboard && product) {
        if (props.dashboardType == 'won' || props.dashboardType == 'buy_now') {
            dashboardLink = (
                <div>
                    <div className="mb-1">
                        <Link to={`/invoice/${product.invoice_id}`}>
                            <PrimaryButton label={phrase.view + ' ' + phrase.order} />
                        </Link>
                    </div>
                    <div>
                        <PrimaryButton
                            onClick={() => openDisputePopup()}
                            label={phrase.add + ' ' + phrase.dispute}
                        />
                        <div className="startCheckout mt-1">
                            <Link to="/checkout">
                                <PrimaryButton
                                    label="PAY"
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                    }}
                                />
                            </Link>
                        </div>
                        <AddDispute
                            open={disputePopup}
                            product={product}
                            openDisputePopup={() => openDisputePopup()}
                            closeDisputePopup={() => closeDisputePopup()}
                        />
                    </div>
                </div>
            )
        } else if (props.dashboardType == 'active' || props.dashboardType == 'watchlist') {
            dashboardLink = (
                <Link to={`/product/view/${product.id}/${titleUrlFormat(product.title)}`}>
                    <PrimaryButton label={phrase.view} />
                </Link>
            )
        }
    }

    let categories = ''
    if (product) {
        for (let i in product.multiplecategoryshow) {
            if (i == product.multiplecategoryshow.length - 1) {
                // last index
                //categories += product.multiplecategoryshow[i]
                categories += phrase[product.multiplecategoryshow[i]]
            } else {
                //categories += product.multiplecategoryshow[i] + ', '
                categories += phrase[product.multiplecategoryshow[i]] + ','
            }
        }
    }

    return (
        <>
            <div className="productCardList d-flex justify-content-between align-items-start">
                <div className="psListLt d-flex">
                    <div className="pclImg">
                        <Link onClick={toggleProductDrawer}>
                            <img
                                src={global.site_url + '/uploads/product/' + product.avatar}
                                alt={product.title}
                            />
                        </Link>
                        {props.completed && <h4 className="aucEnded">Auction Ended</h4>}
                        {(props.dashboard &&
                            props.dashboardType != 'watchlist' &&
                            props.dashboardType != 'active') ||
                        props.completed ? null : user.isBuyer ? (
                            <FavouriteCheckbox
                                watchlisted={product.watchlistid}
                                project_id={product.id}
                                isDashboard={props.dashboard}
                            />
                        ) : null}
                        <div className="listBidStatus">
                            {isAuthenticated && (
                                <>
                                    {product.bidtopstatus === 'outbid' && (
                                        <h4 className="productWinningStatus outbid">OUTBID</h4>
                                    )}
                                    {product.bidtopstatus === 'winner' && (
                                        <h4 className="productWinningStatus winning">WINNING</h4>
                                    )}
                                    {product.bidtopstatus === 'won' && (
                                        <h4 className="productWinningStatus won">WON</h4>
                                    )}
                                    {product.bidtopstatus === 'lost' && (
                                        <h4 className="productWinningStatus lost">LOST</h4>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    <div className="listContent ">
                        <h2 className="listProdTitle">
                            <Link onClick={toggleProductDrawer}>{product.title}</Link>
                        </h2>

                        <div className="listLotDetails">
                            <div className="gridLotDetails">
                                <p>
                                    <label>{phrase.condition}</label>
                                    {phrase[product.condition_language] || product.condition_name}
                                </p>
                                <p>
                                    <label> {phrase.no_of_items} </label> {product.qty}{' '}
                                    {phrase.items}
                                </p>
                            </div>
                            <div className="gridLotDetails">
                                {/* <p>
                                    <label> UPC/SKU </label>
                                    {product.upc}
                                </p> */}
                                <p>
                                    <label> {phrase.auction_type} </label>
                                    {product.auctiontype == 'live'
                                        ? phrase.live_auction
                                        : product.auctiontype == 'lineitem'
                                        ? phrase.line_item_auction
                                        : phrase.blind_auction}
                                </p>
                                <p>
                                    <label> {phrase.category} </label>
                                    {categories}
                                </p>
                            </div>
                            {/* <div className="gridLotDetails">
                                <p>
                                    <label> {phrase.lot_size} </label>
                                    {phrase[product.lottype]} {phrase.lot}
                                </p>
                                
                            </div> */}
                            {/* <div className="gridLotDetails">
                                <p>
                                    <label>{phrase.manufacturer}</label>
                                    {product.manufacturer}
                                </p>
                                <p>
                                    <label>Model</label>
                                    {product.modelnumber}
                                </p>
                            </div> */}
                            {/* <div className="gridLotDetails">
                                <p>
                                    <label>Size</label>
                                    {product.size}
                                </p>
                                <p>
                                    <label>{phrase.finish}</label>
                                    {product.finish}
                                </p>
                            </div>
                            <div className="gridLotDetails fr1">
                                <p>
                                    <label> {phrase.location} </label>
                                    {product.city}, {product.state}, {product.country}
                                </p>
                            </div> */}
                            <div className="d-flex justify-content-between flex-wrap">
                                {product.market_status === 'open' && !props.completed ? (
                                    product.auctiontype !== 'lineitem' ? (
                                        <>
                                            {product.auctiontype == 'live' ? (
                                                <div className="listBidPrice d-flex align-items-center">
                                                    <h6>
                                                        {product.latestbid
                                                            ? phrase.current_bid
                                                            : phrase.starting_price}
                                                        :
                                                    </h6>
                                                    <p>
                                                        <span>
                                                            {product.currency_symbol}{' '}
                                                            {product.wprice}
                                                        </span>
                                                    </p>
                                                </div>
                                            ) : null}

                                            <div className="listBidInfo d-flex align-items-center timeListBidInfo">
                                                {product.market_status == 'open' ? (
                                                    <>
                                                        <p className="d-flex align-items-center">
                                                            {product.date_closed ? (
                                                                <Timer
                                                                    date_added={product.date_added}
                                                                    date_closed={
                                                                        product.date_closed
                                                                    }
                                                                    withText={1}
                                                                ></Timer>
                                                            ) : null}
                                                        </p>
                                                    </>
                                                ) : null}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="listBidInfo d-flex align-items-center timeListBidInfo">
                                            {product.market_status == 'open' ? (
                                                <>
                                                    <p style={{ marginRight: '5px' }}>
                                                        {phrase.time_left}:
                                                    </p>
                                                    <p className="d-flex align-items-center">
                                                        {product.date_closed ? (
                                                            <Timer
                                                                date_added={product.date_added}
                                                                date_closed={product.date_closed}
                                                                withText={0}
                                                            ></Timer>
                                                        ) : null}
                                                    </p>
                                                </>
                                            ) : null}
                                        </div>
                                    )
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`listActBtn ${props.completed && 'ended'}`}>
                    {props.dashboard ? (
                        dashboardLink
                    ) : (
                        <Link to={`/product/view/${product.id}/${titleUrlFormat(product.title)}`}>
                            <PrimaryButton label={phrase.view} btnSize="small" />
                        </Link>
                    )}
                </div>
            </div>
            <Drawer
                className="rightDrawer productViewDrawer"
                anchor={'right'}
                open={openDrawer}
                onClose={toggleProductDrawer}
            >
                <div className="position-relative">
                    <Link onClick={toggleProductDrawer} className="closeMe">
                        <span className="material-icons">clear</span>
                    </Link>
                    {/* <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <span>Lot id : {product?.id}</span>
                        </div>
                    </div> */}
                    <div className="prodInfoSlider pt-0">
                        <Link
                            to={`/product/view/${product?.id}/${titleUrlFormat(product?.title)}`}
                            className="viewFullDetails"
                        >
                            View Full Details
                        </Link>
                        <ProductView slider productId={product?.id} />
                    </div>
                </div>
            </Drawer>
        </>
    )
}
export default ListView
