import React, { useContext } from 'react'
import './Header.css'
import { useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../../common/components'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { languageArr } from '../../../Utils'

import CommonContext from '../../../context/common/commonContext'

function UnAuthHeader() {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const history = useHistory()
    const { phrase, language, changeLanguage } = useContext(CommonContext)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setAnchorEl(null)
        if (history && path && path !== 'backdropClick') {
            console.log('history', history, path)
            handleRedirectInternal(history, path)
        }
    }

    const switchLanguage = (e, lang) => {
        if (language != lang) {
            changeLanguage(lang)
            handleClose()
        }
    }
    let languageKey = 'english'
    switch (language) {
        case 'English':
            languageKey = 'english'
            break
        case 'Mandarin':
            languageKey = 'mandarin'
            break
        case 'French':
            languageKey = 'french'
            break
        case 'Spanish':
            languageKey = 'spanish'
            break
        case 'Portugese':
            languageKey = 'portugese'
            break
        case 'BrazilPortugese':
            languageKey = 'brazil_portugese'
            break
        case 'German':
            languageKey = 'german'
            break
    }

    return (
        <div className="unAuthHeader container d-flex justify-content-end">
            <Button
                variant="outlined"
                aria-controls="simple-menu"
                aria-haspopup="true"
                size="large"
                onClick={handleClick}
            >
                {phrase[languageKey]} <span className="material-icons">arrow_drop_down</span>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                value={language}
                onClose={handleClose}
            >
                <MenuItem value="English" onClick={(event) => switchLanguage(event, 'English')}>
                    {phrase.english}
                </MenuItem>
                <MenuItem value="Mandarin" onClick={(event) => switchLanguage(event, 'Mandarin')}>
                    {phrase.mandarin}
                </MenuItem>
                <MenuItem value="French" onClick={(event) => switchLanguage(event, 'French')}>
                    {phrase.french}
                </MenuItem>
                <MenuItem value="Spanish" onClick={(event) => switchLanguage(event, 'Spanish')}>
                    {phrase.spanish}
                </MenuItem>
                <MenuItem value="Portugese" onClick={(event) => switchLanguage(event, 'Portugese')}>
                    {phrase.portugese}
                </MenuItem>
                <MenuItem
                    value="BrazilPortugese"
                    onClick={(event) => switchLanguage(event, 'BrazilPortugese')}
                >
                    {phrase.brazil_portugese}
                </MenuItem>
                <MenuItem value="German" onClick={(event) => switchLanguage(event, 'German')}>
                    {phrase.german}
                </MenuItem>
            </Menu>
        </div>
    )
}

export default UnAuthHeader
