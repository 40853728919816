import React, { useState, useContext, useEffect } from 'react'
import { Button, ListItem } from '@material-ui/core'
import { Link, NavLink } from 'react-router-dom'
import './Footer.css'
import AutopltContext from '../../../context/autopilot/autopltContext'
import CommonContext from '../../../context/common/commonContext'
import AuthContext from '../../../context/auth/authContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData } from '../../../common/components'
import { logo, siteName } from '../../../Utils'
import { apiCall } from '../../../common/api'
import facebook from '../../../assets/images/facebook.svg'
import instagram from '../../../assets/images/instagram.svg'
import twitter from '../../../assets/images/twitter.svg'

const Footer = () => {
    let { getLangPhrase, language, phrase } = useContext(CommonContext)
    const autopltContext = useContext(AutopltContext)

    const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext
    const { isAuthenticated } = useContext(AuthContext)

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format'),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            let subsribeFormDate = new FormData()
            if (values['email'] != '') {
                subsribeFormDate.append('subscribe_email', values['email'])
                subsribeFormDate.append('site_id', global.site_id)
                const res = apiCall('post', '', subsribeFormDate, '', 'hubspot-save')
                console.log('Result is____________', res)
            }
            //addCustomer(values)
            formik.values.email = ''
        },
    })

    const subscribe = [
        {
            label: 'Email',
            placeholder: 'Enter your email',
            class: 'subsInp',
            type: 'text',
            variant: 'filled',
            name: 'email',
            formik: formik,
            size: 'small',
        },
    ]

    useEffect(() => {
        getLangPhrase(language)
    }, [language])

    useEffect(() => {
        if (responseStatusAutoPlt) {
            if (responseStatusAutoPlt.from === 'addCustomer') {
                if (responseStatusAutoPlt.status === 'success') {
                    formik.values.email = ''
                }
            } else {
                console.log('Subscription Error', responseStatusAutoPlt)
            }
        }
    }, [responseStatusAutoPlt])

    return (
        <footer>
            <div className="footCopyright container-fluid">
                <div className="customContainer d-flex flex-wrap justify-content-center">
                    <div>
                        <ul className="mb-3">
                            <li className="socialImg">
                                {/* <Link to="https://www.facebook.com/profile.php?id=61559505628399"> */}
                                <img
                                    src={facebook}
                                    onClick={() =>
                                        window.open(
                                            'https://www.facebook.com/profile.php?id=61559505628399',
                                            '_blank',
                                        )
                                    }
                                    style={{ cursor: 'pointer' }}
                                />
                                {/* </Link> */}
                            </li>
                            <li className="socialImg">
                                <img src={instagram} />
                            </li>
                            <li className="socialImg">
                                <img src={twitter} />
                            </li>
                        </ul>
                        <p>© {new Date().getFullYear()} powered by Auction.io</p>
                    </div>
                    <ul className="otherLinks">
                        {/* <ListItem>
                            <NavLink to="/how-to">{phrase.how_to}</NavLink>
                        </ListItem> */}
                        <ListItem>
                            <NavLink
                                to="/how-to"
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                }}
                            >
                                FAQ
                                {/* How to */}
                            </NavLink>
                        </ListItem>
                        <ListItem>
                            <NavLink
                                to="/about"
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                }}
                            >
                                {phrase.about}
                            </NavLink>
                        </ListItem>
                        <ListItem>
                            <NavLink
                                to="/support"
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                }}
                            >
                                Support
                            </NavLink>
                        </ListItem>
                        <ListItem>
                            <NavLink to="/support">Contact Us</NavLink>
                        </ListItem>
                        <ListItem>
                            <NavLink
                                to="/terms"
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                }}
                            >
                                {phrase.terms_of_use}
                            </NavLink>
                        </ListItem>
                        <ListItem>
                            <NavLink
                                to="/privacy"
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                }}
                            >
                                {' '}
                                {phrase.privacy_policy}
                            </NavLink>
                        </ListItem>
                    </ul>
                    {/* <a href="https://app.renugo.com/" target="_blank" rel="noreferrer">
                        Powered by Renugo.com
                    </a> */}
                </div>
            </div>
        </footer>
    )
}

export default Footer
