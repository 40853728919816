import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'

// Components
import Invoice from '../../components/molecules/Invoice/'

// Common Function
import { apiCall } from '../../common/api'
import { handleRedirectInternal } from '../../common/components'

//context
import AuthContext from '../../context/auth/authContext'

// css
import './invoice.css'
import Loader from '../../components/molecules/Loader'

const ViewInvoice = (props) => {
    const history = useHistory()
    // state
    const [invoiceData, setInvoiceData] = useState({})
    const [shipping, setShippingData] = useState({})
    const { user } = useContext(AuthContext)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (props.match.params.id) {
            console.log('params id: ', props.match.params.id)
            document.title = `${global.site_title} | Invoice`
            getInvoiceData(props.match.params.id)
        }
    }, [])

    const calculateShipping = async (body) => {
        const res = await apiCall('post', ``, body, '', 'getShippingAmount')
        if (res.data.status) {
            return parseFloat(res.data.shipping_amount)
        } else {
            return 0
        }
    }
    const getInvoiceData = (id) => {
        apiCall('get', `invoice/${id}`, '', '', 'product')
            .then(async (results) => {
                let { data } = results
                console.log('GOT INVOICE DATA: ', data)
                if (data.status == 'success' && user.id == data.data.invoicedata.buyeruser_id) {
                    console.log('INVOICE: ', data.data.invoicedata)
                    var temp_shipping = {}
                    if (data.data.shipping?.length > 0) {
                        temp_shipping = data.data.shipping[0]
                    }
                    setShippingData(temp_shipping)
                    data.data.invoicedata.lineitem = data.data.invoicelineitem
                    if (data.data.invoicedata.paid == 0)
                        data.data.invoicedata.shipping_amount = await calculateShipping({
                            product_id:
                                data.data.invoicedata.lineproject_id > 0
                                    ? data.data.invoicedata.lineproject_id
                                    : data.data.invoicedata.id,
                            city: temp_shipping?.city,
                            zipcode: temp_shipping?.zipcode,
                            country: temp_shipping?.country,
                        })
                    data.data.invoicedata.total =
                        data.data.invoicedata.amount +
                        data.data.invoicedata.buyers_premium +
                        parseFloat(data.data.invoicedata.sales_tax) +
                        data.data.invoicedata.shipping_amount
                    setInvoiceData(data.data.invoicedata)

                    setLoading(false)
                } else {
                    history.goBack()
                }
            })
            .catch((err) => {
                handleRedirectInternal(history, 'search')
            })
    }

    return (
        <>
            {loading ? (
                <div className="heightFiller">
                    <Loader />
                </div>
            ) : Object.keys(invoiceData).length > 0 ? (
                <>
                    <div className="d-flex justify-content-start topHeader">
                        <Button className="backBtn my-2" onClick={() => history.goBack()}>
                            <span className="material-icons">arrow_back</span>Back
                        </Button>
                    </div>
                    <Invoice data={invoiceData} shippingData={shipping} />
                </>
            ) : (
                <div className="heightFiller"></div>
            )}
        </>
    )
}

export default ViewInvoice
