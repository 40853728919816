import React, { createContext } from 'react'
import { useState } from 'react'

const GlobalStateContext = createContext()

export const GlobalStateProvider = ({ children }) => {
    const [loginPopup, setLoginPopup] = useState(false)
    const [registerPopup, setRegisterPopup] = useState(false)
    const [forgotPopup, setForgotPopup] = useState(false)
    const handleLoginPopup = () => {
        setLoginPopup(!loginPopup)
    }
    const handleRegisterPopup = () => {
        setRegisterPopup(!registerPopup)
    }
    const handleForgotPopup = () => {
        setForgotPopup(!forgotPopup, 'ygyg')
        console.log(forgotPopup)
    }

    return (
        <GlobalStateContext.Provider
            value={{
                loginPopup,
                handleLoginPopup,
                registerPopup,
                handleRegisterPopup,
                forgotPopup,
                handleForgotPopup,
            }}
        >
            {children}
        </GlobalStateContext.Provider>
    )
}

export default GlobalStateContext
