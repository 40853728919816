import React, { useState, useContext, useEffect, useRef } from 'react'
import './Cards.css'

const SavedCards = () => {
    return (
        <>
            <div className="savedCardsCnt">
                <div className="savedCards">
                    <div
                        className="emptyCard d-flex justify-content-between flex-column"
                        // onClick={() => toggleFullScreenCardPopup(true, 'new', null)}
                    >
                        <div>
                            <span>
                                <img src="/assets/svg/emptyCard.svg" />
                            </span>
                            <h2>ADD A NEW CARD</h2>
                        </div>
                    </div>
                    <div className="credCard d-flex justify-content-between flex-column">
                        <div className="d-flex justify-content-between align-items-center">
                            <img src="/assets/images/hologram.png" />
                            {/* <img src="/assets/svg/cardBg.svg" /> */}
                            Hi
                        </div>
                        <div className="credCardNum">
                            <h2 style={{ color: 'black' }}>**** **** **** 1234</h2>
                        </div>
                        <div className="credCardName d-flex justify-content-between align-items-center">
                            <div className="text-left">
                                <span>CARD HOLDER</span>
                                <p>Nitish</p>
                            </div>

                            <div className="text-left">
                                <span>EXPIRES</span>
                                <p>07/23 / 123</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SavedCards
