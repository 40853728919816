import React from 'react'
import { removeHTMLTags } from '../../../common/components'

export const ReadMore = ({ data, limit, setHTML, buttonText, readMoreFunction, ...rest }) => {
    let cleanedData = removeHTMLTags(data)

    return (
        <>
            <p className={`${rest.className}`}>
                {setHTML ? (
                    <>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: cleanedData.substring(0, limit),
                            }}
                        ></span>
                    </>
                ) : (
                    <> {cleanedData.substring(0, limit)}</>
                )}
                {cleanedData.length > limit ? '...' : null}
            </p>
            {cleanedData.length > limit && (
                <a onClick={() => readMoreFunction(true)}>{buttonText}</a>
            )}
        </>
    )
}

export default ReadMore
