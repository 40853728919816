import React, { useEffect, useState, useContext, useRef } from 'react'
import openSocket from 'socket.io-client'
//import { socket } from '../../../common/socket'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    Drawer,
    IconButton,
} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import BiddingItem from '../../../components/molecules/Bidding/BiddingItem'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Pagination } from '@material-ui/lab'
import Box from '@material-ui/core/Box'
import { Link, useHistory } from 'react-router-dom'
import CustomSelect from '../../../components/atoms/Inputs/CustomSelect'
import SecondaryButton from '../../../components/atoms/SecondaryButton'
import FavoriteCheckbox from '../../../components/atoms/FavoriteCheckbox'
import PrimaryButton from '../../../components/atoms/PrimaryButton'
import LineItemDetails from './LineItemDetails'
import Timer from '../../../common/timer'
import Popup from '../../../components/organisms/Popup'
import CurrencyConverter from '../CurrencyConverter'
import { apiCall } from '../../../common/api'
import { dateFormatFront, handleRedirectInternal, titleUrlFormat } from '../../../common/components'
import { lineItemBidSocketHandler } from '../../Common/socketHandler'
import { PhotoSwipeGallery } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'
import ReactHtmlParser from 'react-html-parser'
// context
import CommonContext from '../../../context/common/commonContext'
import AuthContext from '../../../context/auth/authContext'
import AlertContext from '../../../context/alert/alertContext'
import Loader from '../../../components/molecules/Loader'
import Login from '../../Login'

function TabPanel(props) {
    const { children, value, index, html, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {html ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: html,
                            }}
                        ></span>
                    ) : (
                        <span>{children}</span>
                    )}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    fullList: {
        width: 'auto',
    },
}))

const LineItemAuction = ({ images, product, downloadFile, setProduct, slider }) => {
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const { user, isAuthenticated } = authContext
    const { setAlert } = alertContext
    const { phrase } = useContext(CommonContext)
    const [value, setValue] = useState(0)
    const [lineItems, setLineItems] = useState([])
    const [lineItemsView, setLineItemsView] = useState([])
    const [totalProductCnt, setTotalProductCnt] = useState(0)
    const [totalPageCnt, setTotalPageCnt] = useState(0)
    const [pageDescription, setPageDescription] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [lineItemsReady, setLineItemsReady] = useState(false)
    const [bidInstructionOpen, setBidInstructionOpen] = useState(false)
    const [logPopup, setLogPopup] = useState(false)
    const [bulkBidLineItems, setBulkBidLineItems] = useState([])
    const [bulkBidTable, setBulkBidTable] = useState([])
    const [bulkBidPopupOpen, setBulkBidPopupOpen] = useState(false)
    const [showDwBidStatus, setDwBidStatus] = useState(product.dwBidStatus) // download bid status link
    const [contactPopup, setContactPopup] = useState(false)
    const [productDescPopup, setProductDescPopup] = useState(false)
    const firstLineItemLoad = useRef(true)
    const bulkBidRef = useRef({}) // store info needed for bulk bidding
    const didBulkBid = useRef(false)
    const [isOpen, setOpen] = useState(false)
    const { setBidHistoryValue } = useContext(CommonContext)
    let history = useHistory()
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    // change results per page value
    const changePageLimit = (e) => {
        setLineItemsReady(false)
        setPageLimit(e.target.value)
        setPageNum(1)
    }
    const changePageHandler = (e, value) => {
        setLineItemsReady(false)
        setPageNum(value)
    }
    const getLineItemProducts = () => {
        let endPoint = `getLineItemsProduct${isAuthenticated ? '' : '_new'}?ajax=1&sh_limit=${
            pageLimitRef.current
        }&page=${pageNumRef.current}&project_id=${product.id}`
        apiCall('get', endPoint, '', '', 'product').then((res) => {
            console.log('[LineItemAuction] ', res)
            if (res.data.status == 'success') {
                let lineItemsTemp = []
                if (res.data.data.subproject.length == 0) {
                    lineItemsTemp.push(
                        <tr>
                            <td colSpan="5" className="text-center">
                                No line items available
                            </td>
                        </tr>,
                    )
                } else {
                    setTotalProductCnt(res.data.data.total_pagecnt)
                    setPageDescription(res.data.data.set_disp)
                    let partNumbers = {}
                    for (var i of product.linedata) {
                        partNumbers[i.id] = i.part_number
                    }

                    lineItemsTemp = res.data.data.subproject.map((item) => {
                        let companyHasBid = 0
                        if (user.companyid && user.companyid != '' && user.companyid != '0') {
                            companyHasBid = item.companynot
                        }

                        return {
                            id: item.id,
                            category_id: item.category_id,
                            title: item.title,
                            description: item.description,
                            qty: item.qty,
                            currency: item.currency,
                            currency_symbol: item.currency_symbol,
                            wpriceview: item.wpriceview,
                            wprice: item.wprice,
                            rprice: item.rprice,
                            next_bid: item.next_bid,
                            bidtopstatus: item.bidtopstatus, // status column, status of user's bid
                            bidPrice: item.bidderproxyant,
                            market_status: product.market_status,
                            master_id: product.id,
                            auctiontype: product.auctiontype,
                            bidtype: item.bidtype,
                            lottype: item.lottype,
                            csvfile: product.csvfile,
                            incrementAmt: item.incrementamt,
                            partNumber: partNumbers[item.id],
                            hasBid: item.cbidnot,
                            companyHasBid: companyHasBid,
                            lot_number: item.lot_number,
                            isSocketUpdate: false,
                        }
                    })
                }

                setLineItems(lineItemsTemp)
            }
        })
    }

    // download buyer's bid status
    const downloadBidStatus = () => {
        apiCall('get', `downloadBidStatus/${product.id}`, '', '', 'product').then((res) => {
            console.log('downloaded bid status: ', res)
            window.location.href = res.data.filePath
        })
    }

    const lineItemsRef = useRef(lineItems) // line items on the page
    const bulkBidLineItemsRef = useRef(bulkBidLineItems)
    const userRef = useRef(user)
    const pageNumRef = useRef(pageNum)
    const pageLimitRef = useRef(pageLimit)
    useEffect(() => {
        bulkBidLineItemsRef.current = bulkBidLineItems
        userRef.current = user
        lineItemsRef.current = lineItems
        pageNumRef.current = pageNum
        pageLimitRef.current = pageLimit
    })
    const toggleLogOpen = () => {
        setLogPopup(!logPopup)
        if (window.location.hash.includes('#login')) {
            handleRedirectInternal(history, '')
        }
    }
    const socketHandler = (data, type) => {
        lineItemBidSocketHandler(
            data,
            type,
            userRef.current,
            lineItemsRef.current,
            bulkBidLineItemsRef.current,
            didBulkBid.current,
            setLineItems,
            setDwBidStatus,
            setBulkBidLineItems,
            setAlert,
            phrase,
        )
    }

    const cancelBidHandler = (data) => {
        let isLineOnScreen = lineItemsRef.current.findIndex((lineItem) => {
            return lineItem.id == data.project_id
        })
        if (isLineOnScreen !== -1) {
            getLineItemProducts()
        }
    }
    // define socket event handler on first render
    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        socket.on('realclosedupdates', (data) => {
            socketHandler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            socketHandler(data, 'bidAddtime')
        })
        // Bid Cancellation Event => Reload the product info
        socket.on('cancelbidemitted', function (data) {
            cancelBidHandler(data)
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                socketHandler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                socketHandler(data, 'bidAddtime')
            })
            socket.off('cancelbidemitted', function (data) {
                cancelBidHandler(data)
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        let pageCount = Math.ceil(parseInt(totalProductCnt) / parseInt(pageLimit))
        setTotalPageCnt(pageCount)
    }, [totalProductCnt, pageLimit])

    useEffect(() => {
        if (firstLineItemLoad.current) firstLineItemLoad.current = false
        getLineItemProducts()
    }, [pageLimit, pageNum])

    useEffect(() => {
        if (product.id && !firstLineItemLoad.current) {
            getLineItemProducts()
        }
    }, [product.id])

    // set each line items view
    useEffect(() => {
        let lineItemsTemp = []
        if (lineItems.length == 0) {
            lineItemsTemp.push(
                <tr>
                    <td colSpan="5" className="text-center">
                        No line items available
                    </td>
                </tr>,
            )
        } else {
            let ref = {}
            lineItemsTemp = lineItems.map((item) => {
                ref[item.id] = {
                    next_bid: item.next_bid,
                    bid_increment: item.incrementAmt,
                }
                return (
                    <tr key={item.id}>
                        <td scope="row" data-title="">
                            {item.lot_number}
                        </td>
                        <td>{item.description}</td>
                        <td
                            className="primeColor"
                            style={{ cursor: 'pointer' }}
                            onClick={toggleDrawer('right', true, {
                                id: item.id,
                            })}
                        >
                            {item.title}
                        </td>
                        <td>{item.category_id}</td>
                        <td>{item.qty}</td>
                        {product.bidtype != 'sealed' ? (
                            <td>
                                {item.currency} {item.currency_symbol}
                                {item.wprice}
                            </td>
                        ) : null}
                        {item.market_status == 'open' || item.isSocketUpdate ? (
                            <td>
                                <div className="lineItemAction">
                                    {item.bidPrice > 0 && item.market_status == 'open' ? (
                                        item.wprice >= item.rprice ? (
                                            <p>{phrase.reserve_price_is_met}</p>
                                        ) : (
                                            <p className="red">{phrase.reserve_price_is_not_met}</p>
                                        )
                                    ) : null}

                                    <BiddingItem
                                        product={item}
                                        phrases={phrase}
                                        wsprice={item.bidPrice}
                                        handleClose={() => {}}
                                    />
                                </div>
                            </td>
                        ) : (
                            <td>
                                {(product.market_status === 'sold' ||
                                    product.market_status === 'relisted' ||
                                    product.market_status === 'closed') &&
                                item.bidtopstatus?.toLowerCase()?.includes('won') ? (
                                    <Link
                                        to={`/dashboard/won`}
                                        style={{
                                            display: 'block',
                                            maxWidth: '200px',
                                            width: '100%',
                                        }}
                                    >
                                        <PrimaryButton label="Go To Checkout" />
                                    </Link>
                                ) : null}
                            </td>
                        )}

                        {product.bidtype != 'sealed' ? (
                            <td>{ReactHtmlParser(item.bidtopstatus)}</td>
                        ) : null}
                    </tr>
                )
            })
            bulkBidRef.current = ref
            setLineItemsView(lineItemsTemp)
            setLineItemsReady(true)
        }
    }, [lineItems])

    useEffect(() => {
        //console.log('bulk bid line items: ', bulkBidLineItems)
        if (Object.keys(bulkBidLineItems).length > 0) {
            let table = []
            for (let item of bulkBidLineItems) {
                let tableRow = (
                    <>
                        <tr>
                            <td>{item.brand}</td>
                            <td>{item.product_name}</td>
                            <td>{item.category}</td>
                            <td>{item.qty}</td>
                            <td>
                                {product.currency_symbol}
                                {item.wsprice}
                            </td>
                            {item.status}
                        </tr>
                    </>
                )
                table.push(tableRow)
            }
            // set the bulk bid line item table in the modal
            setBulkBidTable(table)
        }
    }, [bulkBidLineItems])

    // BULK BIDDING, once bulk bid item table is set, open the modal
    useEffect(() => {
        if (bulkBidTable.length > 0) {
            setBulkBidPopupOpen(true)
        }
    }, [bulkBidTable])
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            let lineDataIndex = product.linedata.findIndex((lindata) => {
                return lindata.id == data.id
            })
            let lineItemData = { ...product.linedata[lineDataIndex] }
            lineItemData.id = data.id
            setState({ ...state, [anchor]: open, data: lineItemData })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const bidInstructionHandler = () => {
        setBidInstructionOpen(true)
    }
    const bidInstructionClose = () => {
        setBidInstructionOpen(false)
    }

    let downloadManifestFile = downloadFile
    const redirectToManifestFile = () => {
        window.location.href = `${global.site_url}/uploads/product_csv/${product.buyer_manifest_file}`
    }
    if (
        typeof product.download_complete_manifest !== 'undefined' &&
        product.download_complete_manifest != ''
    ) {
        downloadManifestFile = redirectToManifestFile
    }

    const openCsvBidFile = () => {
        document.getElementById('bid_csv').click()
    }
    const bulkBidPopupClose = () => {
        // re-initialize
        didBulkBid.current = false
        setBulkBidPopupOpen(false)
        setBulkBidLineItems([])
        setBulkBidTable([])
    }
    const uploadBulkBidding = (e) => {
        console.log('upload bulk bidding')
        var formData = new FormData()
        var bidFile = document.querySelector('#bid_csv')
        if (bidFile.files.length > 0) {
            formData.append('bid_csv', bidFile.files[0])
            formData.append('project_id', product.id)
            formData.append('currency', product.currency)
            apiCall('post', 'upload-bid-csv-lineitem', formData, 'formdata', 'product')
                .then((res) => {
                    console.log(res)
                    if (res.data.msgdata == 'success') {
                        console.log('SUCCESS')
                        let productCopy = { ...product }
                        productCopy.csvfile = res.data.csvfile
                        setProduct(productCopy)

                        var productBidArray = res.data.productBidArray
                        var bulkBids = []
                        for (let item of res.data.productBidArray) {
                            if (parseInt(item.wsprice) >= bulkBidRef.current[item.id].next_bid) {
                                item.status = (
                                    <td className="alert alert-warning">
                                        {phrase.waitingforconfirmation}
                                    </td>
                                )
                                item.bid_increment = bulkBidRef.current[item.id].bid_increment
                                item.csvfile = e.target.value
                                bulkBids.push(item)
                            }
                        }
                        setBulkBidLineItems(bulkBids)
                        // to re-upload again
                        e.target.value = null
                    }
                })
                .catch((err) => {
                    console.log('ERROR: ', err)
                    setAlert('Something wrong happend.. Please try again.', 'error')
                })
        }
    }
    const submitBulkBidding = () => {
        // console.log('SUBMITTING BULK BIDDING')
        // console.log(bulkBidLineItems)
        let bidArr = [...bulkBidLineItems]
        if (bidArr.length > 0) {
            for (let bid of bidArr) {
                var bidData = {
                    auctionType: 'lineitem',
                    bidType: product.bidtype,
                    lotType: '',
                    id: bid.id,
                    bid_increment: bid.bid_increment,
                    wsprice: bid.wsprice,
                    master_id: product.id,
                }
                // submit bid, response will be captured in socket event
                apiCall('post', 'bidConfirm', bidData, '', 'product')
            }
            // re-initialize the bulk bid array
            didBulkBid.current = true
        }
    }

    const contactPopupOpen = () => {
        setContactPopup(true)
    }
    const contactPopupClose = () => {
        setContactPopup(false)
    }
    const productDescClose = () => {
        setProductDescPopup(false)
    }
    const contactSeller = () => {
        let msg = document.getElementById('contactSellerMsg')
        if (msg.value != '') {
            contactPopupClose()
            let data = {}
            data.to_id = product.sellerid
            data.projectmsgpost = product.id
            data.message = msg.value
            data.from_id = user.id
            apiCall('post', 'savemessagefromview', data, '', 'messages')
                .then((res) => {
                    msg.value = ''
                    setAlert(phrase.message_successfully_sent, 'success')
                })
                .catch((err) => {
                    console.log(err)
                    setAlert('Something wrong happend.. Please try again.', 'error')
                })
        }
    }

    let categories = ''
    if (product) {
        for (let i in product.multiplecategoryshow) {
            if (i == product.multiplecategoryshow.length - 1) {
                // last index
                //categories += product.multiplecategoryshow[i]
                categories += phrase[product.multiplecategoryshow[i]]
            } else {
                //categories += product.multiplecategoryshow[i] + ', '
                categories += phrase[product.multiplecategoryshow[i]] + ', '
            }
        }
    }

    const redirectFile = (event) => {
        if (event.target.alt !== 'img') {
            window.location.href = `${global.site_url}/uploads/product/${event.target.title}`
        } else {
            event.preventDefault()
            setOpen(true)
        }
    }
    let options = {}

    return (
        <>
            {slider ? (
                <div className="mt-3 sliderDetails">
                    <div className="timerMainCnt d-flex align-items-start justify-content-between">
                        <div>
                            <p className="lotID">Lot id : {product.id}</p>
                            <h2 className="pvTitle"> {product.title} </h2>
                        </div>
                        <div key="bidTimer" className="bidTimeCntRt text-center primeTimer">
                            {/* <label> {phrase.time_left} </label> */}
                            <div className="primeColor">
                                <Timer
                                    date_added={product.date_added}
                                    date_closed={product.date_closed}
                                    withText={1}
                                ></Timer>
                                {/* <h6>{dateFormatFront(product.date_closed)}</h6> */}
                            </div>
                        </div>
                    </div>
                    <div>
                        <ImageGallery
                            items={images}
                            thumbnailPosition="bottom"
                            showNav={false}
                            showBullets={false}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            autoPlay={true}
                            onClick={redirectFile}
                        />
                        {/* <PhotoSwipeGallery
                            items={images}
                            isOpen={isOpen}
                            onClose={() => setOpen(false)}
                            options={options}
                        /> */}
                    </div>
                    {isAuthenticated ? (
                        <div className="mainCondCnt d-flex align-items-start justify-content-between my-2">
                            <div className="conditionCnt">
                                <div className="conditions d-flex align-items-center">
                                    <label>Condition :</label>
                                    <h6> {phrase[product.condition]}</h6>
                                </div>
                                {/* <div key="currentBid">
                                    <label>
                                        {' '}
                                        {product.bid_count
                                            ? phrase.current_bid
                                            : phrase.starting_price}{' '}
                                    </label>
                                    <h1 className="price">
                                        {product.currency_symbol}
                                        {product.wprice} &nbsp;
                                        {product.currency}
                                    </h1>
                                </div> */}
                            </div>
                            {isAuthenticated ? (
                                <Button
                                    className="viewBids"
                                    onClick={() => setBidHistoryValue(product.id)}
                                >
                                    {' '}
                                    {phrase.view_all_bids}({product.bid_count})
                                </Button>
                            ) : (
                                <p>Bids ({product.bid_count})</p>
                            )}
                        </div>
                    ) : null}

                    {isAuthenticated ? (
                        <SecondaryButton
                            label="Bid Now"
                            btnSize="small"
                            onClick={() =>
                                history.push(
                                    `product/view/${product?.id}/${titleUrlFormat(product?.title)}`,
                                )
                            }
                        />
                    ) : (
                        <SecondaryButton
                            label="Login To Bid"
                            btnSize="small"
                            onClick={() => setLogPopup(true)}
                        />
                    )}
                    <div className="description my-4">
                        <Accordion defaultExpanded>
                            <AccordionSummary>Product Description</AccordionSummary>
                            <AccordionDetails>
                                {/* <div className="lotDataSort mt-4 d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 className="lotCount"></h6>
                                    <CustomSelect
                                        size="small"
                                        label="Show Results"
                                        onChange={changePageLimit}
                                        value={pageLimit}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                    </CustomSelect>
                                </div>
                                <table className="table mobileTable laTable table-striped table-responsive">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">{phrase.sublot_id}</th>
                                            <th scope="col">{phrase.brand}</th>
                                            <th scope="col">{phrase.product_name}</th>
                                            <th scope="col">{phrase.category}</th>
                                            <th scope="col">{phrase.qty}</th>
                                            <th scope="col">{phrase.current_bid}</th>
                                            <th scope="col">{phrase.action}</th>
                                            <th scope="col">{phrase.status}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="mtInner"></tbody>
                                </table> */}
                                <div className="d-flex justify-content-center mb-5"></div>

                                <div style={{ textAlign: 'center' }}>
                                    <PrimaryButton
                                        width="40%"
                                        onClick={(e) => {
                                            history.push(
                                                `/product/view/${product?.id}/${titleUrlFormat(
                                                    product?.title,
                                                )}`,
                                            )
                                        }}
                                    >
                                        click to view description
                                    </PrimaryButton>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary>Condition</AccordionSummary>
                            <AccordionDetails>
                                {ReactHtmlParser(product?.desc_condition)}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary>FAQ</AccordionSummary>
                            <AccordionDetails>
                                {ReactHtmlParser(product?.desc_condition)}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary>Shipping</AccordionSummary>
                            <AccordionDetails>
                                {ReactHtmlParser(phrase.productview_1)}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary>Payment Note</AccordionSummary>
                            <AccordionDetails>{phrase.payment_note_details}</AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            ) : (
                <div className="auctionView lineItemView container-fluid mt-2">
                    <div className="customContainer">
                        <div className="d-flex justify-content-start">
                            <Button className="backBtn my-2" onClick={() => history.goBack()}>
                                <span className="material-icons">arrow_back</span>Back
                            </Button>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                {user.isBuyer && product.isclosed >= 0 ? (
                                    <FavoriteCheckbox
                                        watchlisted={product.isWatchlisted}
                                        project_id={product.id}
                                    />
                                ) : null}
                                <ImageGallery
                                    items={images}
                                    thumbnailPosition="bottom"
                                    showNav={false}
                                    showBullets={false}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    autoPlay={true}
                                    onClick={redirectFile}
                                />
                                <PhotoSwipeGallery
                                    items={images}
                                    isOpen={isOpen}
                                    onClose={() => setOpen(false)}
                                    options={options}
                                />
                            </div>
                            <div className="col-lg-8 col-md-6 col-12">
                                <div className="lineItemTimerCnt primeColor">
                                    <Timer
                                        date_added={product.date_added}
                                        date_closed={product.date_closed}
                                        withText={0}
                                    ></Timer>
                                    <br />
                                    <h6>{dateFormatFront(product.date_closed)}</h6>
                                </div>
                                <h2 className="pvTitle">{product.title}</h2>

                                <div className="lineItemInfo">
                                    <div>
                                        <div className="pvGrid pvMainGrid">
                                            <div className="pvGrid">
                                                <label> {phrase.no_of_items}</label>
                                                <span>{product.qty}</span>
                                            </div>
                                            <div className="pvGrid">
                                                <label>{phrase.condition}</label>
                                                <span>
                                                    {phrase[product.condition]
                                                        ? phrase[product.condition]
                                                        : product.condition}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="pvGrid pvMainGrid">
                                            {/* <div className="pvGrid">
                                        <label>{phrase.lot_size}</label>
                                        <span>
                                            {' '}
                                            {phrase[product.lottype]} {phrase.lot}
                                        </span>
                                    </div> */}
                                            <div className="pvGrid">
                                                <label>{phrase.category}</label>
                                                <span>{categories}</span>
                                            </div>
                                            <div className="pvGrid">
                                                <label> {phrase.auction_type} </label>
                                                <span>Line Item</span>
                                            </div>
                                        </div>
                                        {/* 
                                <div className="pvGrid">
                                    
                                    <div className="pvGrid">
                                        <label> {phrase.location} </label>
                                        <span> {product.city} </span>
                                    </div>
                                </div> */}

                                        <div className="pvDesc">
                                            <label>{phrase.description}</label>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        product.desc_proc.length > 200
                                                            ? product.desc_proc.substring(0, 200)
                                                            : product.desc_proc,
                                                }}
                                            ></p>
                                            {product.desc_proc.length > 200 ? (
                                                <a
                                                    className="font-25"
                                                    onClick={() => setProductDescPopup(true)}
                                                >
                                                    {phrase.view_more}
                                                </a>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="scrollDown">
                                        <div className="ndCTA">
                                            <Button
                                                onClick={() =>
                                                    document
                                                        .getElementById('productView')
                                                        .scrollIntoView({ behavior: 'smooth' })
                                                }
                                            >
                                                {phrase.scroll_to_bid}
                                            </Button>
                                            <div className="arrow">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>

                                    <Popup
                                        open={contactPopup}
                                        handleClose={contactPopupClose}
                                        modaltitle={phrase.send_message}
                                        footer={
                                            <>
                                                <PrimaryButton
                                                    label={phrase.send}
                                                    onClick={contactSeller}
                                                />
                                                <SecondaryButton
                                                    label={phrase.cancel}
                                                    onClick={contactPopupClose}
                                                />
                                            </>
                                        }
                                    >
                                        <div className="row text-left">
                                            <div className="col-md-2">
                                                <label>{phrase.title}</label>
                                            </div>
                                            <div className="col-md-8">
                                                <label>{product.title}</label>
                                            </div>
                                        </div>
                                        <div className="row text-left">
                                            <div className="col-md-2">
                                                <label>{phrase.message}</label>
                                            </div>
                                            <div className="col-md-8">
                                                <textarea
                                                    className="form-control"
                                                    id="contactSellerMsg"
                                                    rows="4"
                                                    placeholder={phrase.enter_your_message}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </Popup>
                                </div>
                                <div className="d-flex justify-content-center justify-content-lg-end">
                                    <div className="mt-3 mb-2 d-flex justify-content-end"></div>
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            className="contactSeller"
                                            onClick={contactPopupOpen}
                                            disabled={user.isBuyer ? false : true}
                                        >
                                            <span className="material-icons">chat</span>
                                            <span> {phrase.contact_seller} </span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 lotDetails" id="productView">
                                {product.currencyexist ? (
                                    <CurrencyConverter product={product} />
                                ) : null}
                                <div className="lineItemMiscActions d-flex justify-content-end align-items-center">
                                    <SecondaryButton
                                        btnSize="small"
                                        label={[
                                            <span className="material-icons">info</span>,
                                            phrase.bidding_instruction,
                                        ]}
                                        onClick={bidInstructionHandler}
                                    />
                                    {isAuthenticated ? (
                                        <>
                                            <SecondaryButton
                                                btnSize="small"
                                                label={[
                                                    <span className="material-icons">
                                                        cloud_download
                                                    </span>,
                                                    phrase.download_complete_manfiest,
                                                ]}
                                                onClick={downloadManifestFile}
                                            />
                                            {product.isclosed >= 0 ? (
                                                <SecondaryButton
                                                    btnSize="small"
                                                    label={[
                                                        <span className="material-icons">
                                                            cloud_upload
                                                        </span>,
                                                        phrase.upload_bulk_bidding,
                                                    ]}
                                                    onClick={openCsvBidFile}
                                                    disabled={user.isBuyer ? false : true}
                                                />
                                            ) : null}

                                            <input
                                                type="file"
                                                id="bid_csv"
                                                accept=".xls,.xlsx"
                                                onChange={(e) => uploadBulkBidding(e)}
                                                hidden
                                            />
                                        </>
                                    ) : null}
                                </div>
                                <h2>{phrase.line_item_bidding}</h2>
                                <div className="customTabs">
                                    <AppBar position="static">
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="Line Item Auctions"
                                            indicatorColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                        >
                                            <Tab label="Products Description" {...a11yProps(0)} />
                                            <Tab label="Condition" {...a11yProps(1)} />
                                            <Tab label="FAQ" {...a11yProps(2)} />
                                            <Tab label="Shipping" {...a11yProps(3)} />
                                            <Tab label="Payment Note" {...a11yProps(4)} />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel value={value} index={0}>
                                        <div className="lotDataSort mt-4 d-flex justify-content-between align-items-center flex-wrap">
                                            <h6 className="lotCount">
                                                {phrase.showing} {pageDescription} {phrase.of}{' '}
                                                {totalProductCnt} {phrase.lots}
                                            </h6>
                                            <CustomSelect
                                                size="small"
                                                label="Show Results"
                                                onChange={changePageLimit}
                                                value={pageLimit}
                                            >
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={25}>25</MenuItem>
                                                <MenuItem value={50}>50</MenuItem>
                                            </CustomSelect>
                                        </div>

                                        {lineItemsReady ? (
                                            <div className="table-responsive">
                                                <table className="table  laTable table-striped">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th scope="col">{'Item No'}</th>
                                                            <th scope="col">{phrase.brand}</th>
                                                            <th scope="col">
                                                                {phrase.product_name}
                                                            </th>
                                                            <th scope="col">{phrase.category}</th>
                                                            <th scope="col">{phrase.qty}</th>
                                                            {product.bidtype != 'sealed' ? (
                                                                <th scope="col">
                                                                    {phrase.current_bid}
                                                                </th>
                                                            ) : null}

                                                            {/* <th scope="col">{phrase.action}</th> */}

                                                            {product.bidtype != 'sealed' ? (
                                                                <th scope="col">Action</th>
                                                            ) : (
                                                                <th scope="col"> - </th>
                                                            )}
                                                            <th scope="col">{phrase.status}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="mtInner">
                                                        {lineItemsView}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <Loader />
                                        )}
                                        <div className="d-flex justify-content-center">
                                            <Pagination
                                                count={totalPageCnt}
                                                onChange={changePageHandler}
                                            ></Pagination>
                                        </div>
                                        {isAuthenticated ? (
                                            <div className="downloadManifest">
                                                <PrimaryButton
                                                    onClick={downloadManifestFile}
                                                    width="40%"
                                                >
                                                    <span
                                                        className="material-icons"
                                                        style={{ marginRight: '3px' }}
                                                    >
                                                        cloud_download
                                                    </span>{' '}
                                                    {phrase.download_complete_manfiest}
                                                </PrimaryButton>
                                                {showDwBidStatus ? (
                                                    <div className="dwBidStatus">
                                                        <a onClick={downloadBidStatus}>
                                                            {phrase.download_bid_status}
                                                        </a>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {ReactHtmlParser(product?.desc_condition)}
                                    </TabPanel>
                                    <TabPanel
                                        value={value}
                                        index={2}
                                        html={product.faqContent}
                                    ></TabPanel>
                                    <TabPanel value={value} index={3}>
                                        {ReactHtmlParser(phrase.productview_1)}
                                    </TabPanel>
                                    <TabPanel value={value} index={4}>
                                        {phrase.payment_note_details}
                                    </TabPanel>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                <div className="col-12 assuranceCnt">
                    <div className="customContainer ">
                        <div className="col-lg-8 col-12">
                            <div className="assuranceCard d-flex justify-content-between">
                                <span className="material-icons">verified</span>
                                <div>
                                    <h2>{phrase.buy_with_confidence}</h2>
                                    <p>{phrase.productview_2}</p>
                                    <p>{phrase.productview_3}</p>
                                    <p>{phrase.productview_4}</p>
                                    <span className="leaf">
                                        <span className="material-icons">eco</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                </div>
            )}
            <Drawer
                className="rightDrawer"
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >
                <LineItemDetails data={state['data']} close={toggleDrawer('right', false)} />
            </Drawer>
            {/* /Bidding Instruction Popup*/}
            <Popup
                open={bidInstructionOpen}
                modaltitle={phrase.bidding_instruction}
                handleClose={bidInstructionClose}
                footer={<SecondaryButton label={phrase.close} onClick={bidInstructionClose} />}
            >
                <p dangerouslySetInnerHTML={{ __html: product.bidInstruction.for_english }} />
            </Popup>
            {/* Bulk Bidding Popup */}
            <Popup
                open={bulkBidPopupOpen}
                handleClose={bulkBidPopupClose}
                modaltitle={phrase.bid_confirmation}
                footer={
                    didBulkBid.current ? (
                        <PrimaryButton label={phrase.close} onClick={bulkBidPopupClose} />
                    ) : (
                        <>
                            {' '}
                            <PrimaryButton label={phrase.submit} onClick={submitBulkBidding} />
                            <SecondaryButton
                                label={phrase.cancel}
                                onClick={bulkBidPopupClose}
                            />{' '}
                        </>
                    )
                }
            >
                <div className="row ml-2 mr-2">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>{phrase.brand}</th>
                                <th>{phrase.product_name}</th>
                                <th>{phrase.category}</th>
                                <th>{phrase.qty}</th>
                                <th>{phrase.amount}</th>
                                <th>{phrase.status}</th>
                            </tr>
                        </thead>
                        <tbody>{bulkBidTable}</tbody>
                    </table>
                </div>
            </Popup>

            {/* Long Product Description Modal*/}
            <Popup
                open={productDescPopup}
                modaltitle={phrase.description}
                handleClose={productDescClose}
                footer={<SecondaryButton label={phrase.close} onClick={productDescClose} />}
            >
                <div
                    style={{ textAlign: 'left' }}
                    dangerouslySetInnerHTML={{
                        __html: product.desc_proc,
                    }}
                ></div>
            </Popup>

            <Dialog
                open={logPopup}
                function={toggleLogOpen}
                // maxWidth={'md'}
                className="lgnWrpr"
            >
                <div className="lgnCntnr">
                    <IconButton className="popClrBtn" onClick={toggleLogOpen}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                    <Login
                        autoComplete={1}
                        closeLogin={toggleLogOpen}
                        // openRegister={toggleRegOpen}
                        onMenuClose={() => setAnchorMenu(null)}
                    />
                </div>
            </Dialog>
        </>
    )
}

export default LineItemAuction
