import {
    GET_STATIC_PAGE,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    USER_DATA,
    USER_PREFERENCES,
} from './userTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_STATIC_PAGE:
            return {
                ...state,
                static_page: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case USER_DATA:
            return {
                ...state,
                userData: action.payload.users,
                shippingAddressData: action.payload.myshipping,
                userNotifications: action.payload,
            }
        case USER_PREFERENCES: {
            return {
                ...state,
                userPreferences: action.payload,
            }
        }
        default:
            return state
    }
}
