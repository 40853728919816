import React, { useState, useContext, useEffect } from 'react'
import './ResetPassword.css'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import CheckBox from '../../components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import { Button, Dialog, Divider, IconButton } from '@material-ui/core'
import { mapData, handleRedirectInternal } from '../../common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AlertContext from '../../context/alert/alertContext'
import CommonContext from '../../context/common/commonContext'
import UserContext from '../../context/user/userContext'
import { logo, siteName } from '../../Utils'
import Login from '../Login'
import GlobalStateContext from '../../context/globalState/globalStateContext'

const ResetPassword = (props) => {
    const history = useHistory()

    const { setAlert } = useContext(AlertContext)
    const { phrase } = useContext(CommonContext)
    const { decodeResetPasswordToken, updateUserPasswordUnauth } = useContext(UserContext)
    const rememberedTokenLoc = localStorage.getItem('remembered')
    const [userInfo, setUserInfo] = useState({})
    let [passwordShown, setPasswordShown] = useState(false)
    let [passwordShown1, setPasswordShown1] = useState(false)
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }
    const { loginPopup, handleLoginPopup } = useContext(GlobalStateContext)
    useEffect(async () => {
        const res = await decodeResetPasswordToken(props.match.params.token)
        setUserInfo(res)
    }, [])

    const validationArray = Yup.object({
        password: Yup.string().min(8, phrase.min8_char).required(phrase.required_landline),
        // confirm_password: Yup.string().when('password', {
        //     is: (val) => (val && val.length > 0 ? true : false),
        //     then: Yup.string().oneOf([Yup.ref('password')], 'Password Mismatch'),
        // }),
        confirm_password: Yup.string()
            .required(`${phrase.confirm} ${phrase.password}`)
            .oneOf([Yup.ref('password'), null], phrase.this_value_should_be_the_same),
    })
    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            let passwordResetFormDate = new FormData()
            passwordResetFormDate.append('emailid', userInfo.emailid)
            passwordResetFormDate.append('email_encry', userInfo.emailency)
            passwordResetFormDate.append('userid', userInfo.user_id)
            passwordResetFormDate.append('password', values['password'])
            passwordResetFormDate.append('new_password1', values['password'])
            passwordResetFormDate.append('site_id', global.site_id)

            const res = await updateUserPasswordUnauth(passwordResetFormDate)
            if (res) {
                handleLoginPopup()
                history.push('/')
                setAlert('Password Change Successful', 'success')
            }
        },
    })

    const loginInfo = [
        {
            label: phrase.new_password,
            name: 'password',
            autoFocus: true,
            type: passwordShown ? 'text' : 'password',
            placeholder: phrase.new_password,
            class: 'col-12',
            formik: formik,
            endadornment: passwordShown ? (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility_off
                </span>
            ) : (
                <span className="material-icons cursorPointer" onClick={togglePasswordVisiblity}>
                    visibility
                </span>
            ),
        },
        {
            label: phrase.confirm_password,
            name: 'confirm_password',
            type: passwordShown1 ? 'text' : 'password',
            placeholder: phrase.confirm_password,
            class: 'col-12',
            formik: formik,
            endadornment: passwordShown1 ? (
                <span
                    className="material-icons cursorPointer"
                    onClick={() => setPasswordShown1(!passwordShown1)}
                >
                    visibility_off
                </span>
            ) : (
                <span
                    className="material-icons cursorPointer"
                    onClick={() => setPasswordShown1(!passwordShown1)}
                >
                    visibility
                </span>
            ),
        },
    ]

    return (
        <>
            <div className="loginContainer resetContainer">
                {/* <div className="bg-login"></div> */}
                <div className="log-content">
                    <Link to="/">
                        <img className="brandLogo" src={logo} alt={`${siteName} logo`} />
                    </Link>
                    <div className="login">
                        <h2 className="loginTitle">
                            <span>{phrase.password_reset}</span>
                        </h2>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">{Object.values(mapData(loginInfo))}</div>
                            <PrimaryButton label={phrase.submit} type="submit" />
                        </form>
                    </div>
                </div>
            </div>
            <Dialog
                open={loginPopup}
                function={handleLoginPopup}
                // maxWidth={'md'}
                className="lgnWrpr"
            >
                <div className="lgnCntnr">
                    <IconButton className="popClrBtn" onClick={handleLoginPopup}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                    <Login autoComplete={1} />
                </div>
            </Dialog>
        </>
    )
}

export default ResetPassword
