import React, { useState, useContext, useEffect, useRef } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { logo, siteName } from '../../../Utils'
import { Link } from 'react-router-dom'
import {
    Badge,
    Button,
    Dialog,
    Divider,
    Drawer,
    IconButton,
    ListItem,
    Menu,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'

import AuthContext from '../../../context/auth/authContext'
import CommonContext from '../../../context/common/commonContext'
import AlertContext from '../../../context/alert/alertContext'
import './Header1.css'
import AutopltContext from '../../../context/autopilot/autopltContext'
import UserContext from '../../../context/user/userContext'
import ProductContext from '../../../context/product/productContext'
import BidHistory from '../../organisms/BidHistory'
import Popup from '../../organisms/Popup'
import PrimaryButton from '../../atoms/PrimaryButton'

// Common Function
import { handleRedirectInternal, useCustomMediaQuery } from '../../../common/components'
import { apiCall } from '../../../common/api'
import SearchNav from '../SearchNav'
import CustomInput from '../../atoms/Inputs/CustomInput'
import Login from '../../../views/Login'
import Registration from '../../../views/Registration'
import ForgotPassword from '../../../views/ForgotPassword'
import ResetPassword from '../../../views/ResetPassword'
import GlobalStateContext from '../../../context/globalState/globalStateContext'
import { set } from 'date-fns'

const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const userContext = useContext(UserContext)
    const {
        loginPopup,
        handleLoginPopup,
        registerPopup,
        handleRegisterPopup,
        handleForgotPopup,
        forgotPopup,
    } = useContext(GlobalStateContext)
    // const [regPopup, setRegPopup] = useState(false)
    const autopltContext = useContext(AutopltContext)
    const {
        searchValue,
        phrase,
        language,
        setSearchValue,
        changeLanguage,
        msgUnreadCount,
        getMsgUnreadCount,
        clearNotification,
    } = commonContext

    const { setAlert } = alertContext
    const { user, isAuthenticated, isAdmin, logout } = authContext
    const [state, setState] = useState({
        top: false,
        right: false,
    })
    const history = useHistory()
    const { pathname } = useLocation()
    const [forPopup, setForPopup] = useState(false)
    const [anchorLanguage, setAnchorLanguage] = useState(null)
    const [anchorNotif, setAnchorNotif] = useState(null)
    const [anchorMenu, setAnchorMenu] = useState(null)
    const [bellNotify, setBellNotify] = useState([])
    const [bellNotifyContent, setBellNotifyContent] = useState([])
    const [bellNotifyUnreadCount, setBellNotifyUnreadCount] = useState()
    const [sessionPopup, setSessionPopup] = useState(false)
    const [logDrawer, setLogDrawer] = useState()

    const handleMenuClose = () => {
        setAnchorLanguage(null)
        setAnchorNotif(null)
    }
    const switchLanguage = (e, lang) => {
        if (language != lang) {
            changeLanguage(lang)
            handleMenuClose()
        }
        if (lang === 'German') {
            document.body.classList.add('German')
        } else {
            document.body.classList.remove('German')
        }
    }

    let languageKey = 'english'
    switch (language) {
        case 'English':
            languageKey = 'english'
            break
        case 'Mandarin':
            languageKey = 'mandarin'
            break
        case 'French':
            languageKey = 'french'
            break
        case 'Spanish':
            languageKey = 'spanish'
            break
        case 'Portugese':
            languageKey = 'portugese'
            break
        case 'BrazilPortugese':
            languageKey = 'brazil_portugese'
            break
        case 'German':
            languageKey = 'german'
            break
    }

    const { responseStatus: responseStatusProduct, clearResponse: clearResponseProduct } =
        productContext
    const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext
    const { responseStatus: responseStatusAutoPlt, clearResponse: clearResponseAutoPlt } =
        autopltContext

    // get notification asynchronously
    const getNotification = async () => {
        apiCall('get', '', '', '', 'notification')
            .then((response) => {
                setBellNotify(response.data.bellNotify)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const changeNotifyReadStatus = (id, read, lotId, masterLotId) => {
        let productId = 0
        if (masterLotId == 0) productId = lotId
        else productId = masterLotId

        // if already read, simply redirect
        if (read == 1) {
            handleRedirectInternal(history, `product/view/${productId}`)
        }
        // if not read, update the status of notification, and redirect
        else {
            apiCall('post', '', '', '', `updatebellsingle/${id}`)
            let bellNotifyCopy = [...bellNotify]
            const notifyIndex = bellNotifyCopy.findIndex((data) => {
                return data.id == id
            })
            bellNotifyCopy[notifyIndex].read = '1'
            setBellNotify(bellNotifyCopy)
            let unreadCount = bellNotifyUnreadCount
            setBellNotifyUnreadCount(unreadCount--)
            handleRedirectInternal(history, `product/view/${productId}`)
        }
    }

    const idleTime = useRef(0)
    const sessionInterval = useRef(null)
    const timerIncrement = () => {
        idleTime.current = idleTime.current + 1
        // After 8 hours of inactivity
        if (idleTime.current > 60 * 8) {
            setSessionPopup(true)
        }
    }

    const sessionPopupClose = () => {
        setSessionPopup(false)
        logout()
        setBellNotify([])
        clearInterval(sessionInterval.current)
        sessionInterval.current = null
    }

    useEffect(() => {
        if (isAuthenticated) {
            getNotification()
            getMsgUnreadCount()
            if (!sessionInterval.current) {
                //Increment the idle time counter every minute.
                sessionInterval.current = setInterval(timerIncrement, 60000) // 1 minute
                //Zero the idle timer on mouse movement.
                document.addEventListener('mousemove', (e) => {
                    idleTime.current = 0
                })
                document.addEventListener('keypress', (e) => {
                    idleTime.current = 0
                })
            }
        }
    }, [isAuthenticated])

    // Show More / Show Less link in notification bell
    const showMore = (index) => {
        let whiteSpaceCss = document.getElementById('notify_message_' + index).style.whiteSpace
        if (whiteSpaceCss == 'normal') {
            document.getElementById('notify_show_more_' + index).innerHTML = phrase.show_more + ' >'
            document.getElementById('notify_message_' + index).style.whiteSpace = 'nowrap'
        } else {
            document.getElementById('notify_show_more_' + index).innerHTML = phrase.show_less + ' >'
            document.getElementById('notify_message_' + index).style.whiteSpace = 'normal'
        }
    }

    const StyledMenuItem = withStyles({
        root: {
            width: 'auto',
            overflow: 'hidden',
            fontSize: '1rem',
            boxSizing: 'border-box',
            whiteSpace: 'normal',
        },
    })(MenuItem)
    useEffect(() => {
        if (bellNotify.length > 0) {
            let unreadCount = 0
            let notifyContent = bellNotify.map((data, i) => {
                let content = ''
                switch (data.type) {
                    case 1:
                        content = phrase.congratulations_you_bought + ' ' + data.title
                        break
                    case 2:
                        content = phrase.bid_is_placed_on + ' ' + data.title
                        break
                    case 3:
                    case 4:
                        content = phrase.you_have_been_outbid_on + ' ' + data.title
                        break
                    case 5:
                        content =
                            data.title + ' ' + phrase.has_been_successfully_added_to_your_watchlist
                        break
                    case 6:
                        content = phrase.congratulations_you_won + ' ' + data.title
                        break
                    case 8:
                    case 9:
                        content = data.title + ' ' + phrase.is_ending_soon
                        break
                    case 10:
                        content =
                            phrase.preferred_product + ' ' + data.title + ' ' + phrase.is_posted
                        break
                    case 11:
                    case 12:
                        content = data.title + ' ' + phrase.is_price_dropped
                        break
                    case 13:
                        content = phrase.notify_place_offer + ' ' + data.title
                        break
                    case 14:
                        content = phrase.notify_counter_offer + ' ' + data.title
                        break
                    case 15:
                        content = phrase.notify_decline_counter_offer + ' ' + data.title
                        break
                    case 16:
                        content = phrase.notify_reject_offer + ' ' + data.title
                        break
                    case 17:
                        content = phrase.notify_accept_offer + ' ' + data.title
                        break
                }
                let showMoreLink = true
                if (content.length < 26) showMoreLink = false
                if (data.read == '0') unreadCount++
                return (
                    <React.Fragment key={i}>
                        <StyledMenuItem onClick={handleMenuClose}>
                            <a
                                onClick={() =>
                                    changeNotifyReadStatus(
                                        data.id,
                                        data.read,
                                        data.project_id,
                                        data.linesublot,
                                    )
                                }
                            >
                                <h6
                                    id={'notify_message_' + i}
                                    style={
                                        data.read == '0'
                                            ? { fontWeight: 'bold', lineHeight: '1.3' }
                                            : { lineHeight: '1.3' }
                                    }
                                >
                                    {content}
                                </h6>
                            </a>
                        </StyledMenuItem>
                        {showMoreLink ? (
                            <div style={{ paddingLeft: '16px' }}>
                                <a onClick={() => showMore(i)} id={'notify_show_more_' + i}>
                                    {phrase.show_more} {'>'}
                                </a>
                            </div>
                        ) : null}

                        <Divider />
                    </React.Fragment>
                )
            })
            setBellNotifyContent(notifyContent)
            setBellNotifyUnreadCount(unreadCount)
        } else {
            setBellNotifyContent([])
            setBellNotifyUnreadCount(0)
        }
    }, [bellNotify, localStorage.getItem('language')])

    useEffect(() => {
        if (responseStatusUser) {
            setAlert(responseStatusUser.message, responseStatusUser.status)
            clearResponseUser()
        }
        if (responseStatusProduct) {
            setAlert(responseStatusProduct.message, responseStatusProduct.status)
            clearResponseProduct()
        }
        if (responseStatusAutoPlt) {
            setAlert(responseStatusAutoPlt.message, responseStatusAutoPlt.status)
            clearResponseAutoPlt()
        }
    }, [responseStatusUser, responseStatusProduct, responseStatusAutoPlt])

    const onLogOut = () => {
        logout()
        setBellNotify([])
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }
    function closeSideLogin() {
        handleLoginPopup()
        setState({ ...state, right: false })
    }
    function closeSideRegister() {
        handleRegisterPopup()
        setState({ ...state, right: false })
    }
    const handleClose = (history, path) => {
        setAnchorMenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const clearNotificationHandler = async () => {
        await clearNotification()
        getNotification()
    }

    const redirectDefaultSearch = () => {
        // if (history.location.pathname == '/search') {
        //     window.location.reload()
        // } else {
        //     handleRedirectInternal(history, 'search')
        // }
        window.location.href = '/search'
    }
    const handleSearchBarValue = (event) => {
        let { key } = event
        let { value } = event.target
        setSearchValue({ ...searchValue, searchbar: value })
        if (key === 'Enter') {
            history.push('/search')
        }
    }

    const searchBarSubmit = () => {
        setSearchValue({ ...searchValue, searchbar: searchValue.searchbar })
        history.push('/search')
    }

    // const searchPath = (window.location.pathname = '/search')
    // console.log(searchPath, 'searchPath')
    return (
        <>
            {/* {console.log(localStorage, 'Window.localStorage')} */}
            {useCustomMediaQuery('(min-width: 1100px)') ? (
                <>
                    {/* DESKTOP NAVIGATION */}
                    <header className="mainHeader">
                        <div className="customContainer d-flex justify-content-between align-items-center">
                            <div className="headLt d-flex justify-content-start align-items-center">
                                {/* {isAuthenticated ? (
                                    <a
                                        onClick={() => redirectDefaultSearch()}
                                        className="brandLogo"
                                    >
                                        <img src={logo} width="65" alt={`${siteName} logo`} />
                                    </a>
                                ) : ( */}
                                <Link to="/">
                                    <img src={logo} alt={`${siteName} logo`} />
                                </Link>
                                {/* )} */}
                            </div>
                            <div className="headerNewClass w-100">
                                <div
                                    className={`headRt deskNav d-flex w-100 align-items-center ${
                                        isAuthenticated
                                            ? 'justify-content-between'
                                            : 'justify-content-end'
                                    }`}
                                >
                                    {isAuthenticated && (
                                        <ul
                                            className="d-flex justify-content-start align-items-center"
                                            style={{ marginTop: '4px', marginLeft: '6px' }}
                                        >
                                            {user.isBuyer ? (
                                                <>
                                                    <ListItem
                                                        style={{
                                                            paddingLeft: '7px',
                                                            paddingRight: '7px',
                                                        }}
                                                    >
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/active"
                                                            isActive={() =>
                                                                [
                                                                    '/dashboard/active',
                                                                    '/dashboard/buy_now',
                                                                    '/dashboard/won',
                                                                    '/dashboard/lost',
                                                                ].includes(pathname)
                                                            }
                                                        >
                                                            {/* {phrase.my_auctions} */}My Dashboard
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem
                                                        style={{
                                                            paddingLeft: '7px',
                                                            paddingRight: '7px',
                                                        }}
                                                    >
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/watchlist"
                                                            className="d-flex align-items-center"
                                                        >
                                                            <span
                                                                className="material-icons"
                                                                style={{ paddingRight: '4px' }}
                                                            >
                                                                star
                                                            </span>
                                                            {phrase.watch_list}
                                                        </NavLink>
                                                    </ListItem>
                                                </>
                                            ) : null}
                                        </ul>
                                    )}
                                    <ul className="d-flex justify-content-start align-items-center">
                                        {window.location.pathname.includes('search') ||
                                        window.location.pathname.includes('dashboard') ||
                                        window.location.pathname.includes('recent') ? (
                                            <>
                                                <ListItem>
                                                    <NavLink
                                                        to={'/search'}
                                                        onClick={() =>
                                                            (window.location.href = '/search')
                                                        }
                                                        activeClassName={'active'}
                                                    >
                                                        {phrase.buy_from_us}
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem>
                                                    <NavLink
                                                        to={'/recent/upcoming'}
                                                        onClick={() =>
                                                            (window.location.href =
                                                                '/recent/upcoming')
                                                        }
                                                        activeClassName={'active'}
                                                    >
                                                        Upcoming Auctions
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem>
                                                    <NavLink
                                                        to={'/recent/completed'}
                                                        className="colsedAuctions"
                                                        onClick={() =>
                                                            (window.location.href =
                                                                '/recent/completed')
                                                        }
                                                        activeClassName={'active'}
                                                    >
                                                        {phrase.see_closed_auctions}
                                                    </NavLink>
                                                </ListItem>
                                            </>
                                        ) : null}

                                        {/*{!isAuthenticated ? (
                                            <ListItem>
                                                <NavLink to="/register_seller">Sell to Us</NavLink>
                                            </ListItem>
                                        ) : null} */}
                                        {/* <ListItem>
                                            <NavLink to="/about">{phrase.about}</NavLink>
                                        </ListItem>
                                        <ListItem>
                                            <NavLink to="/how-to">{phrase.how_to}</NavLink>
                                        </ListItem> */}
                                        <ListItem className="contactMe">
                                            <NavLink to="/support">Contact</NavLink>
                                        </ListItem>
                                        <li>
                                            <Button
                                                aria-controls="languageMenu"
                                                className="langButton"
                                                aria-haspopup="true"
                                                onClick={(e) => setAnchorLanguage(e.currentTarget)}
                                            >
                                                {phrase[languageKey]}
                                                <span className="material-icons">
                                                    arrow_drop_down
                                                </span>
                                            </Button>
                                            <Menu
                                                id="languageMenu"
                                                anchorEl={anchorLanguage}
                                                keepMounted
                                                open={Boolean(anchorLanguage)}
                                                value={language}
                                                onClose={() => setAnchorLanguage(null)}
                                            >
                                                <MenuItem
                                                    value="English"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'English')
                                                    }
                                                >
                                                    {phrase.english}
                                                </MenuItem>
                                                <MenuItem
                                                    value="Mandarin"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'Mandarin')
                                                    }
                                                >
                                                    {phrase.mandarin}
                                                </MenuItem>
                                                <MenuItem
                                                    value="French"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'French')
                                                    }
                                                >
                                                    {phrase.french}
                                                </MenuItem>
                                                <MenuItem
                                                    value="Spanish"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'Spanish')
                                                    }
                                                >
                                                    {phrase.spanish}
                                                </MenuItem>
                                                <MenuItem
                                                    value="Portugese"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'Portugese')
                                                    }
                                                >
                                                    {phrase.portugese}
                                                </MenuItem>
                                                <MenuItem
                                                    value="BrazilPortugese"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'BrazilPortugese')
                                                    }
                                                >
                                                    {phrase.brazil_portugese}
                                                </MenuItem>
                                                <MenuItem
                                                    value="German"
                                                    onClick={(event) =>
                                                        switchLanguage(event, 'German')
                                                    }
                                                >
                                                    {phrase.german}
                                                </MenuItem>
                                            </Menu>
                                        </li>
                                        {isAuthenticated ? (
                                            <>
                                                {user.isBuyer ? (
                                                    <>
                                                        {' '}
                                                        <li className="headCart">
                                                            <Button
                                                                aria-controls="notificationMenu"
                                                                aria-haspopup="true"
                                                                onClick={(e) =>
                                                                    setAnchorNotif(e.currentTarget)
                                                                }
                                                            >
                                                                <Badge
                                                                    color="primary"
                                                                    badgeContent={
                                                                        bellNotifyUnreadCount
                                                                    }
                                                                >
                                                                    <span className="material-icons">
                                                                        notifications
                                                                    </span>
                                                                </Badge>
                                                            </Button>
                                                            <Menu
                                                                id="notificationMenu"
                                                                anchorEl={anchorNotif}
                                                                keepMounted
                                                                open={Boolean(anchorNotif)}
                                                                onClose={() => setAnchorNotif(null)}
                                                                className="notificationMenu"
                                                            >
                                                                <div>
                                                                    <h4>{phrase.notifications}</h4>
                                                                    {bellNotifyContent.length >
                                                                    0 ? (
                                                                        bellNotifyContent
                                                                    ) : (
                                                                        <h5 className="noNotification">
                                                                            {
                                                                                phrase.no_new_notification
                                                                            }
                                                                        </h5>
                                                                    )}
                                                                    {bellNotifyContent.length >
                                                                    0 ? (
                                                                        <Button
                                                                            onClick={
                                                                                clearNotificationHandler
                                                                            }
                                                                        >
                                                                            {phrase.clear}
                                                                        </Button>
                                                                    ) : null}
                                                                </div>
                                                            </Menu>
                                                        </li>
                                                        <li className="headCart">
                                                            <Button
                                                                onClick={() =>
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        'message',
                                                                    )
                                                                }
                                                            >
                                                                <Badge
                                                                    color="primary"
                                                                    badgeContent={msgUnreadCount}
                                                                >
                                                                    <span className="material-icons">
                                                                        mail
                                                                    </span>
                                                                </Badge>
                                                            </Button>
                                                        </li>
                                                    </>
                                                ) : null}

                                                <li>
                                                    <Button
                                                        aria-controls="avatarMenu"
                                                        aria-haspopup="true"
                                                        onClick={(e) =>
                                                            setAnchorMenu(e.currentTarget)
                                                        }
                                                    >
                                                        {user.first_name || 'User'}{' '}
                                                        <span className="material-icons">
                                                            arrow_drop_down
                                                        </span>
                                                    </Button>
                                                    <Menu
                                                        id="avatarMenu"
                                                        anchorEl={anchorMenu}
                                                        keepMounted
                                                        open={Boolean(anchorMenu)}
                                                        onClose={() => setAnchorMenu(null)}
                                                    >
                                                        {user.isBuyer ? (
                                                            <>
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'my_account',
                                                                        )
                                                                    }
                                                                >
                                                                    {phrase.my_account}
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() =>
                                                                        handleClose(
                                                                            history,
                                                                            'my_preferences',
                                                                        )
                                                                    }
                                                                >
                                                                    {phrase.my_preferences}
                                                                </MenuItem>
                                                            </>
                                                        ) : (
                                                            <MenuItem
                                                                onClick={() =>
                                                                    window.open(
                                                                        global.site_url,
                                                                        '_blank', // <- This is what makes it open in a new window.
                                                                    )
                                                                }
                                                            >
                                                                Admin Module
                                                            </MenuItem>
                                                        )}

                                                        <MenuItem onClick={() => onLogOut()}>
                                                            Logout
                                                        </MenuItem>
                                                    </Menu>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li>
                                                    <Button className="loginn">
                                                        <NavLink
                                                            button
                                                            activeClassName="active"
                                                            to="#"
                                                            onClick={handleLoginPopup}
                                                        >
                                                            Login |
                                                        </NavLink>
                                                        <NavLink
                                                            button
                                                            activeClassName="active"
                                                            to="#"
                                                            onClick={handleRegisterPopup}
                                                            className="ml-1"
                                                        >
                                                            Register
                                                        </NavLink>
                                                    </Button>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                                {window.location.pathname.includes('search') ||
                                window.location.pathname.includes('dashboard') ||
                                window.location.pathname.includes('recent') ? (
                                    <SearchNav />
                                ) : null}{' '}
                            </div>
                        </div>
                    </header>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="customContainer mobHeader d-flex justify-content-between align-items-center">
                        {console.log(isAuthenticated, 'isAuthenticated')}
                        <div className="headLt d-flex justify-content-start">
                            {/* {isAuthenticated ? (
                                <a onClick={() => redirectDefaultSearch()}>
                                    <img src={logo} alt={`${siteName} logo`} />
                                </a>
                            ) : ( */}
                            <Link to="/">
                                <img src={logo} alt={`${siteName} logo`} />
                            </Link>
                            {/* )} */}
                        </div>
                        <ul className="d-flex justify-content-start ml-auto align-items-center">
                            {isAuthenticated && (
                                <>
                                    <li className="headCart">
                                        <Button onClick={toggleDrawer('top', true)}>
                                            <span className="material-icons">search</span>
                                        </Button>
                                    </li>
                                    <li className="headCart">
                                        <Button
                                            aria-controls="notificationMenu"
                                            aria-haspopup="true"
                                            onClick={(e) => setAnchorNotif(e.currentTarget)}
                                        >
                                            <Badge
                                                color="primary"
                                                badgeContent={bellNotifyUnreadCount}
                                            >
                                                <span className="material-icons">
                                                    notifications
                                                </span>
                                            </Badge>
                                        </Button>
                                        <Menu
                                            id="notificationMenu"
                                            anchorEl={anchorNotif}
                                            keepMounted
                                            open={Boolean(anchorNotif)}
                                            onClose={() => setAnchorNotif(null)}
                                            className="notificationMenu"
                                        >
                                            <div>
                                                <h4>{phrase.notifications}</h4>
                                                {bellNotifyContent.length > 0 ? (
                                                    bellNotifyContent
                                                ) : (
                                                    <h5 className="noNotification">
                                                        {phrase.no_new_notification}
                                                    </h5>
                                                )}
                                                {bellNotifyContent.length > 0 ? (
                                                    <Button onClick={clearNotificationHandler}>
                                                        {phrase.clear}
                                                    </Button>
                                                ) : null}
                                            </div>
                                        </Menu>
                                    </li>
                                    <li className="headCart">
                                        <Button
                                            onClick={() =>
                                                handleRedirectInternal(history, 'message')
                                            }
                                        >
                                            <Badge color="primary" badgeContent={msgUnreadCount}>
                                                <span className="material-icons">mail</span>
                                            </Badge>
                                        </Button>
                                    </li>
                                </>
                            )}
                            <li>
                                <Button
                                    className="respNavBtn"
                                    onClick={toggleDrawer('right', true)}
                                >
                                    <span className="material-icons">menu</span>
                                </Button>
                            </li>
                        </ul>
                    </header>
                    <Drawer
                        className="headerSearch"
                        anchor={'top'}
                        open={state['top']}
                        onClose={toggleDrawer('top', false)}
                        onOpen={toggleDrawer('top', true)}
                    >
                        {/* <CustomInput
                            label="Search"
                            size="regular"
                            placeholder="Search for products"
                            endadornment={
                                <Button>
                                    <span className="material-icons">search</span>
                                </Button>
                            }
                        /> */}
                        <CustomInput
                            label={phrase.search_inventory}
                            size="medium"
                            placeholder={phrase.search_inventory}
                            defaultValue={
                                props.searchBarValue
                                    ? props.searchBarValue
                                    : searchValue.searchbar
                                    ? searchValue.searchbar
                                    : ''
                            }
                            value={props.searchBarValue}
                            onChange={props.searchInv ? props.searchInv : handleSearchBarValue}
                            onKeyDown={props.searchInv ? props.searchInv : handleSearchBarValue}
                            endadornment={
                                <Button
                                    type="button"
                                    onClick={
                                        props.searchBarSubmit
                                            ? props.searchBarSubmit
                                            : searchBarSubmit
                                    }
                                >
                                    <SearchIcon />
                                </Button>
                            }
                        />
                    </Drawer>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    {/* {isAuthenticated ? (
                                        <a onClick={() => redirectDefaultSearch()}>
                                            <img src={logo} alt={`${siteName} logo`} />
                                        </a>
                                    ) : ( */}
                                    <Link to="/">
                                        <img src={logo} alt={`${siteName} logo`} />
                                    </Link>
                                    {/* )} */}
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="headRtNav">
                                    <span className="headerSideCont">
                                        {isAuthenticated && (
                                            <>
                                                {/* <ListItem button> */}
                                                <SearchNav />
                                                {/* </ListItem> */}
                                                {/* <Divider className="my-2" /> */}
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/active"
                                                        onClick={() =>
                                                            (window.location.href =
                                                                '/dashboard/active')
                                                        }
                                                    >
                                                        My Dashboard
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/dashboard/watchlist"
                                                        onClick={() =>
                                                            (window.location.href =
                                                                '/dashboard/watchlist')
                                                        }
                                                    >
                                                        {phrase.watch_list}
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        to={'/search'}
                                                        onClick={() =>
                                                            (window.location.href = '/search')
                                                        }
                                                        activeClassName={'active'}
                                                    >
                                                        {phrase.buy_from_us}
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        to={'/recent/upcoming'}
                                                        onClick={() =>
                                                            (window.location.href =
                                                                '/recent/upcoming')
                                                        }
                                                        activeClassName={'active'}
                                                    >
                                                        Upcoming Auctions
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        to={'/recent/completed'}
                                                        className="colsedAuctions"
                                                        onClick={() =>
                                                            (window.location.href =
                                                                '/recent/completed')
                                                        }
                                                        activeClassName={'active'}
                                                    >
                                                        {phrase.see_closed_auctions}
                                                    </NavLink>
                                                </ListItem>
                                            </>
                                        )}
                                        {/* <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/about"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                {phrase.about}
                                            </NavLink>
                                        </ListItem> */}
                                        <ListItem button>
                                            <NavLink
                                                activeClassName="active"
                                                to="/support"
                                                onClick={toggleDrawer('right', false)}
                                            >
                                                Contact
                                            </NavLink>
                                        </ListItem>
                                        {isAuthenticated ? (
                                            <>
                                                {/* {logDrawer ? (
                                                    <> */}
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/my_account"
                                                        onClick={toggleDrawer('right', false)}
                                                    >
                                                        My Account
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/my_preferences"
                                                        onClick={toggleDrawer('right', false)}
                                                    >
                                                        My Preferences
                                                    </NavLink>
                                                </ListItem>{' '}
                                                <ListItem button onClick={() => onLogOut()}>
                                                    <Link
                                                        to="/dashboard/myPreferences"
                                                        onClick={toggleDrawer('right', false)}
                                                    >
                                                        Logout
                                                    </Link>
                                                </ListItem>
                                                {/* </>
                                                ) : null} */}
                                            </>
                                        ) : (
                                            <>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="#"
                                                        onClick={closeSideLogin}
                                                    >
                                                        Login
                                                    </NavLink>
                                                </ListItem>
                                                <ListItem button>
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="#"
                                                        onClick={closeSideRegister}
                                                    >
                                                        Register
                                                    </NavLink>
                                                </ListItem>
                                            </>
                                        )}
                                    </span>
                                    <li className="headRtLang">
                                        <Divider />
                                        <Button
                                            aria-controls="languageMenu"
                                            aria-haspopup="true"
                                            onClick={(e) => setAnchorLanguage(e.currentTarget)}
                                        >
                                            {phrase[languageKey]}
                                            <span className="material-icons">arrow_drop_down</span>
                                        </Button>
                                        <Menu
                                            id="languageMenu"
                                            anchorEl={anchorLanguage}
                                            keepMounted
                                            open={Boolean(anchorLanguage)}
                                            value={language}
                                            onClose={() => setAnchorLanguage(null)}
                                        >
                                            <MenuItem
                                                value="English"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'English')
                                                }
                                            >
                                                {phrase.english}
                                            </MenuItem>
                                            <MenuItem
                                                value="Mandarin"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'Mandarin')
                                                }
                                            >
                                                {phrase.mandarin}
                                            </MenuItem>
                                            <MenuItem
                                                value="French"
                                                onClick={(event) => switchLanguage(event, 'French')}
                                            >
                                                {phrase.french}
                                            </MenuItem>
                                            <MenuItem
                                                value="Spanish"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'Spanish')
                                                }
                                            >
                                                {phrase.spanish}
                                            </MenuItem>
                                            <MenuItem
                                                value="Portugese"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'Portugese')
                                                }
                                            >
                                                {phrase.portugese}
                                            </MenuItem>
                                            <MenuItem
                                                value="BrazilPortugese"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'BrazilPortugese')
                                                }
                                            >
                                                {phrase.brazil_portugese}
                                            </MenuItem>
                                            <MenuItem
                                                value="German"
                                                onClick={(event) => switchLanguage(event, 'German')}
                                            >
                                                {phrase.german}
                                            </MenuItem>
                                        </Menu>
                                    </li>
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            <Dialog open={loginPopup} function={handleLoginPopup} className="lgnWrpr">
                <div className="lgnCntnr">
                    <IconButton className="popClrBtn" onClick={handleLoginPopup}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                    <Login
                        autoComplete={1}
                        closeLogin={handleLoginPopup}
                        openRegister={handleRegisterPopup}
                        openForgot={handleForgotPopup}
                        onMenuClose={() => setAnchorMenu(null)}
                        closeLogMenu={() => setLogDrawer(false)}
                    />
                </div>
            </Dialog>
            <Dialog open={registerPopup} function={handleRegisterPopup} className="lgnWrpr">
                <div className="lgnCntnr">
                    <IconButton className="popClrBtn" onClick={handleRegisterPopup}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                    <Registration
                        openLogin={handleLoginPopup}
                        closeRegister={handleRegisterPopup}
                    />
                </div>
            </Dialog>
            <Dialog open={forgotPopup} function={handleForgotPopup} className="lgnWrpr">
                <div className="lgnCntnr">
                    <IconButton className="popClrBtn" onClick={handleForgotPopup}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                    <ForgotPassword closeForg={handleForgotPopup} openLogin={handleLoginPopup} />
                </div>
            </Dialog>
            {/* Session Inactividy Popup Message */}
            <Popup
                open={sessionPopup}
                modaltitle={phrase.message}
                handleClose={sessionPopupClose}
                footer={<PrimaryButton label={phrase.close} onClick={sessionPopupClose} />}
            >
                <p>Your session has expired due to inactivity!</p>
            </Popup>
        </>
    )
}
export default Header
